import BaseViewModel from "../../Core/BaseViewModel";

export default class CustomerRegisterViewModel extends  BaseViewModel{
    id:Number=0;
    referral_code:string="";
    referred_by:string="";
    first_name:string="";
    last_name:string="";
    email:string="";
    mobile:string="";
    password:string="";
    access_token:string="";
    onProcess: boolean = false;
		formErrors:any = {};
}