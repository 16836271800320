import BaseViewModel from "../../Core/BaseViewModel";
import ProductModel from "../../Models/ProductModel";

export default class CategoryProductViewModel extends BaseViewModel{
	loading: boolean = false;
	categorySlug: string = "";
	products: ProductModel[] = [];
	itemsLoading: Array<number> = [];
	page: number = 1;
	totalPage: number = 0;
}