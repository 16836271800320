import React  from 'react';
//import {Col, Container, Row, Tab, Tabs, Button } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseState from '../../Core/BaseState';
import BaseResponse from '../../Core/BaseResponse';
import RecentlyViewProductViewModel from '../../ViewModels/Home/RecentlyViewProductViewModel';
import HomeDataAccess from '../../DataAccess/HomeDataAccess';
import CustomerCartDataAccess from '../../DataAccess/CustomerCartDataAccess';
import WishlistDataAccess from '../../DataAccess/WishlistDataAccess';
//import HeaderPage from '../Core/HeaderPage';
//import FooterPage from '../Core/FooterPage';
import OwlCarousel from 'react-owl-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";

type Props = {
	section?: any
} 

export default class RecentlyViewed extends BaseComponent<any,RecentlyViewProductViewModel> {
  constructor(props:any) {
    super(props);
    this.state = new BaseState<RecentlyViewProductViewModel>(new RecentlyViewProductViewModel());
		this.state.Model.section = 'section' in this.props ? this.props.section : null;
		this.state.Model.loading = true;
  }
	
	componentDidMount(){
		this.recenlyViewList();
	}
	
	recenlyViewList(){
		HomeDataAccess.GetRecentlyViewedProduct((res: BaseResponse) => {
      this.state.Model.loading = false;
      if(!res.success){
				this.UpdateViewModel();
				return;
      }
			this.state.Model.products = res.data.items;
			this.UpdateViewModel();
			
		});
		
	}

	addToWishlist = (item: any, key: any) => { 
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		
		//let model = this.state.Model;
		if(item.wishlist_id){
			WishlistDataAccess.RemoveWishlist(item.id, (res: BaseResponse) => {
				if(!res.success){
					return;
				}
				this.state.Model.products[key]['wishlist_id'] = "";
				this.UpdateViewModel();
				this.UpdateWishlistCount(res.data.wishlist_count);
				this.ShowToast(res.message,"success");
				
			});
			
		}else{
			let params = {
				product_id: item.id
			}
			WishlistDataAccess.AddWishlist(params, (res: BaseResponse) => {
				if(!res.success){
					return;
				}
				this.state.Model.products[key]['wishlist_id'] = res.data.wishlist_count;
				this.UpdateViewModel();
				this.UpdateWishlistCount(res.data.wishlist_count);
				this.ShowToast(res.message,"success");
			});
		}
		
	}
	
	addToCart = (id: number) => {
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		let model = this.state.Model;
		if(this.checkIsProductProcessing(id)) return;
		let params = {
			product_id: id,
			quantity: 1
		};
		
		this.state.Model.itemsLoading.push(id);
    this.UpdateViewModel();
		
		CustomerCartDataAccess.Create(params, async (res: BaseResponse) => {
			model.itemsLoading = model.itemsLoading.filter(i => i !== id);
			this.UpdateViewModel();
			if(!res.success){
				this.showCartErrorMessage(res);
				return false;
			}
			
			this.UpdateCartCount(res.data.cart_count);
			this.ShowToast(res.message,"success");
		});
	}
	
	checkIsProductProcessing(id: number){
		let model = this.state.Model;
		if(model.itemsLoading.includes(id)){
			return true;
		}else{
			return false;
		}
	}
  
	render(){
		let model = this.state.Model;
		
		if(model.loading || !model.products.length){
			return false;
		}
		
		return(
			
			<div className="home">
				<section>
					<div className="our-recently_viewed">
						<div className="new_recently_viewed_container">
							<div className="puja_feature_heading">
								<h1> {model.section.section_title} </h1>
							</div>
							<div className="new_recently_viewed_row">
								<OwlCarousel
									autoplay={false}
									className="owl-theme"
									loop
									margin={18}
									items={4}
									dots={false}
									nav={true}
									responsive={{
										0: {
												items: 1,
										},
										600: {
												items: 3,
										},
										1000: {
												items: 4,
										},
									}}
								>
									{
										model.products.map((item: any, key:number) => {
										return <div key={key}>
											<div className="contain-recently_viewed layout-default">
												{
													item.media ?
													<div className="recently_viewed-thumb">
														<Link to={"/products/" + item.slug} className="mage link-to-recently_viewed">
															<div className="max-fit-2"><img src={item.media} className="img-fluid" alt="puja" /></div>
														</Link>
													</div>
													: null
												}
												<div className="info">
													<h4 className="recently_viewed-title">
														<Link to={"/products/" + item.slug} className="pr-name">{ item.name }</Link>
													</h4>
													<div className="price">
														<span className="price-amount"><span className="currencySymbol"></span>{ this.priceDisplay('Rs. ', item.sale_price) }</span>
														{
															item.price !== item.sale_price ?
															<span className="price-amount original_price"><span className="currencySymbol"></span>{ this.priceDisplay('Rs. ', item.price) }</span>
															: null
														}
													</div>
													<div className="slide-down-box">
														<p className="message">All products are carefully selected to ensure food safety.</p>
														<div className="buttons">
														    <a href="javascript: void(0)" onClick={(e) => this.addToWishlist(item, key)} className={item.wishlist_id  ? "" : "btn compare-btn"}>
													        	<FontAwesomeIcon icon="heart" />
												            </a>
															<a href="javascript: void(0)" onClick={() => this.addToCart(item.id)} className="btn add-to-cart-btn">
																<FontAwesomeIcon icon="shopping-cart" /> {this.checkIsProductProcessing(item.id) ? 'Processing...' : 'add to cart'}
															</a>
															<a href="javascript: void(0)" onClick={(e) => this.addToWishlist(item, key)} className={item.wishlist_id  ? "" : "btn compare-btn"}>
													        	<FontAwesomeIcon icon="heart" />
												            </a>
														</div>
													</div>
												</div>
											</div>
										</div>
										})
									}
								</OwlCarousel>
							</div>
						</div>
					</div>
				</section>
			</div>
    );
  }
}