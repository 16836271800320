import React  from 'react';
import {Col, Container, Row, Button, Modal, Spinner} from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import { BaseLoader } from '../Core/BaseView';
import SessionHelper from '../../Core/SessionHelper';
import CustomerAddressDataAccess from '../../DataAccess/CustomerAddressDataAccess';
import CustomerAddressModel from '../../Models/CustomerAddressModel';
import CustomerAddressListViewModel from '../../ViewModels/CustomerAddress/CustomerAddressListViewModel';
import CustomerAddressCreatePage from '../CustomerAddress/CustomerAddressCreatePage';
import CustomerAddressUpdatePage from '../CustomerAddress/CustomerAddressUpdatePage';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';

export default class CustomerAddressListPage extends BaseComponent<any, CustomerAddressListViewModel> {

  constructor(props:any) {
      super(props);
      SessionHelper.HandleAuthentication();
      this.state = new BaseState<CustomerAddressListViewModel>(new CustomerAddressListViewModel());
      this.state.Model.loading=true;
  }

  componentDidMount(){
    if(!this.Auth){
			return null;
		}
    this.BindData();
  }


  BindData(){
    this.GetList();
  }

  
  GetList = async  () => {
    CustomerAddressDataAccess.GetList(async (res: BaseResponse) => {
      if(!res.success){
        return this.ShowToast(res.message,"warning");
      }
      this.state.Model.addresses=res.data as CustomerAddressModel[];
      this.state.Model.loading=false;
      this.UpdateViewModel();
    });

  }


  handleCloseAddModal = (address?:any) =>{
    this.state.Model.showAddModal = false;
		if(address){
			this.state.Model.addresses.push(address);
		}
		this.UpdateViewModel();
  }

  handleCloseEditModal = (address?:any)  => {
    this.state.Model.showEditModal = false;
		if(address){
			this.state.Model.addresses[this.state.Model.selected_index] = address;
		}
		this.state.Model.selected_index = 0;
		this.UpdateViewModel();
  }
 
  handleCloseCancelModal = () => {
		this.state.Model.cancelModal=false;
		this.state.Model.actionAddress = [];
    this.UpdateViewModel();
  }

  addAddress(){
    this.state.Model.showAddModal=true;
    this.UpdateViewModel();
  }

  editAddress = async (item:any, index: any) =>{
    this.state.Model.showEditModal=true;
    this.state.Model.selected_index = index;
    this.UpdateViewModel();
  }

  deleteAddress = async (item:any) => {
    this.state.Model.actionAddress = item;
		this.state.Model.cancelModal = true;
		this.UpdateViewModel();
  }

  CancelAddress = async () => {
		let model = this.state.Model
		if(model.actionProcessing){
			return;
		}
		let address_id = model.actionAddress.id;
		this.state.Model.actionProcessing = true;
    this.UpdateViewModel();
    
    CustomerAddressDataAccess.Delete(address_id,async (res: BaseResponse) => {
      this.state.Model.loading=false;
      this.state.Model.actionProcessing = false;
      this.state.Model.cancelModal=false;
      
        if(!res.success){
          return this.ShowToast(res.message,"warning");
        }
        else{
          this.GetList();
          return this.ShowToast(res.message,"success");
        }
      });
	}

    render(){
      var model=this.state.Model;
      if(!this.Auth){
        return null;
      }
        return(
          <div>
              <HeaderPage/>
            <div>
            <br/>            
            <div className="addresses_container">
              <Container>
                <Row>
                <Col xs={12} md={12} sm={12} lg={12}>
                  <div className="cart_header">
                    <h1>My Address on God's Kart</h1>
                  </div>
                  </Col>
                  <Col xs={12} md={12} sm={12} lg={12}>
                    <div className="address_adds_btn">
                      <Button variant="primary" onClick={() => this.addAddress()}>Add Address</Button>
                    </div>
                  </Col>
                </Row>
                <br/>
                {
                 (model.loading) ?
                    <div className="container_loader">
											<BaseLoader/>
										</div>
                    : 
                    <React.Fragment>
                      {
                        model.addresses.length === 0 ?
                          null
                        :
                        <React.Fragment>
                          {model.addresses.map((item, index) =>
                            <Row key={item.id}>
                              <Col xs={12} md={12} sm={12} lg={12}>
                                <div className="myaddress_area">
                                  <Col xs={12} md={8} sm={8} lg={8}>
                                     <h1>Home</h1>
                                     <div className="personal_info">
                                       <h2>{item.name} <span>&nbsp; {item.mobile}</span></h2>
                                     </div> 
                                     <div className="personal_address">
                                      <p>{item.street}, {item.city}, {item.state}, {item.country}, {item.zip}</p>
                                     </div>
                                   </Col>
                                   <Col xs={12} md={4} sm={4} lg={4}>
                                     <div className="adress-action-btns">
                                       <Button variant="primary" onClick={() => this.editAddress(item, index)}>Edit</Button>
                                       <Button variant="primary" onClick={() => this.deleteAddress(item)}>Delete</Button>
                                     </div>
                                   </Col>
                                </div>
                              </Col>
                            </Row>
                          )}
                        </React.Fragment>
                      }
                   </React.Fragment>
                }

                  <Modal
                    show={model.showAddModal}
                    onHide={() => this.handleCloseAddModal()}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Add Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <CustomerAddressCreatePage closeEvent={this.handleCloseAddModal}/>
                    </Modal.Body>
                    <Modal.Footer>
                   </Modal.Footer>
                </Modal>

                <Modal
                    show={model.showEditModal}
                    onHide={() => this.handleCloseEditModal()}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Edit Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <CustomerAddressUpdatePage addresses={this.state.Model.addresses} selected_index={this.state.Model.selected_index} closeEvent={this.handleCloseEditModal}/>
                    </Modal.Body>
                    <Modal.Footer>
                   </Modal.Footer>
                </Modal>

                  <Modal
                    show={model.cancelModal}
                    onHide={() => this.handleCloseCancelModal()}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header>
                      <Modal.Title>Delete Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       Are you sure want to delete this address?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="danger" onClick={() => this.CancelAddress()} disabled={model.actionProcessing}>
                        {
                          model.actionProcessing ?
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          :
                          "Yes"
                        }
                      </Button>
                      {
                        !model.actionProcessing ? 
                        <Button variant="success" onClick={() => this.handleCloseCancelModal()}>No</Button>
                        : null
                      }
                  </Modal.Footer>
                </Modal>
              </Container>
              </div>
            </div> 
            <FooterPage /> 
          </div>
        );
    }
  }
