import React  from 'react';
import { Col, Container, Row, Button, Modal, Spinner, Card, Form, Breadcrumb, Alert } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import SessionHelper from '../../Core/SessionHelper';
import CustomerOrderDataAccess from '../../DataAccess/CustomerOrderDataAccess';
import TicketDetailsViewModel from '../../ViewModels/Ticket/TicketDetailsViewModel';
import HeaderPage from '../Core/HeaderPage';
import { BaseLoader } from '../Core/BaseView';
import { Link } from 'react-router-dom';
import FooterPage from '../Core/FooterPage';
import { withRouter } from 'react-router-dom';
import PageNotFoundContent from '../Core/PageNotFoundContent';

class TicketViewPage extends BaseComponent<any, TicketDetailsViewModel> {

  constructor(props:any) {
		super(props);
		SessionHelper.HandleAuthentication();
		this.state = new BaseState<TicketDetailsViewModel>(new TicketDetailsViewModel());
	    this.state.Model.loading = true;
  }

  componentDidMount(){
		if(!this.Auth){
			return null;
		}
	this.GetTicket();
	this.GetMessages();
  }

  GetTicket = async  () => {
	  let ticketId = this.props.match.params.id;
		this.state.Model.orderid = this.props.match.params.orderid;
	  
	  CustomerOrderDataAccess.getTicketDetails(ticketId, async (res: BaseResponse) => {
		if(!res.success){
			this.state.Model.loading = false;
			this.UpdateViewModel();
			return this.ShowToast(res.message,"warning");
		}
		this.state.Model.ticket = res.data;
		this.state.Model.loading = false;
		this.UpdateViewModel();
	});
  }

  GetMessages = async  () => {
	let ticketId = this.props.match.params.id;
	
	CustomerOrderDataAccess.getTicketMessages(ticketId, async (res: BaseResponse) => {
	  if(!res.success){
		  this.state.Model.loading = false;
		  this.UpdateViewModel();
		  return this.ShowToast(res.message,"warning");
	  }
	  this.state.Model.messages = res.data.messages;
	  this.state.Model.loading = false;
	  this.UpdateViewModel();
  });
}

  hasError = (key: string) => {
	let formErrors = this.state.Model.formErrors;
	return (key in formErrors && formErrors[key]);
  }

  showError = (key: string) => {
	let formErrors = this.state.Model.formErrors;
	return this.hasError(key) ? <Form.Text className="text-danger">{formErrors[key]}</Form.Text> : null;
  }


  Create = async () => {
		if(!this.ValidateFrom() || this.state.Model.onProcess){
			return false;
		 }
		this.state.Model.onProcess = true;
		this.UpdateViewModel();
		let model = this.state.Model;
		
		const formData = new FormData();
		if(model.file){
			for (let i = 0; i < model.file.length; i++) {
				formData.append(`file[${i}]`, model.file[i])
			}
		}
		formData.append('message', model.message);
		
		let ticketId = this.props.match.params.id;
		
			 CustomerOrderDataAccess.createMessage(ticketId, formData, async (res: BaseResponse) => {
			this.state.Model.onProcess = false;
			if(!res.success){
				try {
					let errArr = JSON.parse(res.message);
					let formErrors:any = {};
					if('message' in errArr){
						formErrors.message=errArr.message[0];
					}
					this.state.Model.formErrors = formErrors;
					this.UpdateViewModel();
					return;
				} catch (e) {
					this.UpdateViewModel();
					return this.ShowToast(res.message,"success");
				}
			}

			this.state.Model.isSuccess = res.message;
			this.state.Model.id = res.data.id;
			this.state.Model.message = null;
			this.state.Model.file = null;
			this.state.Model.messages.push(res.data.message);
			this.UpdateViewModel();
			this.ResetForm();
			
			//this.props.history.push('/order/' + this.state.Model.orderid + '/tickets/' + this.state.Model.id);
			
		 });
	}
	
	ResetForm = () => {
		let model = this.state.Model;
		model.message = '';
		model.file = '';
		(document as any).getElementById('form_file').value = '';
		this.setState({
			Model: model
		});
	}

	handleFileChange = (e:any) => {
		this.state.Model.file = e.target.files;
	}


	ValidateFrom = () => {
		let model = this.state.Model;
		let formErrors:any = {};

		if(!model.message){
			formErrors.message = 'This field is required.'
		}
		
		this.state.Model.formErrors = formErrors;
		this.UpdateViewModel();
		return Object.keys(formErrors).length === 0;
	}

	render(){
		let model = this.state.Model;

		if(!this.Auth){
			return null;
		}
		
		return(
			<div>
				<HeaderPage />
				
				<div className="container">
					<section>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="breadcrumb-container">
									<Breadcrumb>
										<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
										<Breadcrumb.Item href="/orders">My Order</Breadcrumb.Item>
										<Breadcrumb.Item href={"/order-details/" + model.orderid}>Order Details</Breadcrumb.Item>
										<Breadcrumb.Item href={"/order/"+model.orderid+"/tickets"}>All Tickets</Breadcrumb.Item>
										<Breadcrumb.Item active>Ticket History</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</Col>
							{
								model.loading ?
								<Col xs={12} sm={12} md={12} lg={12}>
									<div className="container_loader">
										<BaseLoader/>
									</div>
								</Col>
								:
								<React.Fragment>
								{
									!model.ticket ?
									<Col xs={12} sm={12} md={12} lg={12}>
										<Alert variant="danger" className="not_found_product_container">
											<span>Sorry! Ticket not found.</span>
										</Alert>
									</Col>
									:
									<React.Fragment>
										<Col xs={12} sm={12} md={12} lg={12}>
										<div className="chat-history-container">
												<div className="chat-header clearfix">
												<div className="chat-about">
													<div className="chat-with">{model.ticket.ticket_no}</div>
													<div className="chat-num-messages">{model.ticket.subject}</div>
													<div className="chat-num-messages">{model.ticket.message}</div>
												</div>
												</div>
											<div className="chat-history">
											{
												model.messages.map((item:any, key:number) => {
													return <Col xs={12} sm={12} md={12} lg={12} key={key} className="myorderlist-area">
														<Row>
															<Col xs={12} sm={12} md={12} lg={12}>
																<div className="msg_name_date">
																	<p className="msg_name">{item.name}</p>
																	<span className="mg_name">{item.date}</span>
																</div>
																<p className="msg_description">{item.message}</p>
															</Col>
															{
																item.medias ? 
																<Col xs={12} sm={12} md={12} lg={12}>
																	<img src={item.medias} style={{width:'100px', height: 'auto'}}/>
																	</Col>
																: null
															}
														</Row>
													</Col>
													})
												}

												<Form>
												<Row>
													<Col xs={12} md={12}>
														<Form.Group controlId="message">
															<Form.Label>Message Box</Form.Label>
																<Form.Control 
																	as="textarea" 
																	rows={4} 
																	placeholder="Type your message..." 
																	name="message"
																	value={model.message}
																	isInvalid={this.hasError('message')}
																	onChange={this.SetModelValue}
																/>
															{this.showError('message')}
														</Form.Group>
													</Col>
													<Col xs={12} md={12}>
														<Form.Group controlId="file">
															<Form.Label>Image</Form.Label>
															<Form.File 
																type="text"
																name="File"
																multiple
																accept="image/*"
																id="form_file"
																onChange={this.handleFileChange}
															/>
															{this.showError('subject')}
														</Form.Group>
													</Col>
													<Col xs={12} md={12} className="text-right">
														<Form.Group controlId="formSubmit" className="ticket_submit_button">
															<Button 
																onClick={this.Create}
																disabled={model.onProcess}
															>
															{
																model.onProcess ? 
																<div>
																	<Spinner
																		as="span"
																		animation="border"
																		size="sm"
																		role="status"
																		aria-hidden="true"
																	/>&nbsp;Processing....
																</div>
																:
																"Submit"
															}
															</Button>
														</Form.Group>
														</Col>
													 </Row>
													</Form>
											</div> 
										</div>
										</Col>
									</React.Fragment>
								}
								</React.Fragment>
							}
						</Row>
						
					</section>
				</div>
				
				<FooterPage />
			</div>
		);
	}
}

export default withRouter(TicketViewPage)