import BaseApi from "../Core/BaseApi";

export default class CustomerAddressDataAccess extends BaseApi{

    public static GetList(callback:any){
        this.BaseGetRequest("customer/user/addresses",callback);
    }

    public static Create(model:any,callback:any){
        this.BaseURLPostRequest("customer/user/addresses",model,callback);
    }

    public static Update(model:any,callback:any){
        this.BaseURLPutRequest("customer/user/addresses/" + model.id ,model,callback);
    }

    public static Delete(id:number,callback:any){
        this.BaseURLDeleteRequest("customer/user/addresses/" + id,{},callback);
    }

}