import React  from 'react';
import { Spinner } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
//import HomeDataAccess from '../../DataAccess/HomeDataAccess';
//import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import HomeViewModel from '../../ViewModels/Home/HomeViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import Slides from '../Home/Slides';
import Products from '../Home/Products';
//import AppPromotion from '../Home/AppPromotion';
import Communities from '../Home/Communities';
import Packages from '../Home/Packages';
import Events from '../Home/Events';
import Partners from '../Home/Partners';
import RoomDecor from '../Home/RoomDecor';
import RecentlyViewed from '../Home/RecentlyViewed';
import Deals from '../Home/Deals';
//import { BaseLoader } from '../Core/BaseView';
import sectionsData from "../../sections.json";

export default class HomePage extends BaseComponent<any,HomeViewModel> {
  constructor(props:any) {
    super(props);
    this.state = new BaseState<HomeViewModel>(new HomeViewModel());
		this.state.Model.sections = sectionsData;
  }
	
	getSectionComponent = (item: any) => {
		switch(item.section_type){
			case 'slides' :
				return <Slides section={item} />;
				
			case 'products':
				return <Products section={item} />;
			
			case 'events':
				return <Events section={item} />;
				
			case 'communities':
				return <Communities section={item} />;
				
			case 'packages':
				return <Packages section={item} />;
				
			case 'partners':
				return <Partners section={item} />;
				
			case 'room_decor':
				return <RoomDecor section={item} />;
				
			case 'recently_viewed':
				return <RecentlyViewed section={item} />;
				
			case 'deals':
				return <Deals section={item} />;
			
			default:
				return <React.Fragment></React.Fragment>;
		}
	}
  
	render(){
		let model = this.state.Model;
		return(
			<React.Fragment>
				<HeaderPage />
				
				<div className="home">
				{
					model.sections.length ?
					<React.Fragment>
						{
							model.sections.map((item: any, key:number) => {
								return <React.Fragment key={key}>{this.getSectionComponent(item)}</React.Fragment>
							})
						}
						<div>
							{/* <AppPromotion /> */}
						</div>
					</React.Fragment>
					:
					<React.Fragment>
					{
						model.loading ? 
						<div className="container_loader">
							<div className="each_item_loader" style={{backgroundColor: 'unset', opacity: 1}}>
								<div className="spinner_conent"><Spinner animation="border" variant="primary"/></div>
							</div>
						</div>
						: null
					}
					</React.Fragment>
				}

				</div>
				
				
				<FooterPage />
			</React.Fragment>
    );
  }
}
