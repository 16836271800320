import React  from 'react';
import { Col, Row, Button, InputGroup, FormControl, Spinner, Breadcrumb, Modal } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import SessionHelper from '../../Core/SessionHelper';
import CustomerCartDataAccess from '../../DataAccess/CustomerCartDataAccess';
import WishlistDataAccess from '../../DataAccess/WishlistDataAccess';
import CustomerCartListViewModel from '../../ViewModels/CustomerCart/CustomerCartListViewModel';
import HeaderPage from '../Core/HeaderPage';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CartLoader from '../../Skeletons/CartLoader';
import FooterPage from '../Core/FooterPage';
import update from 'immutability-helper';
import OwlCarousel from 'react-owl-carousel';

class CustomerCartListPage extends BaseComponent<any, CustomerCartListViewModel> {

  constructor(props:any) {
      super(props);
      SessionHelper.HandleAuthentication();
      this.state = new BaseState<CustomerCartListViewModel>(new CustomerCartListViewModel());
  }


  componentDidMount(){
		if(!this.Auth){
			return null;
		}
    this.BindData();
  }


  BindData(){
    this.GetList();
  }


  GetList = async  () => {
    CustomerCartDataAccess.GetList(async (res: BaseResponse) => {
      if(!res.success){
        return this.ShowToast(res.message,"warning");
      }
			if(!res.data.length){
				this.UpdateCartCount(0);
				setTimeout(() => {
					window.location.href = '/';
				}, 500);
				return this.ShowToast('Cart is empty',"warning");
			}
			
			let carts = {
				items: res.data,
				amount: 0,
				charge: 0,
				cgst: 0,
				igst: 0,
				sgst: 0,
				discount: 0,
				total: 0,
				total_item: res.data.length
			}
			
			let newState = update(this.state, {
				Model: {
					carts: {$set: carts},
					IsPageLoading: {$set: false},
					cartLoading: {$set: false},
				}
			});
			this.setState(newState, () => {
				this.UpdateCartCount(carts.total_item);
				this.updateOrderSummery();
			});
    });

  }
	
	updateOrderSummery = (cartData?:any) => {
		let model = this.state.Model;
		let carts = {
			items: model.carts.items,
			amount: 0,
			charge: 0,
			cgst: 0,
			igst: 0,
			sgst: 0,
			discount: 0,
			total: 0,
			total_item: 0
		}
	
		if(model.carts.items.length > 0){
			model.carts.items.forEach((cart:any) => {
				if(cartData){
					carts.amount += cartData.quantity * cartData.product_sale_price;
					carts.cgst += cartData.quantity * cartData.cgst;
					carts.igst += cartData.quantity * cartData.igst;
					carts.sgst += cartData.quantity * cartData.sgst;
					carts.charge = cartData.charge;
				}else{
					carts.amount += cart.quantity * cart.product_sale_price;
					carts.cgst += cart.quantity * cart.cgst;
					carts.igst += cart.quantity * cart.igst;
					carts.sgst += cart.quantity * cart.sgst;
					carts.charge = cart.charge;
				}
			});
			carts.total = (carts.amount + carts.cgst + carts.igst + carts.sgst + carts.charge);
    }
		
		let newState = update(this.state, {
			Model: {
				carts: {$set: carts}
			}
		});
		this.setState(newState);
	}

  Update = async  (item: any, type: string, index: number) => {
		let quantity = item.quantity;
		if(type === 'increment'){
			quantity += 1;
		}else{
			quantity -= 1;
		}
		let params = {
			id: item.id,
			quantity: quantity
		}
    this.state.Model.cartLoading = true;
		this.UpdateViewModel();
    CustomerCartDataAccess.Update(params, async (res: BaseResponse) => {
			if(!res.success){
        return this.ShowToast(res.message,"warning");
      }
			this.state.Model.cartLoading = false;
			this.state.Model.carts.items[index].quantity = res.data.quantity;
			this.UpdateViewModel();
			this.updateOrderSummery(res.data);
			this.ShowToast(res.message,"success");
    });

  }

  Delete = async (item: any) => {
    this.state.Model.cartRemoveModal = true;
    this.state.Model.actionItem = item;
		this.UpdateViewModel();
  }
	
	deleteCartProcess = () => {
		let model = this.state.Model;
		let actionItem = model.actionItem;
		this.state.Model.cartLoading = true;
		this.state.Model.cartRemoveModal = false;
		this.UpdateViewModel();
    CustomerCartDataAccess.Delete(actionItem.id, async (res: BaseResponse) => {
      if(!res.success){
				this.state.Model.cartLoading = false;
				this.UpdateViewModel();
        return this.ShowToast(res.message,"warning");
      }
			if(model.carts.items.length === 1){
				this.UpdateCartCount(0);
				setTimeout(() => {
					window.location.href = '/';
				}, 1000);
				return this.ShowToast('Cart is empty', "warning");
			}
			let items = model.carts.items;
			items.forEach((cart:any, index: number) => {
				if(cart.id === actionItem.id){
					items.splice(index, 1);
				}
			});
			
			let newState = update(this.state, {
				Model: {
					carts: {
						items: {$set: items}
					},
					cartLoading: {$set: false}
				}
			});
			this.setState(newState, () => {
				this.updateOrderSummery();
			});
			this.ShowToast(res.message,"success");
    });
	}
	
	handleCloseCartRemoveCobfirm = () => {
		this.state.Model.cartRemoveModal = false;
		this.UpdateViewModel();
	}
	
	BuyNow = (item: any) => {
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		return this.props.history.push("/buynow?quantity=" + item.quantity + '&slug=' + item.product_slug);
	}
	
	goForShoppping = () => {
		window.location.href = '/';
	}
	
	getDiscount = (promo: string) => {
		
	}
	
	addToCart = (id: number) => {
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		let model = this.state.Model;
		if(this.checkIsProductProcessing(id)) return;
		let params = {
			product_id: id,
			quantity: 1
		};
		
		this.state.Model.itemsLoading.push(id);
    this.UpdateViewModel();
		
		CustomerCartDataAccess.Create(params, async (res: BaseResponse) => {
			model.itemsLoading = model.itemsLoading.filter(i => i !== id);
			this.UpdateViewModel();
			if(!res.success){
				this.showCartErrorMessage(res);
				return false;
			}
			
			this.UpdateCartCount(res.data.cart_count);
			this.ShowToast(res.message,"success");
		});
	}
	
	checkIsProductProcessing(id: number){
		let model = this.state.Model;
		if(model.itemsLoading.includes(id)){
			return true;
		}else{
			return false;
		}
	}
	
	addToWishlist = (item: any, key: any) => { 
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		
		//let model = this.state.Model;
		if(item.wishlist_id){
			WishlistDataAccess.RemoveWishlist(item.id, (res: BaseResponse) => {
				if(!res.success){
					return;
				}
				this.state.Model.products[key]['wishlist_id'] = "";
				this.UpdateViewModel();
				this.UpdateWishlistCount(res.data.wishlist_count);
				this.ShowToast(res.message,"success");
				
			});
			
		}else{
			let params = {
				product_id: item.id
			}
			WishlistDataAccess.AddWishlist(params, (res: BaseResponse) => {
				if(!res.success){
					return;
				}
				this.state.Model.products[key]['wishlist_id'] = res.data.wishlist_count;
				this.UpdateViewModel();
				this.UpdateWishlistCount(res.data.wishlist_count);
				this.ShowToast(res.message,"success");
			});
		}
		
	}
	
	CheckOut = () => {
		this.props.history.push("/checkouts");
	}

	render(){
		let model = this.state.Model;
		
		if(!this.Auth){
			return null;
		}
		
		return(
			<div>
				<HeaderPage />
				
				<div className="container home">
					<section>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="breadcrumb-container">
									<Breadcrumb>
										<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
										<Breadcrumb.Item active>Shopping Cart</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</Col>
						</Row>
						<Row className="position_relative">
							{
							(model.IsPageLoading || !model.carts) ?
							<Col xs={12} sm={8} md={8} lg={8}>
								<Row className="position_relative">
								{
									[...Array(4)].map((x, i) => {
										return <Col lg={12} key={i}>
											<CartLoader />
										</Col>
									})
								}
								</Row>
							</Col>
							:
							<React.Fragment>
								<Col xs={12} sm={8} md={8} lg={8}>
									<div className="cart_column_header">
										<h1>Products</h1>
									</div>
									<div className="underline"> &nbsp;</div>
									{
										model.carts.items.map((item:any, key:number) => {
										return <div className="cart_single_image_container" key={key}>
											<Col xs={12} sm={4} md={4} lg={4} style={{padding: '0px'}}>
												{
													item.product_media ?
													<Link to={'/products/' + item.product_slug } className="image link-to-product">
													  <div className="cart_single_image">
														<img src={item.product_media} alt="puja" />
													 </div>
													</Link>
													 : null
												}
											</Col>
											<Col xs={12} sm={8} md={8} lg={8}>
												<div className="cart_page_header">
													<h1> { item.product_name }</h1>
												</div>
												<div className="cart-stock-status"><h1> In Stock</h1></div>
												<div className="carts-item-price">
													{ this.priceDisplay('Rs. ', item.product_sale_price)}
													{
														item.product_price ?
														<del style={{color: '#c5c5c5'}}> { this.priceDisplay('Rs. ', item.product_price) }</del>
														: null
													}
												</div>
												<div className="product-qty">
													<div className="def-number-input number-input">
														<InputGroup className="">
															<InputGroup.Prepend>
																<Button onClick={() => this.Update(item, 'decrement', key)} disabled={item.quantity === 1}><FontAwesomeIcon icon="minus" /></Button>
															</InputGroup.Prepend>
															<FormControl value={item.quantity} disabled={true} />
															<InputGroup.Append>
																<Button onClick={() => this.Update(item, 'increment', key)}><FontAwesomeIcon icon="plus" /></Button>
															</InputGroup.Append>
														</InputGroup>
													</div>
												</div>
												<div className="sub-total">
													<label style={{margin: '0px'}}>Total Price:</label> <span>{this.priceDisplay('Rs. ', item.quantity * item.product_sale_price) }</span>
												</div>
												<div className="cartpage_btns">
													<Button variant="primary" className="btn btn-primary" onClick={() => this.Delete(item)}>Remove</Button>
													<Button variant="primary" className="btn btn-primary" onClick={() => this.BuyNow(item)}>Buy now</Button>
												</div>
											</Col>
										</div>
										})
									}
								</Col>
								<Col xs={12} sm={4} md={4} lg={4}>
									<div className="cart_column_header">
										<h1>Order Summary</h1>
									</div>
									<div className="underline">&nbsp;</div>
									<div className="cart_total_area">
										<div className="cart_page_total_column">
											<div className="total-amount-name">Amount</div>
											<div className="subtotal-amount">{ this.priceDisplay('Rs. ', model.carts.amount) }</div>
										</div>
										<div className="cart_page_total_column">
											<div className="total-amount-name">CGST</div>
											<div className="subtotal-amount">{ this.priceDisplay('Rs. ', model.carts.cgst) }</div>
										</div>
										<div className="cart_page_total_column">
											<div className="total-amount-name">IGST</div>
											<div className="subtotal-amount">{ this.priceDisplay('Rs. ', model.carts.igst) }</div>
										</div>
										<div className="cart_page_total_column">
											<div className="total-amount-name">SGST</div>
											<div className="subtotal-amount">{ this.priceDisplay('Rs. ', model.carts.sgst) }</div>
										</div>
										<div className="cart_page_total_column">
											<div className="total-amount-name">Shipping Charge</div>
											<div className="subtotal-amount">{ this.priceDisplay('Rs. ', model.carts.charge) }</div>
										</div>
										<div className="cart_page_total_column">
											<div className="total-amount-name">Discount</div>
											<div className="subtotal-amount">{ this.priceDisplay('Rs. ', model.carts.discount) }</div>
										</div>
										<div className="cart_page_total_column">
											<div className="total-amount-name">Total Amount</div>
											<div className="subtotal-amount">{ this.priceDisplay('Rs. ', model.carts.total) }</div>
										</div>
									</div>
									<div className="cart_page_order_summary_btns">
										<Button variant="primary" className="btn-primary" onClick={() => this.CheckOut()}><span>Proceed To Checkout</span></Button>
										<Button variant="primary" className="btn-primary" onClick={() => this.goForShoppping()}><span>Continue Shopping</span></Button>
									</div>
									<Row>
										<div className="el-input">
											<Col xs={12} sm={12} md={12} lg={12}>
												<div className="promo_code_area">
													<FormControl value={model.promo} placeholder="Enter Your Promo Code" className="el-input__inner"  />
													<Button variant="primary" className="btn" onClick={() => this.getDiscount(model.promo)}><span>Apply</span></Button>
												</div>
											</Col>
										</div>
									</Row>
								</Col>
								{
									model.cartLoading ? 
									<div className="each_item_loader">
										<div className="spinner_conent"><Spinner animation="border" variant="primary"/></div>
									</div>
									: null
								}
							</React.Fragment>
							}
						</Row>
						<div className="divider">&nbsp;</div>
						{
							model.products.length ?
						<React.Fragment>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="our-carts">
									<div className="new_products_container">
										<div className="puja_feature_heading">
											<h1>Best Sellers</h1>
										</div>
										<div className="new_products_row">
											<OwlCarousel
												autoplay={false}
												className="owl-theme"
												loop
												margin={10}
												items={4}
												dots={false}
											    nav={true}
											>
											{
												model.products.map((item:any, index:number) => {
												return <div key={index}>
														<div className="contain-product layout-default">
														{
															item.media ?
															<div className="product-thumb">
																<Link to={'/products/' + item.slug } className="image link-to-product">
																	<div className="max-fit-2">
																		<img src={item.media} alt="puja" />
																	</div>
																</Link>
															</div>
															: null
														}
														<div className="info">
															<h4 className="product-title"><Link to={'/products/' + item.slug } className="pr-name">{ item.name }</Link></h4>
															<div className="price ">
																<span className="price-amount"><span className="currencySymbol"></span>{ this.priceDisplay('Rs. ', item.sale_price) }</span>
																{
																	item.price !== item.sale_price ?
																	<span className="price-amount original_price"><span className="currencySymbol"></span>{this.priceDisplay('Rs. ', item.price) }</span>
																	: null
																}
																</div>
																<div className="slide-down-box">
																	<p className="message">All products are carefully selected to ensure food safety.</p>
																	<div className="buttons">
																		<a href="javascript: void(0)" onClick={(e) => this.addToWishlist(item, index)} className={item.wishlist_id  ? "" : "btn compare-btn"}>
																			<FontAwesomeIcon icon="heart" />
																		</a>
																		<a href="javascript: void(0)" onClick={() => this.addToCart(item.id)} className="btn add-to-cart-btn">
																			<FontAwesomeIcon icon="shopping-cart" /> {this.checkIsProductProcessing(item.id) ? 'Processing...' : 'add to cart'}
																		</a>
																		<a href="javascript: void(0)" onClick={(e) => this.addToWishlist(item, index)} className={item.wishlist_id  ? "" : "btn compare-btn"}>
																			<FontAwesomeIcon icon="heart" />
																		</a>
																	</div>
																</div>
															</div>
													</div>
													</div>
													})
											}
												</OwlCarousel>
											</div>
										</div>
									</div>
									</Col>
							</Row>
							</React.Fragment>
							: null
							
						}
					</section>
					
					<Modal
						show={model.cartRemoveModal}
						onHide={() => this.handleCloseCartRemoveCobfirm()}
						keyboard={false}
						className="cart_remove_item_modal"
						centered
					>
						<Modal.Header closeButton>
							<Modal.Title>Warning</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p>Are you sure to delete?</p>
							<div className="text-right">
								<Button variant="default" onClick={() => this.deleteCartProcess()} >Yes</Button>
								<Button variant="default" onClick={() => this.handleCloseCartRemoveCobfirm()}>No</Button>
							</div>
						</Modal.Body>
					</Modal>
					
				</div>
				
				
				<FooterPage />
			</div>
		);
	}
}

export default withRouter(CustomerCartListPage)
