import BaseViewModel from "../../Core/BaseViewModel";

export default class CustomerLoginViewModel extends  BaseViewModel{
    mobile:string="";
    password:string="";
		onProcess: boolean = false;
		formErrors:any = {};
		loginErrorMsg: string = "";
		facebookBtnTxt: string = " Facebook";
		googleBtnTxt: string = "Google";
		twitterBtnTxt: string = "";
}