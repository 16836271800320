import React  from 'react';
import {Col, Row, Breadcrumb } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
//import Pagination from "../../Core/Pagination";
import CategoryDataAccess from '../../DataAccess/CategoryDataAccess';
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import CategoryViewModel from '../../ViewModels/Category/CategoryViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import { BaseLoader } from '../Core/BaseView';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import ReactImageMagnify from 'react-image-magnify';
//import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';

class CategoryPage extends BaseComponent<any,CategoryViewModel> {
  constructor(props:any) {
    super(props);
    this.state = new BaseState<CategoryViewModel>(new CategoryViewModel());
    this.state.Model.loading = true;
  }
	
	componentDidMount(){
		this.getCategories();
	}
	
	getCategories(){
		CategoryDataAccess.GetCategories((res: BaseResponse) => {
      if(!res.success){
				this.state.Model.loading = false;
				this.UpdateViewModel();
				return;
      }
			this.state.Model.loading = false;
			this.state.Model.categories = res.data;
			this.UpdateViewModel();
			
		});
		
	}
	
	redirectUrl = (item: any) => {
		this.props.history.push("/category/" + item.slug);
	}
  
	render(){
		let model = this.state.Model;
		
		return(
			<React.Fragment>
				<HeaderPage />
				<div className="category_single_main_page">
				<div className="container">
				
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="breadcrumb-container">
									<Breadcrumb>
										<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
										<Breadcrumb.Item active>Categories</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</Col>
						</Row>
						<Row>
							{
								model.loading ?
								<Col xs={12} sm={12} md={12} lg={12}>
									<div className="container_loader">
										<BaseLoader/>
									</div>
								</Col>
								:
								<React.Fragment>
								{
									model.categories.map((item: any, index: number) => {
									return <Col xs={12} sm={3} md={3} lg={3} key={index}>
										<div className="category_single_area" onClick={() => this.redirectUrl(item)}>
											<div className="category_single_image">
												{
													item.media ?
													<img src={item.media} alt={item.name} />
													: null
												}
											</div>
											<div className="category_image_description">
												<h1>{item.name}</h1>
											</div>
										</div>
									</Col>
									})
								}
								
								</React.Fragment>
							}
						</Row>
						</div>
				</div>
				
				<FooterPage />
				
			</React.Fragment>
    );
  }
}

export default withRouter(CategoryPage)
