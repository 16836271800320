import React  from 'react';
//import {Col, Container, Row, Tab, Tabs, Button } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseState from '../../Core/BaseState';
import BaseResponse from '../../Core/BaseResponse';
import DealsViewModel from '../../ViewModels/Home/DealsViewModel';
import HomeDataAccess from '../../DataAccess/HomeDataAccess';
import OwlCarousel from 'react-owl-carousel';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { Link } from "react-router-dom";

type Props = {
	section?: any
} 

export default class Deals extends BaseComponent<any,DealsViewModel> {
  constructor(props:any) {
    super(props);
    this.state = new BaseState<DealsViewModel>(new DealsViewModel());
		this.state.Model.section = 'section' in this.props ? this.props.section : null;
		this.state.Model.loading = true;
  }
	
	componentDidMount(){
		this.dealList();
	}
	
	dealList(){
		let model = this.state.Model;
		let params = {
			section_name: model.section.section_name
		}
		HomeDataAccess.GetProducts(params,(res: BaseResponse) => {
      this.state.Model.loading = false;
      if(!res.success){
				this.UpdateViewModel();
				return;
      }
			this.state.Model.products = res.data.items;
			this.UpdateViewModel();
			
		});
		
	}
  
	render(){
		let model = this.state.Model;
		
		if(model.loading || !model.products.length){
			return false;
		}
		
		return(
			<div className="home">
				<OwlCarousel
					autoplay={false}
					className="owl-theme"
					loop
					margin={10}
					items={6}
					dots={false}
					nav={true}
				>
					{
						model.products.map((item: any, key:number) => {
						return <div key={key}>
							{
								item.media ?
								<div>
									<img src={item.media} className="img-fluid" alt="puja" />
								</div>
								: null
							}
						</div>
						})
					}
				</OwlCarousel>
			</div>
    );
  }
}