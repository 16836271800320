import React  from 'react';
//import Modal from 'react-bootstrap/Modal';
import { Col, Row, Button, InputGroup, FormControl, Spinner, Form, Breadcrumb, Table  } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import SessionHelper from '../../Core/SessionHelper';
import CustomerAddressDataAccess from '../../DataAccess/CustomerAddressDataAccess';
import CustomerOrderDataAccess from '../../DataAccess/CustomerOrderDataAccess';
import ProductDataAccess from '../../DataAccess/ProductDataAccess';
import CustomerAddressModel from '../../Models/CustomerAddressModel';
import CustomerBuyNowViewModel from '../../ViewModels/CustomerCart/CustomerBuyNowViewModel';
import HeaderPage from '../Core/HeaderPage';
import { Link, withRouter } from 'react-router-dom';
import CartLoader from '../../Skeletons/CartLoader';
import FooterPage from '../Core/FooterPage';
//import update from 'immutability-helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CustomerBuyNowPage extends BaseComponent<any, CustomerBuyNowViewModel> {
  constructor(props:any) {
		super(props);
		SessionHelper.HandleAuthentication();
		this.state = new BaseState<CustomerBuyNowViewModel>(new CustomerBuyNowViewModel());
  }

  componentDidMount(){
		if(!this.Auth){
			return null;
		}
		this.getGivenProduct();
		this.getProductDetails();
		
  }

  getGivenProduct = () => {
	let query = new URLSearchParams(this.props.location.search);
	this.state.Model.slug = query.get('slug');
	this.state.Model.quantity = query.get('quantity');
  }

  getProductDetails = async  () => {
	let model = this.state.Model;
	

	ProductDataAccess.GetProductBySlug(model.slug, async (res: BaseResponse) => {
		if(!res.success){
			return this.ShowToast(res.message,"warning");
		 }
		 model.product = res.data;
		 model.amount = model.quantity * model.product.sale_price;
		 model.cgst =  model.quantity * model.product.cgst;
		 model.sgst =  model.quantity * model.product.sgst;
		 model.igst =  model.quantity * model.product.igst;
		 model.shipping_charge = model.product.shipping_charge;
		 model.total = model.amount + model.cgst + model.sgst + model.igst + model.shipping_charge;
		 this.UpdateViewModel();
	});

	CustomerAddressDataAccess.GetList(async (res: BaseResponse) => {
		if(!res.success){
		  return this.ShowToast(res.message,"warning");
		}
		this.state.Model.addresses=res.data as CustomerAddressModel[];
		this.UpdateViewModel();
	});
  }


	SelectAddress = (event: any) => {
    const { target: { value } } = event;
		this.state.Model.select_address = parseInt(value);
		this.UpdateViewModel();
	}
	
	addAddress = () => {
		this.props.history.push("/addresses");
	}
	
	Proceed = async () => {
		let model = this.state.Model;
		if(!model.select_address){
			return this.ShowToast('Please select address',"warning");
		}
		
		if(!model.selected_method){
			return this.ShowToast('Please select payment option',"warning");
		}
		
		if(this.state.Model.order_processing){
			return;
		}
		
		this.state.Model.order_processing=true;
		this.UpdateViewModel();
		
		let orderData = {
			mode: 'buynow',
			product_id: model.product.id,
			amount: model.total,
			quantity: model.quantity,
			default_address_id: model.select_address,
			payment_mode: model.selected_method,
			coupon_id: 0, //model.coupon_id,
			// discount_amount: model.deducted_amount,
			discount_amount: 0,
			use_wallet: false
		};
		
		CustomerOrderDataAccess.CreateBuyNowOrder(orderData, async (res: BaseResponse) => {
			if(!res.success){
				this.state.Model.order_processing=false;
				this.UpdateViewModel();
				return this.ShowToast(res.message,"warning");
			}
			
			if(model.selected_method === 'cash'){
				this.ShowToast(res.message,"success");
				this.UpdateCartCount(0);
				setTimeout(() => {
					this.props.history.push("/orders");
				}, 1000);
			}else{ 
				let res_data = res.data;
				let order_id = res_data.order_id;
				let _this = this;
				var options = {
						key: res_data.payment_method_Id,
						amount: res_data.amount,
						currency: "INR",
						name: res_data.site_name,
						description: res_data.description,
						image: res_data.site_image,
						order_id: res_data.payment_order_id,
						remember_customer: false,
						prefill: {
							name: res_data.name,
							email: res_data.email,
							contact: res_data.phone,
							method: model.selected_method
						},
						notes:{
							address: res_data.address,
						},
						theme:{
							color:"#1545ef",
						},
						handler: function(res2:any){
							res2 = {...res2, ...{
								order_id: order_id,
								mode: 'buynow'
							}};
							
							CustomerOrderDataAccess.PaymentVerifyBuyNowOrder(res2, async (res: BaseResponse) => {
								_this.state.Model.order_processing=false;
								_this.UpdateViewModel();
								if(!res.success){
									let params = {
										order_id: order_id,
										payment_status: 'failed',
										transaction_id: res2.razorpay_payment_id
									}
									_this.updatePaymentStatus(params);
									return;
									//return _this.ShowToast(res.message,"warning");
								}
								_this.UpdateCartCount(0);
								_this.ShowToast(res.message,"success");
								setTimeout(() => {
									_this.props.history.push("/orders");
								}, 1000);
								
							});
							
						},
						"modal": {
							"ondismiss": function(){
								let params = {
									order_id: order_id,
									payment_status: 'failed',
									transaction_id: ''
								}
								_this.updatePaymentStatus(params);
							}
						}
				};
				var razorPay = new (window as any).Razorpay(options);
				razorPay.open();
			}
			
			//this.ShowToast(res.message,"success");
			
			
    });
		
	}
	
	updatePaymentStatus = async (params: any) => {
		let postParams = {
			order_id: params.order_id,
			payment_status: params.payment_status,
			payment_transaction_id: params.transaction_id,
			payment_mode: 'online'
		};
		CustomerOrderDataAccess.UpdatePaymentStatusBuyNowOrder(postParams, async (res: BaseResponse) => {
			this.state.Model.order_processing=false;
			this.UpdateViewModel();
			this.UpdateCartCount(0);
      if(!res.success){
				setTimeout(() => {
					this.props.history.push("/orders");
				}, 1000);
        return this.ShowToast(res.message,"warning");
      }
			
			this.ShowToast(res.message,"success");
			setTimeout(() => {
				this.props.history.push("/orders");
			}, 1000);
			
		});
		
	}

	Update = async  (type: string) => { 
    let model = this.state.Model;  
		if(type === 'increment'){
			if(model.quantity >= model.product.quantity) return;
			
			model.quantity = Number.parseInt(model.quantity) + 1;
		}else{
			if(model.quantity <= 1) return;
			
			model.quantity = Number.parseInt(model.quantity) - 1;
		}
		
		this.updateOrderSummery(type);
		this.UpdateViewModel();
		//this.ShowToast(res.message,"success");
  }

  updateOrderSummery = (type: any) => {
		let model = this.state.Model;
		
		if(type === 'increment'){
			model.amount = model.quantity * model.product.sale_price;
			model.cgst +=  model.product.cgst;
			model.sgst +=   model.product.sgst;
			model.igst +=  model.product.igst;
			model.total = model.amount + model.cgst + model.sgst + model.igst
		}
		else{
			model.amount = model.quantity * model.product.sale_price;
			model.cgst -=  model.product.cgst;
			model.sgst -=   model.product.sgst;
			model.igst -=  model.product.igst;
			model.total = model.amount + model.cgst + model.sgst + model.igst
		}
  }
	
	handlePaymentOption = (event: any) => {
		const { target: { value } } = event;
		this.state.Model.selected_method = value;
		this.UpdateViewModel();
	}
	
	render(){
		let model = this.state.Model;
		
		if(!this.Auth){
			return null;
		}
		
		
		return(
			<div>
				<HeaderPage />
				
				<div className="container">
					<section className="checkout-main-container">
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="breadcrumb-container">
									<Breadcrumb>
										<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
										<Breadcrumb.Item active>Secure Checkout</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</Col>
							{
							(model.IsPageLoading || !model.product) ?
							<Col xs={12} sm={12} md={12} lg={12}>
								<Row className="position_relative">
								{
									[...Array(4)].map((x, i) => {
										return <Col lg={12} key={i}>
											<CartLoader />
										</Col>
									})
								}
								</Row>
							</Col>
							:
							<React.Fragment>
								<Col xs={12} sm={8} md={8} lg={8}>
									<Row>
										<Col xs={12} sm={12} md={12} lg={12}>
											<div className="checkout-header">
												<h1>Billing Details</h1>
											</div>
											<div className="checkout-table-container">
												<Table striped bordered hover>
													<thead>
														<tr>
															<th>Address</th>
															<th>Select Address</th>
														</tr>
													</thead>
													<tbody>
														{
															model.addresses.map((item:any, index:number) => {
															return <tr key={index}>
																<td>{item.street}, {item.city}, {item.state}, {item.country}</td>
																<td>
																	<Form.Check 
																		checked={model.select_address === item.id}
																		value={item.id}
																		custom 
																		inline 
																		label="" 
																		name="address"
																		type="radio" 
																		id={"address-" + item.id}
																		onChange={this.SelectAddress}
																	/>
																</td>
															</tr>
														})
														}
													</tbody>
												</Table>
											</div>
										</Col>
										<Col xs={12} sm={12} md={12} lg={12}>
											<div className="new-address-btn">
												<Button variant="primary" className="el-button el-button--primary" onClick={() => this.addAddress()}>Add a New Address</Button>	
											</div>
										</Col>
										{<Col xs={12} sm={12} md={12} lg={12}>
											 <div className="order-summary">
                 							   <h1>Order Summary</h1>
               								 </div>
										</Col> }
											  
										<Col xs={12} sm={4} md={4} lg={4} style={{padding: '0px'}}>
											{
												model.product.media ?
												<div className="order-summary-image">
												   	<Link to={"/products/" + model.slug} className="image link-to-product">
													 <img src={model.product.media} alt="puja" />
												   </Link>
												</div>
												: null
											}
										</Col>
										<Col xs={12} sm={8} md={8} lg={8}>
											<div className="order-summary-product-header">
												<h1> { model.product.name }</h1>
											</div>
											<div className="order-summary-product-price">
												{
													model.product.price !== model.product.sale_price?
													<del> { this.priceDisplay('Rs. ', model.product.price) }</del>
													: null
												} &nbsp;  
												{ this.priceDisplay('Rs. ', model.product.sale_price)}
											</div>
											<div className="product-qty">
												<div className="def-number-input number-input">
													<InputGroup className="">
														<InputGroup.Prepend>
															<Button onClick={() => this.Update('decrement')} disabled={model.quantity <= 1}><FontAwesomeIcon icon="minus" /></Button>
														</InputGroup.Prepend>
														<FormControl value={model.quantity} disabled={true} />
														<InputGroup.Append>
															<Button onClick={() => this.Update('increment')} disabled={model.quantity >= model.product.quantity}><FontAwesomeIcon icon="plus" /></Button>
														</InputGroup.Append>
													</InputGroup>
												</div>
												<div className="checkout_final_price">
												   { this.priceDisplay('Rs. ', model.amount) }
												</div>
												{
													model.product.quantity <= 0 ?
													<div className="product-stock-status">
														<h1 className="text-danger"><b>Out of Stock</b></h1>
													</div>
													: null
												}
											</div>
										</Col>
										
										{
											model.product.quantity > 0 ?
											<React.Fragment>
												<Col xs={12} sm={12} md={12} lg={12}>
													{/*<div className="order-summary">
														<el-checkbox v-model="walletCheck" @change="applyWallet($event)">Use Your Wallet Balance ({{priceDisplay('Rs. ', walletBalance)}})</el-checkbox>
													</div>*/}
													<div className="product_payment_mode">
														{
															Object.keys(this.paymentOptions).map((k:string) => {
															return <Form.Check 
																name="payment_options" 
																label={this.paymentOptions[k]} 
																value={k} 
																custom 
																type="radio" 
																id={`inline-${k}`}
																onChange={this.handlePaymentOption}
																key={k} 
															/>
															})
														}
														
													</div>
												</Col>

												
											</React.Fragment>
											: null
										}
									</Row>
								</Col>
								
								<Col xs={12} sm={4} md={4} lg={4}>
									<div className="checkout-header">
										<h1>Price Details</h1>
									</div>
									<div className="checkout_order_area">
										<div className="checkout_page_total_column">
											<div className="total-amount-name">{model.product.name} X {model.quantity}</div>
											<div className="subtotal-amount">{ this.priceDisplay('Rs. ', model.amount) }</div>
										</div>
										<div className="checkout_page_total_column"><div className="total-tax-name">CGST</div>
											 <div className="total-tax-amount">{ this.priceDisplay('Rs. ', model.cgst) }</div>
										</div>
										<div className="checkout_page_total_column"><div className="total-tax-name">IGST</div>
											 <div className="total-tax-amount">{ this.priceDisplay('Rs. ', model.igst) }</div>
										</div>
										<div className="checkout_page_total_column"><div className="total-tax-name">SGST</div>
										 <div className="total-tax-amount">{ this.priceDisplay('Rs. ', model.sgst) }</div>
										</div>
										<div className="checkout_page_total_column"><div className="total-tax-name">Shipping Charge</div>
										 <div className="total-tax-amount">{ this.priceDisplay('Rs. ', model.shipping_charge) }</div>
										</div>
										<div className="checkout_page_total_column"><div className="total-name">Total Amount</div>
										 <div className="total-amount">{ this.priceDisplay('Rs. ', model.total) }</div>
										</div>
									</div>
								</Col>
							</React.Fragment>
							}
						</Row>
						<Row>
						<Col xs={12} sm={12} md={12} lg={12}>
													<div className="margin-top-30 checkout-placeorder">
														<Button variant="primary" className="el-button el-button--primary" onClick={() => this.Proceed()} disabled={model.order_processing}>
															{
																model.order_processing ? 
																<div>
																	<Spinner
																		as="span"
																		animation="border"
																		size="sm"
																		role="status"
																		aria-hidden="true"
																	/>&nbsp;Processing...
																</div>
																:
																"Place Order"
															}
														</Button>
													</div>
												</Col>
						</Row>
					</section>
				</div>
				
				<FooterPage />
			</div>
		);
	}
}

export default withRouter(CustomerBuyNowPage)