import React  from 'react';
import { Form,  Button, Spinner, Col, Alert, Row} from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import CustomerDataAccess from '../../DataAccess/CustomerDataAccess';
import ForgetPassowordViewModel from '../../ViewModels/Customer/ForgetPassowordViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import { withRouter } from 'react-router-dom';

class ForgetPasswordPage extends BaseComponent<any,ForgetPassowordViewModel> {
  constructor(props:any) {
    super(props);
    var model = new ForgetPassowordViewModel();
    this.state = new BaseState(Object.assign(model,this.Auth));
  }

  componentDidMount(){
	  //console.log(this.state.Model.token_status)
  }
	
	
	
	submitEmail = async (event:any) => {

		event.preventDefault();
		
		if(!this.ValidateFrom() || this.state.Model.email_processing){
			return false;
		}

		var model = this.state.Model;
		this.state.Model.email_processing=true;
		this.UpdateViewModel();
		
		let params = {
			email: model.email
		}
		CustomerDataAccess.ForgetPassword(params,(res: BaseResponse) => {
			model.email_processing=false;
			model.email = '';
			model.isSuccess = res.message;

			if(!res.success){
				model.token_status = 2;
				this.UpdateViewModel();
			    //return this.ShowToast(res.message,"warning");
			}
			else{
				model.token_status = 1;
				this.UpdateViewModel();
				//return this.ShowToast(res.message,"success");
			}
		});
	}

	ValidateFrom(){
		var model = this.state.Model; 
		var status=true;
		var mailformat =/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		
		if(!model.email){
			model.ErrorEmail="Email is required";
			status=false;
		}

		else if(!model.email.match(mailformat)){
			model.ErrorEmail="You have entered an invalid email address!";
			status=false;
		}else{
			model.ErrorEmail="";
		}

		this.UpdateViewModel();
		
	 return status?true:false;
	}
	
  
	render(){
		let model = this.state.Model;
		
		return(
			<React.Fragment>
				<HeaderPage />
				
				<div className="forget_password_area">
					<div className="container">
						<Row>
							<Col md={{span: 8, offset: 2}}>
								<div className="forget_password_box_area">
								  <div className="cart_header">
                    				 <h1>Forget Password</h1>
                  				  </div>
									<Form className="forget_password_form" onSubmit={this.submitEmail}>
									 {
										model.token_status === 1 ?
										<Alert variant="success">
										{model.isSuccess}
										</Alert>
										: null
									 }
									 {  model.token_status === 2 ?
										<Alert variant="danger">
										 {model.isSuccess}
										</Alert>
										: null
									  }
										<Row>
											<Col xs={12} sm={12} md={12} lg={12} xl={12}>
												<Form.Group controlId="email">
													<Form.Label>Email</Form.Label>
													<Form.Control type="email"
														placeholder="Enter Your Email"
														name="email"
														value={model.email}
														onChange={this.SetModelValue} />
													{model.ErrorEmail ? <Form.Text className="text-danger">{model.ErrorEmail}</Form.Text> : null}
												</Form.Group>
											</Col>
										</Row>

										<Row>
											<Col xs={12} md={6} sm={6} lg={6} xl={6}>
											  <div>
													<Button variant="primary" type="submit" >
														{
														model.email_processing ? 
														<div className="spinner_container">
															<Spinner
															as="span"
															animation="border"
															size="sm"
															role="status"
															aria-hidden="true"
															/>&nbsp;Processing...
														</div>
														:
														"Submit"
														}
                          							  </Button>
												</div>
											</Col>
										</Row>
									</Form>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				
				<FooterPage />
				
			</React.Fragment>
    );
  }
}

export default withRouter(ForgetPasswordPage)
