import BaseApi from "../Core/BaseApi";

export default class HeaderDataAccess extends BaseApi{

  public static GetCategories(callback:any){
		this.BaseURLGetRequest("customer/categories", {}, callback);
	}
	
	public static GetAutocompletes(callback:any){
		this.BaseURLGetRequest("customer/autocomplete", {}, callback);
	}

}