import BaseApi from "../Core/BaseApi";

export default class HomeDataAccess extends BaseApi{

  public static GetSlides(params:any, callback:any){
		this.BaseURLGetRequest("customer/slides", params, callback);
	}
	
	public static GetSection(params:any, callback:any){
		this.BaseURLGetRequest("customer/sections", params, callback);
	}
	
	public static GetProducts(params:any, callback:any){
		this.BaseURLGetRequest("customer/products", params, callback);
	}
	
	public static GetEventList(callback:any){
		this.BaseURLGetRequest("customer/special-events", {}, callback);
	}
	
	public static GetCommunities(callback:any){
		this.BaseURLGetRequest("customer/communities", {}, callback);
	}
	
	public static GetPartners(callback:any){
		this.BaseURLGetRequest("customer/partners", {}, callback);
	}
	
	public static GetRoomDecor(callback:any){
		this.BaseURLGetRequest("customer/room-decor", {}, callback);
	}
	
	public static GetRecentlyViewedProduct(callback:any){
		this.BaseURLGetRequest("customer/recently-viewed-products", {}, callback);
	}

	public static GetPageContent(params:any, callback:any){
		this.BaseURLGetRequest("customer/pages", params, callback);
	}

	public static GetSpecialOffers(callback:any){
		this.BaseURLGetRequest("customer/special-offers", {}, callback);
	}
	

}