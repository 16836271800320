import BaseApi from "../Core/BaseApi";

export default class ProductDataAccess extends BaseApi{

  public static GetProductById(id:number, callback:any){
		this.BaseURLGetRequest("customer/products/" + id, {}, callback);
	}
	
	public static GetProducts(params:any, callback:any){
		this.BaseURLGetRequest("customer/products", params, callback);
	}
	
	public static GetProductBySlug(slug:string, callback:any){
		this.BaseURLGetRequest("customer/product-details/" + slug, {}, callback);
	}

	public static GetRelatedProductsById(params:any, callback:any){
		this.BaseURLGetRequest("customer/related-products", params, callback);
	}

	public static saveRating(model:any,callback:any){
		this.BaseURLPostRequest("customer/product-review",model,callback);
	}
	
}