import BaseViewModel from "../../Core/BaseViewModel";
import ProductModel from "../../Models/ProductModel";
import CategoryModel from "../../Models/CategoryModel";

export default class ProductSearchViewModel extends BaseViewModel{
	loading: boolean = false;
	products: ProductModel[] = [];
	search: any;
	type: any;
	page: number = 1;
	totalPage: number = 0;
	itemsLoading: Array<number> = [];
	categories: CategoryModel[] = [];
	discount_range: Array<number> = [];
	offers: Array<string> = [];
	price_range: price_range[] = [];
	collaps_category: boolean = true;
	collaps_price: boolean = true;
	collaps_ratings: boolean = true;
	collaps_discount: boolean = true;
	collaps_availability: boolean = true;
	filters: any = {min_price: "", max_price: "", category_id: "", min_rating: "", min_discount: "", availability: "", sort_by: "popularity"};
	productSearching: boolean = false;
	currentPageUrl: string = "";
	hasFilter: boolean = false;
	mobileSortFilterTab: string = "";
	isFirstLoad: boolean = true;
	clearSearch: boolean = false;
}

export class price_range{
	max: Number = 0;
	min: string = "";
	price: string = "";
}