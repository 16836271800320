import BaseViewModel from "../../Core/BaseViewModel";
import CustomerAddressModel from "../../Models/CustomerAddressModel";


export default class CustomerCartCheckoutViewModel extends  BaseViewModel{
	carts:any;
	address_id :number=0;
	cartLoading: boolean=false;
	select_address: number=0;
	addresses: CustomerAddressModel[] = [];
	selected_method: string = "";
	deducted_amount:number=0;
	order_processing: boolean=false;
}