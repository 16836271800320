import BaseApi from "../Core/BaseApi";
import UIHelper from "../Core/UIHelper";

export default class NotificationDataAccess extends BaseApi{

  public static GetList(params:any, callback:any){
		this.BaseURLGetRequest("customer/notifications", params, callback);
  }
	
	public static MarkRead(params:any, callback:any){
		var urlData = UIHelper.ModelConvertToUrl(params);
		this.BaseURLGetRequest("customer/mark-read" + urlData, {}, callback);
	}
}