import React  from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import ShippingViewModel from '../../ViewModels/Home/ShippingViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import HomeDataAccess from '../../DataAccess/HomeDataAccess';
import { BaseLoader } from '../Core/BaseView';
import { withRouter } from 'react-router-dom';

class ShippingViewPage extends BaseComponent<any,ShippingViewModel> {
  constructor(props:any) {
    super(props);
    this.state = new BaseState<ShippingViewModel>(new ShippingViewModel());
    this.state.Model.loading = true;
  }
	
  componentDidMount(){
	this.getShippingInfo();
  }

  getShippingInfo(){
		let model = this.state.Model;
		let params ={page: 'shipping'}
		
		HomeDataAccess.GetPageContent(params, async (res: BaseResponse) => {
			model.loading = false;		
			if(!res.success){
			 return this.ShowToast(res.message,"warning");
			}
			let data = res.data;
			model.title = data.title;
			model.content = data.content;
			this.UpdateViewModel();
		});
	}
	
	render(){
		let model = this.state.Model;
		
		return(
			<React.Fragment>
			  <HeaderPage />
			    <div>
			   {
				 model.loading ?
				  <Col xs={12} sm={12} md={12} lg={12}>
					<div className="container_loader">
						<BaseLoader/>
					</div>
				   </Col>
				  :
				  <div className="shipping_godskart">
					  <Container>
						  <Row>
						  <Col xs={12} sm={12} md={12} lg={12} dangerouslySetInnerHTML={{__html: model.content}}>
						  									
						  </Col>
						  </Row>
					  </Container>
				  </div>
			   }
			  </div>		
		      <FooterPage />
		  </React.Fragment>
    );
  }
}

export default withRouter(ShippingViewPage)
