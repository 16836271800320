import BaseApi from "../Core/BaseApi";

export default class CustomerEventDataAccess extends BaseApi{

	public static GetEventList(params:any, callback:any){
		this.BaseURLGetRequest("customer/events", params, callback);
	}

	public static GetEventDetails(slug:any, callback:any){
		this.BaseURLGetRequest("customer/events/" + slug, {}, callback);
	}

	public static GetCommunities(callback:any){
		this.BaseURLGetRequest("customer/communities", {}, callback);
	}

	public static GetCommunityDetails(slug:any, callback:any){
		this.BaseURLGetRequest("customer/communities/" + slug, {}, callback);
	}
}