import BaseViewModel from "../../Core/BaseViewModel";
import EventModel from "../../Models/EventModel";
import CommunityModel from "../../Models/CommunityModel";

export default class CustomerEventsListViewModel extends  BaseViewModel{
	events:EventModel[]=[];
	specialEvents:EventModel[]=[];
	communities:CommunityModel[]=[];
	loading: boolean = false;
	community_id: string = "";
	
}