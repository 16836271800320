import BaseViewModel from "../../Core/BaseViewModel";

export default class ResetPassowordViewModel extends  BaseViewModel{
    id:number=0;
    pwd_processing:boolean=false;
    password:string="";
    confirm_password:string="";
    access_token: any="";
    isSuccess: string="";
    loading: boolean = false;
    valid_token : boolean = false;
    status:number=0;

    //form validation
    ErrorPassword:String="";
    ErrorConfirmPassword:String="";
}