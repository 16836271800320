import BaseViewModel from "../../Core/BaseViewModel";
//import SectionModel from "../../Models/SectionModel";
import ProductModel from "../../Models/ProductModel";

export default class ProductDetailsViewModel extends BaseViewModel{
	loading: boolean = false;
	productSlug: string = "";
	product: any;
	quantity: number = 0;
	subtotal: number = 0;
	addToCartProcessing: boolean = false;
	relatedProducts: ProductModel[] = [];
	itemsLoading: Array<number> = [];
	reviews: any = [];

	rateUsModal: boolean=false;
	actionProcessing: boolean = false;
	canReview: boolean = false;
	review: string="";
	title: string="";
	rating: number=0;
	actionItem:any;
	totalStar: number = 5;

	ErrorReview: string="";
	ErrorTitle: string="";
}