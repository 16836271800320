import BaseViewModel from "../../Core/BaseViewModel";

export default class CustomerServiceViewModel extends  BaseViewModel{
    id:number=0;
    service_processing:boolean=false;
    first_name:string="";
    last_name:string="";
    email:string="";
    phone:string="";
    message:string="";
    loading: boolean = false;
    formErrors:any = {};
}