import React  from 'react';
import {Col} from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
//import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import HomeViewModel from '../../ViewModels/Home/HomeViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import { BaseLoader } from '../Core/BaseView';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';

class TrackingViewPage extends BaseComponent<any,HomeViewModel> {
  constructor(props:any) {
    super(props);
    this.state = new BaseState<HomeViewModel>(new HomeViewModel());
    this.state.Model.loading = false;
  }
	
	componentDidMount(){
		
	}
	
	render(){
		let model = this.state.Model;
		
		return(
			<React.Fragment>
			  <HeaderPage />
			    <div>
			   {
				 model.loading ?
				  <Col xs={12} sm={12} md={12} lg={12}>
					<div className="container_loader">
						<BaseLoader/>
					</div>
				   </Col>
				  :
				  <div className="container faq_area_container"><h2>Tracking</h2>

				 	 <p>We are working on it, we will get back to you shortly.</p>
				  
				     <p>&nbsp;</p>
				  </div>
			   }
			  </div>		
		      <FooterPage />
		  </React.Fragment>
    );
  }
}

export default withRouter(TrackingViewPage)
