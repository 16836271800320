import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PagePath from './PagePath';
import PageNotFoundPage from './PageNotFoundPage';
import LoginPage from '../Auth/LoginPage';
import RegisterPage from '../Auth/RegisterPage';
import BrahminPage from '../Brahmins/BrahminPage';
import NotificationPage from '../Notifications/NotificationPage';
import CustomerAddressCreatePage from '../CustomerAddress/CustomerAddressCreatePage';
import CustomerAddressUpdatePage from '../CustomerAddress/CustomerAddressUpdatePage';
import CustomerAddressListPage from '../CustomerAddress/CustomerAddressListPage';
import ProductViewPage from '../Product/ProductViewPage';
import CategoryProductPage from '../Category/CategoryProductPage';
import CategoryPage from '../Category/CategoryPage';
import ProductSearchPage from '../Product/ProductSearchPage';
import MyProfilePage from '../Customer/MyProfilePage';
import WishlistPage from '../Wishlist/WishlistPage';
import CustomerOrderListPage from '../CustomerOrder/CustomerOrderListPage';
import CustomerOrderViewPage from '../CustomerOrder/CustomerOrderViewPage';
import CustomerEventListPage from '../CustomerEvents/CustomerEventListPage';
import CustomerCartListPage from '../CustomerCart/CustomerCartListPage';
import CustomerCartCheckoutPage from '../CustomerCart/CustomerCartCheckoutPage';
import CustomerServicePage from '../CustomerService/CustomerService';
import CustomerBuyNowPage from '../CustomerCart/CustomerBuyNowPage';
import ShippingPage from '../Shipping/ShippingViewPage';
import TrackingPage from '../Tracking/TrackingViewPage';
import ReturnPolicyViewPage from '../ReturnPolicy/ReturnPolicyViewPage';
import PrivacyPolicyPage from '../PrivacyPolicy/PrivacyPolicyViewPage';
import AboutUsPage from '../AboutUs/AboutUsViewPage';
import FaqPage from '../Faq/FaqViewPage';
import ResetPasswordPage from '../Customer/ResetPasswordPage';
import ForgetPasswordPage from '../Customer/ForgetPasswordPage';
import SpecialOffersPage from '../SpecialOffers/SpecialOffersPage';
import ScrollToTop from '../../Core/ScrollToTop';
import HomePage from '../Home/HomePage';
import EventDetailsViewPage from '../Event/EventDetailsViewPage';
import CommunityDetailsViewPage from '../Community/CommunityDetailsViewPage';
import TicketCreatePage from '../Ticket/TicketCreatePage';
import TicketsPage from '../Ticket/TicketsPage';
import TicketViewPage from '../Ticket/TicketViewPage';

export default function AppStart(props: any) {

  var allPaths: PagePath[] = [];
  allPaths.push(new PagePath("HomePage", "/", "", HomePage));
  allPaths.push(new PagePath("LoginPage", "/login", "", LoginPage));
  allPaths.push(new PagePath("RegisterPage", "/register", "", RegisterPage));
  allPaths.push(new PagePath("BrahminPage", "/brahmins", "", BrahminPage));
  allPaths.push(new PagePath("NotificationPage", "/notifications", "", NotificationPage));
  allPaths.push(new PagePath("AddressCreate", "/customer/address/create", "",CustomerAddressCreatePage));
  allPaths.push(new PagePath("AddressUpdate", "/customer/address/update/:id", "",CustomerAddressUpdatePage));
  allPaths.push(new PagePath("AddressList", "/addresses", "",CustomerAddressListPage));
  allPaths.push(new PagePath("CategoryPage", "/categories", "", CategoryPage));
  allPaths.push(new PagePath("ProductViewPage", "/products/:slug", "", ProductViewPage));
  allPaths.push(new PagePath("CategoryProductPage", "/category/:slug", "", CategoryProductPage));
  allPaths.push(new PagePath("ProductSearchPage", "/search-results", "", ProductSearchPage));
  allPaths.push(new PagePath("OrderView", "/order-details/:id", "",CustomerOrderViewPage));
  allPaths.push(new PagePath("OrderList", "/orders", "",CustomerOrderListPage));
  allPaths.push(new PagePath("EventDetailsViewPage", "/events/:slug", "",EventDetailsViewPage));
  allPaths.push(new PagePath("EventList", "/events", "",CustomerEventListPage));
  allPaths.push(new PagePath("WishlistPage", "/wishlists", "",WishlistPage));
	allPaths.push(new PagePath("CustomerCartListPage", "/carts", "",CustomerCartListPage));
  allPaths.push(new PagePath("CustomerCartCheckoutPage", "/checkouts", "",CustomerCartCheckoutPage));
  allPaths.push(new PagePath("CustomerBuyNowPage", "/buynow", "",CustomerBuyNowPage));
  allPaths.push(new PagePath("MyProfilePage", "/my-profile", "",MyProfilePage));
  allPaths.push(new PagePath("CustomerServicePage", "/customer-service", "",CustomerServicePage));
  allPaths.push(new PagePath("ShippingPage", "/shipping", "", ShippingPage));
  allPaths.push(new PagePath("TrackingPage", "/tracking", "", TrackingPage));
  allPaths.push(new PagePath("ReturnPolicyViewPage", "/return-policy", "", ReturnPolicyViewPage));
  allPaths.push(new PagePath("PrivacyPolicyPage", "/privacy-policy", "", PrivacyPolicyPage));
  allPaths.push(new PagePath("AboutUsPage", "/about", "", AboutUsPage));
  allPaths.push(new PagePath("FaqPage", "/faq", "", FaqPage));
  allPaths.push(new PagePath("ResetPasswordPage", "/reset-password", "", ResetPasswordPage));
  allPaths.push(new PagePath("ForgetPasswordPage", "/forget-password", "", ForgetPasswordPage));
  allPaths.push(new PagePath("SpecialOffersPage", "/special-offers", "", SpecialOffersPage));
  allPaths.push(new PagePath("CommunityDetailsViewPage", "/communities/:slug", "", CommunityDetailsViewPage));
  allPaths.push(new PagePath("TicketViewPage", "/order/:orderid/tickets/:id", "", TicketViewPage));
  allPaths.push(new PagePath("TicketsPage", "/order/:orderid/tickets", "", TicketsPage));
  allPaths.push(new PagePath("TicketCreatePage", "/order/:orderid/ticket/create", "", TicketCreatePage));
  return (
    <Router>
			<ScrollToTop>
				<Switch>
					{allPaths.map((path,key) => (
						<Route  key={path.Text} path={path.Path} exact={key === 0} component={path.Component} />
					))}
					<Route path="*" component={PageNotFoundPage} />
				</Switch>
			</ScrollToTop>
    </Router>
  );
}
