import React  from 'react';
import { withRouter } from 'react-router-dom';
import BaseComponent from '../../Core/BaseComponent';
import BaseState from '../../Core/BaseState';
import HomeViewModel from '../../ViewModels/Home/HomeViewModel';

class PageNotFoundContent extends BaseComponent<any, HomeViewModel> {
  constructor(props:any) {
		super(props)
		this.state = new BaseState<HomeViewModel>(new HomeViewModel());
  }
  
	

  render() {
    //var model = this.state.Model;

    return (
     <div className="">
        <div id="notfound">
          <div className="notfound">
            <div className="notfound-404"></div>
            <h1>404</h1>
            <h2>The page you are looking for does not exist</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PageNotFoundContent)