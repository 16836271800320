import BaseViewModel from "../../Core/BaseViewModel";
export default class CustomerOrderDetailsViewModel extends  BaseViewModel{
	order:any;
	cancelModal:boolean=false;
	reorderModal:boolean=false;
	ticketModal:boolean=false;
	actionProcessing:boolean=false
	selected_method:string=""
	loading: boolean = false;
	activities: any;
	count: any;
}