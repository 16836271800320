import React  from 'react';
import { Form,  Button, Spinner, Col, Row, Modal } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
//import SessionHelper from '../../Core/SessionHelper';
import CustomerDataAccess from '../../DataAccess/CustomerDataAccess';
import CustomerEditViewModel from '../../ViewModels/Customer/CustomerEditViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import { withRouter } from 'react-router-dom';

class MyProfilePage extends BaseComponent<any,CustomerEditViewModel> {
  constructor(props:any) {
    super(props);
    var model = new CustomerEditViewModel();
    this.state = new BaseState(Object.assign(model,this.Auth));
  }
	
	EditProfile = (event: any) => {
		event.preventDefault();
		//var model = this.state.Model;
		
		if(!this.ValidateFrom()){
			return false;
		} 
		
		this.submitProcess();
	}
	
	submitProcess = () => {
		var model = this.state.Model;
		this.state.Model.profile_processing=true;
		this.UpdateViewModel();
		let params = {
			base64Image: '',
			first_name: model.first_name,
			last_name: model.last_name,
			email: model.email,
			mobile: model.mobile,
			step: model.step,
			otp: model.otp
		}
		CustomerDataAccess.EditProfile(params,(res: BaseResponse) => {
			this.state.Model.profile_processing=false;
			if(!res.success){
				try {
					let errArr = JSON.parse(res.message);
					if('email' in errArr){
						model.ErrorEmail = errArr.email[0];
					}
					if('mobile' in errArr){
						model.ErrorMobile = errArr.mobile[0];
					}
					if('otp' in errArr){
						model.ErrorOtp = errArr.otp[0];
					}
					
					this.UpdateViewModel();
					return;
				} catch (e) {
					this.UpdateViewModel();
					return this.ShowToast(res.message,"success");
				}
				//return this.ShowToast(res.message,"success");
			}
			
			if(this.Auth.mobile !== model.mobile && model.step === 1){
				this.state.Model.otpModal = true;
				this.UpdateViewModel();
			}else{
				let data = {
					first_name: this.state.Model.first_name,
					last_name: this.state.Model.last_name,
					email: this.state.Model.email,
					mobile: model.mobile,
					step: 1
				}
				this.UpdateAuthData(data);
				this.state.Model.otpModal = false;
				this.UpdateViewModel();
			}
			
			this.ShowToast(res.message,"success");
		});
	}

	ValidateFrom(){
		var model = this.state.Model; 
		var status=true;
		var mailformat =/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		var phoneno = /^\d{10}$/;

		if(!model.first_name){
			model.ErrorFirstName="First name is required";
			status=false;
		}else{
			model.ErrorFirstName="";
		}

		if(!model.last_name){
			model.ErrorLastName="Last name is required";
			status=false;
		}else{
			model.ErrorLastName="";
		}
		
		if(!model.email){
			model.ErrorEmail="Email is required";
			status=false;
		}

		else if(!model.email.match(mailformat)){
			model.ErrorEmail="You have entered an invalid email address!";
			status=false;
		}else{
			model.ErrorEmail="";
		}

		if(!model.mobile){
			model.ErrorMobile="Mobile number is required";
			status=false;
		}
		else if(!model.mobile.toString().match(phoneno)){
			model.ErrorMobile="Invalid mobile number";
			status=false;
		}else{
			model.ErrorMobile="";
		}

		this.UpdateViewModel();
		
	 return status?true:false;
	}
	
	handleCloseOtpModal = () => {
		this.state.Model.otpModal = false;
		this.UpdateViewModel();
	}
	
	submitOtp = () => {
		var model = this.state.Model; 
		var status = true;

		if(!model.otp){
			model.ErrorOtp="Please enter OTP.";
			status=false;
		}else{
			model.ErrorOtp="";
		}
		this.UpdateViewModel();
		if(!status) return;
		
		model.step = 2;
		this.setState({
			Model: model
		}, () => {
			this.submitProcess();
		});
		
	}
  
	render(){
		let model = this.state.Model;
		
		return(
			<React.Fragment>
				<HeaderPage />
				
				<div className="profile_area">
					<div className="container">
						<Row>
							<Col md={{span: 8, offset: 2}}>
								<div className="customer_profile_area" style={{padding: '30px'}}>
									<Form onSubmit={this.EditProfile}>
										<Row>
											<Col xs={12} md={12} sm={12} lg={12} xl={12}>
												<div className="profile_image_container">

												{/* <el-upload
														:on-preview="handlePreview"
														:on-remove="handleRemove"
														:auto-upload="false"
														:on-change="handleBeforeUpload"
														:before-remove="handleBeforeRemove"
														:thumbnail-mode="false"
														:multiple="false"
														:limit="1"
														:file-list="formMyProfile.photo"
														:on-exceed="handleExceed"
														drag
														className="picture-uploader user-photo"
														action=""
														list-type="picture-card"
														accept=".png,.jpeg,.jpg"
													>
														<i className="el-icon-plus picture-uploader-icon"></i>
												</el-upload> */ }

												</div>
											</Col>
										</Row>

										<Row>
											<Col xs={6} md={6} sm={6} lg={6} xl={6}>
												<Form.Group controlId="first_name">
                          <Form.Control type="text"
                            placeholder="Enter First Name"
                            name="first_name"
                            value={model.first_name}
                            onChange={this.SetModelValue} />
                          {model.ErrorFirstName ? <Form.Text className="text-danger">{model.ErrorFirstName}</Form.Text> : null}
                        </Form.Group>
											</Col>
											
											<Col xs={6} md={6} sm={6} lg={6} xl={6}>
												<Form.Group controlId="last_name">
                          <Form.Control type="text"
                            placeholder="Enter Last Name"
                            name="last_name"
                            value={model.last_name}
                            onChange={this.SetModelValue} />
                          {model.ErrorLastName ? <Form.Text className="text-danger">{model.ErrorLastName}</Form.Text> : null}
                        </Form.Group>
											</Col>
											
											<Col xs={6} md={6} sm={6} lg={6} xl={6}>
												<Form.Group controlId="email">
                          <Form.Control type="email"
                            placeholder="Enter Email"
                            name="email"
                            value={model.email}
                            onChange={this.SetModelValue} />
                          {model.ErrorEmail ? <Form.Text className="text-danger">{model.ErrorEmail}</Form.Text> : null}
                        </Form.Group>
											</Col>

											<Col xs={6} md={6} sm={6} lg={6} xl={6}>
												<Form.Group controlId="mobile">
                          <Form.Control type="mobile"
                            placeholder="Enter mobile"
                            name="mobile"
                            value={model.mobile}
                            onChange={this.SetModelValue} />
                          {model.ErrorMobile ? <Form.Text className="text-danger">{model.ErrorMobile}</Form.Text> : null}
                          </Form.Group>
											</Col>
										</Row>

										<Row>
											<Col xs={12} md={12} sm={12} lg={12} xl={12} className="text-right">
												<div className="profile_save_btn">
													<Button variant="primary" type="submit" disabled={model.profile_processing}>
                                {
                                  model.profile_processing ? 
                                  <div className="spinner_container">
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />&nbsp;Processing...
                                  </div>
                                  :
                                  "Save"
                                }
                           </Button>
												</div>
											</Col>
										</Row>
									</Form>
									<Row>
									{/* <el-col :xs="24" :sm="24" :md="24" :lg="24" v-if="checked">
										<div className="profile_brahmin_section">
											<el-checkbox v-model="checked" style="margin-bottom:15px;">Are You Brahmin ?</el-checkbox>
										</div>
									</el-col>
									<el-col :xs="24" :sm="24" :md="24" :lg="24" v-if="checked">
										<div className="profile_brahmin_section">
									<el-button round type="primary" @click="showModal()">Edit Brahmin Profile</el-button>
									</div>
									</el-col> */}
									</Row>
								</div>
							</Col>
						</Row>
					</div>
					
					<Modal
						show={model.otpModal}
						onHide={() => this.handleCloseOtpModal()}
						backdrop="static"
						keyboard={false}
					>
						<Modal.Header>
							<Modal.Title>Save Your Profile</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form>
								<Form.Group controlId="otp">
									<Form.Control type="text"
										placeholder="Enter You OTP"
										name="otp"
										value={model.otp}
										isInvalid={!!model.ErrorOtp}
										onChange={this.SetModelValue} />
									{model.ErrorOtp ? <Form.Text className="text-danger">{model.ErrorOtp}</Form.Text> : null}
								</Form.Group>
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="success" onClick={() => this.submitOtp()} disabled={model.profile_processing}>
								{
									model.profile_processing ?
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									:
									"Submit"
								}
							</Button>
						</Modal.Footer>
					</Modal>
					
				</div>
				
				<FooterPage />
				
			</React.Fragment>
    );
  }
}

export default withRouter(MyProfilePage)
