import CustomerModel from "../Models/CustomerModel";

export default class SessionHelper{
    public static Auth:string="token";
    public static UserLocation:string="userlocation";
		
    public static SetSession(customer:CustomerModel){
        localStorage.setItem(SessionHelper.Auth,JSON.stringify( customer))
    }
    public static GetSession():CustomerModel{
       var item= localStorage.getItem(SessionHelper.Auth);
       if(!item) {
            return item as unknown as any;
       }

       return JSON.parse(item) as any;
    }
		
		public static SetUserLocation(latlong:any){
        localStorage.setItem(SessionHelper.UserLocation,JSON.stringify(latlong))
    }
		public static GetUserLocation(){
       var item = localStorage.getItem(SessionHelper.UserLocation);
       if(!item) {
            return item as unknown as any;
       }

       return JSON.parse(item) as any;
    }
		
    public static HandleAuthentication() {
        var user = SessionHelper.GetSession();
        if (user === null) {
            window.location.href="/";
        }
    }
}