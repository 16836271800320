import React  from 'react';
import BaseComponent from "../../Core/BaseComponent";
import { Form, Button, Spinner, Col, Container, Row } from 'react-bootstrap';
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import SessionHelper from '../../Core/SessionHelper';
import CustomerAddressDataAccess from '../../DataAccess/CustomerAddressDataAccess';
import CustomerAddressUpdateViewModel from '../../ViewModels/CustomerAddress/CustomerAddressUpdateViewModel';

type Props = {
  closeEvent?:any,
  addresses?: [],
  selected_index?: 0

}

export default class CustomerAddressUpdatePage extends BaseComponent<any, CustomerAddressUpdateViewModel> {

  constructor(props:any) {
      super(props);
      SessionHelper.HandleAuthentication();
      this.state = new BaseState<CustomerAddressUpdateViewModel>(new CustomerAddressUpdateViewModel());
      this.state.Model.loading = true;
    }

  componentDidMount(){ 
    if(!this.Auth){
			return null;
    }
    else{
      this.state.Model.selected_index = this.props.selected_index;
      this.state.Model.addresses = this.props.addresses;

      let all_addresss = this.props.addresses[this.state.Model.selected_index];
      for(let index in all_addresss){
        this.SetModelValueByObject(index, all_addresss[index]);
       }
      this.UpdateViewModel();
    }
  }
  
  resetAddressData(data: any, index: any){
    let address = this.state.Model.addresses;
    address[index] = data;
  }

  Update = async  () => {
    if(!this.ValidateFrom()){
      return false;
    }
    this.state.Model.address_processing=true; 
    this.state.Model.default = (this.state.Model.default) ? 1 : 0;
    
    CustomerAddressDataAccess.Update(this.state.Model,async (res: BaseResponse) => {
      this.state.Model.address_processing=false;
      if(!res.success){
        this.UpdateViewModel();
        return this.ShowToast(res.message,"warning");
      }else{
        let updated_address = res.data;
        this.resetAddressData(updated_address, this.state.Model.selected_index);
        this.props.closeEvent(updated_address);
        this.UpdateViewModel();
        return this.ShowToast(res.message,"success");
      }
    });
  }

  ValidateFrom = () => {
    var model=this.state.Model;
    var status=true;

    if(!model.zip){
      model.ErrorZip="Pincode is required";
      status=false;
    }else{
      model.ErrorZip="";
    }
    if(!model.city){
      model.ErrorCity="City is required";
      status=false;
    }else{
      model.ErrorCity="";
    }
    
    if(!model.state){
      model.ErrorState="State is required";
      status=false;
    }else{
      model.ErrorState="";
    }

    /*if(!model.landmark){
      model.ErrorLandmark="Landmark is required";
      status=false;
    }else{
      model.ErrorLandmark="";
    }*/

    if(!model.name){
      model.ErrorName="Name is required";
      status=false;
    }else{
      model.ErrorName="";
    }

    if(!model.mobile){
      model.ErrorMobile="Mobile no is required";
      status=false;
    } else if(model.mobile.toString().length !== 10) {
      model.ErrorMobile="Mobile no is not valid";
      status=false;
    } else {
      const num = parseInt(model.mobile.toString().replace(/[^0-9.]/g, '')).toString();
      if(num.length !== 10){
        model.ErrorMobile="Mobile no is not valid";
        status=false;
      } else {
        model.ErrorMobile="";
      }
      
    }
  
    if(!model.state){
      model.ErrorState="State is required";
      status=false;
    }else{
      model.ErrorState="";
    }

    if(!model.country){
      model.ErrorCountry="Country is required";
      status=false;
    }else{
      model.ErrorCountry="";
    }

    if(!model.street){
      model.ErrorStreet="Street is required";
      status=false;
    }else{
      model.ErrorStreet="";
    }
    

    this.UpdateViewModel();
    return status;
  }

    render(){
      var model=this.state.Model;
      if(!this.Auth){
        return null;
      }
        return(
          <div>
            <Container>
              <Row>
                <Col xs={12} className="no-padd profile_edit_area">
                        <div className="cart_summary_area create_address_page">
                          <Form.Group controlId="name">
														<Form.Label>Name</Form.Label>
                                <Form.Control type="text"
                                  placeholder="Name"
                                  name="name"
                                  value={model.name}
                                  onChange={this.SetModelValue}
																	isInvalid={!!model.ErrorName}
                                />
                                {model.ErrorName ? <Form.Text className="text-danger">{model.ErrorName}</Form.Text>:null}
                          </Form.Group>
                          <Form.Group controlId="mobile">
														<Form.Label>Enter mobile</Form.Label>
                                <Form.Control type="text"
                                  placeholder="Enter mobile"
                                  name="mobile"
                                  value={model.mobile}
                                  onChange={this.SetModelValue}
                                  maxLength={10}
																	isInvalid={!!model.ErrorMobile}
                                />
                                {model.ErrorMobile ? <Form.Text className="text-danger">{model.ErrorMobile}</Form.Text>:null}
                           </Form.Group>
                           <Form.Group controlId="street">
														<Form.Label>Street</Form.Label>
                                <Form.Control type="text"
                                  placeholder="Street"
                                  name="street"
                                  value={model.street}
                                  onChange={this.SetModelValue}
																	isInvalid={!!model.ErrorStreet}
                                />
                                {model.ErrorStreet ? <Form.Text className="text-danger">{model.ErrorStreet}</Form.Text>:null}
                            </Form.Group>
                            <Form.Group controlId="street2">
															<Form.Label>Street 2</Form.Label>
                                <Form.Control type="text"
                                  placeholder="Street 2"
                                  name="street2"
                                  value={model.street2}
                                  onChange={this.SetModelValue}
                                />
                            </Form.Group>
                            <Form.Group controlId="city">
																<Form.Label>City</Form.Label>
                                <Form.Control type="text"
                                    placeholder="City"
                                    name="city"
                                    value={model.city}
                                    onChange={this.SetModelValue}
																		isInvalid={!!model.ErrorCity}
                                  />
                                  {model.ErrorCity ? <Form.Text className="text-danger">{model.ErrorCity}</Form.Text>:null}
                              </Form.Group>

                              <Form.Group controlId="state">
																<Form.Label>State</Form.Label>
                                <Form.Control type="text"
                                  placeholder="State"
                                  name="state"
                                  value={model.state}
                                  onChange={this.SetModelValue}
																	isInvalid={!!model.ErrorState}
                                />
                                {model.ErrorState ? <Form.Text className="text-danger">{model.ErrorState}</Form.Text>:null}
                              </Form.Group>

                              <Form.Group controlId="country">
																<Form.Label>Country</Form.Label>
                                <Form.Control type="text"
                                  placeholder="Country"
                                  name="country"
                                  value={model.country}
                                  onChange={this.SetModelValue}
																	isInvalid={!!model.ErrorCountry}
                                />
                                {model.ErrorCountry ? <Form.Text className="text-danger">{model.ErrorCountry}</Form.Text>:null}
                              </Form.Group>
                              
                              <Form.Group controlId="zip">
																<Form.Label>Pincode</Form.Label>
                                <Form.Control type="Pincode"
                                  placeholder="Pincode"
                                  name="zip"
                                  value={model.zip}
                                  onChange={this.SetModelValue}
																	isInvalid={!!model.ErrorZip}
                                />
                                {model.ErrorZip ? <Form.Text className="text-danger">{model.ErrorZip}</Form.Text>:null}
                              </Form.Group>
                              
                              
                              <Form.Group controlId="landmark">
																<Form.Label>Landmark</Form.Label>
                                <Form.Control type="text"
                                  placeholder="Landmark"
                                  name="landmark"
                                  value={model.landmark}
                                  onChange={this.SetModelValue}
                                />
                              </Form.Group>

                              <Button 
                                onClick={this.Update}
                                disabled={model.address_processing}
                                >
                                {
                                  model.address_processing ? 
                                  <div>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />&nbsp;Processing...
                                  </div>
                                  :
                                  "Save"
                                }
                                </Button>
                        </div>
                    </Col>
                   </Row>
              </Container>   
          </div>
        );
    }
  }
