import React  from 'react';
import {Col, Container, Row, Alert} from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseState from '../../Core/BaseState';
import BaseResponse from '../../Core/BaseResponse';
import NotificationModel from '../../Models/NotificationModel';
import NotificationsViewModel from '../../ViewModels/Notifications/NotificationsViewModel';
import NotificationDataAccess from '../../DataAccess/NotificationDataAccess';
import SessionHelper from '../../Core/SessionHelper';
import HeaderPage from '../Core/HeaderPage';
import { BaseLoader } from '../Core/BaseView';
import FooterPage from '../Core/FooterPage';
import Pagination from "../../Core/Pagination";

export default class NotificationPage extends BaseComponent<any, NotificationsViewModel> {

  	constructor(props:any) {
      super(props);
      SessionHelper.HandleAuthentication();
      this.state = new BaseState<NotificationsViewModel>(new NotificationsViewModel());
	  this.state.Model.loading = true;
  	}


  	componentDidMount(){
		if(!this.Auth){
			return null;
		}
		this.BindData();
  	}


  	BindData(){
    	this.GetList();
  	}


  	GetList = async  () => {
		let params = {
			page: this.state.Model.current_page
		}
		NotificationDataAccess.GetList(params, async (res: BaseResponse) => {
			if(!res.success){
				return this.ShowToast(res.message,"warning");
			}
			this.state.Model.loading = false;		
			this.state.Model.notifications=res.data.items as NotificationModel[];
			this.state.Model.totalPage = res.data.total_pages;
			this.UpdateViewModel();
    	});

  	}
	
	redirectOrderPage = (item:any) => {
		this.props.history.push('/orders?notification=' + item.id);
	}

	paginationClick = (page:number) => {
		let model = this.state.Model;
		model.current_page = page;
		model.loading = true;
		this.setState({
			Model: model
		}, () => {
			this.GetList();
		})
	}
	
	render(){
		let model = this.state.Model;

		if(!this.Auth){
			return null;
		}

		return(
			<div>
				<HeaderPage/>
				<div className="notification_main_page">
					<Container>
						<Row>
							<div className="checkout-header notification">
								<h1>Notifications</h1>
							</div>
							<React.Fragment>
								{
								model.loading ?
								<Col xs={12} sm={12} md={12} lg={12}>
									<div className="container_loader">
										<BaseLoader/>
									</div>
								</Col>
								:
								<React.Fragment>
								{
									!model.notifications.length ?
									<Col xs={12} sm={12} md={12} lg={12}>
										<Alert variant="danger" className="not_found_product_container">
											<span>Sorry! No notifications found.</span>
										</Alert>
									</Col>
									:
									<React.Fragment>
										{model.notifications.map((item) =>
											<Container onClick={() => this.redirectOrderPage(item)} key={item.id}>
												<Row>
													<Col xs={12} sm={12} md={12} lg={12}>
														<div className="notification_area">
														<Col xs={12} sm={9} md={9} lg={9}>
															<p>{item.message}</p>
														</Col>
														<Col xs={12} sm={3} md={3} lg={3}>
															{
																item.notification_date != null? 
																<p style={{textAlign: 'right'}}>{item.notification_date}</p>
																: null
															} 
														</Col>
														</div> 
													</Col>
												</Row>
											</Container>
										)}
										</React.Fragment>
									}
									</React.Fragment>
								}

								<Col xs={12} sm={12} md={12} lg={12}>
									<Pagination
										page={model.current_page}
										totalPage={model.totalPage}
										clickHandler={this.paginationClick}
									/>
								</Col>
							</React.Fragment>
						</Row>
					</Container>
				</div> 
				<FooterPage />
			</div>
		);
	}
}
