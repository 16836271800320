import React  from 'react';
import {Col, Form, Modal, Breadcrumb, Button, InputGroup, FormControl, Spinner, Alert } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import ProductDataAccess from '../../DataAccess/ProductDataAccess';
import CustomerCartDataAccess from '../../DataAccess/CustomerCartDataAccess';
import WishlistDataAccess from '../../DataAccess/WishlistDataAccess';
import RecentlyViewedDataAccess from '../../DataAccess/RecentlyViewedDataAccess';
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import ProductDetailsViewModel from '../../ViewModels/Product/ProductDetailsViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import { BaseLoader } from '../Core/BaseView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactImageMagnify from 'react-image-magnify';
import update from 'immutability-helper';
import { Link, withRouter } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';

class ProductViewPage extends BaseComponent<any,ProductDetailsViewModel> {
  constructor(props:any) {
    super(props);
    this.state = new BaseState<ProductDetailsViewModel>(new ProductDetailsViewModel());
		this.state.Model.productSlug = this.props.match.params.slug;
    this.state.Model.loading = true;
  }
	
	componentDidMount(){
		this.getProductDetails();
	}

	componentDidUpdate(prevProps:any) {
		if (this.props.location !== prevProps.location) {
			let model = this.state.Model;
			model.productSlug = this.props.match.params.slug;
			model.loading = true;
			this.setState({
				Model: model
			}, () => {
				this.getProductDetails();
			})
		}
	}
	
	getProductDetails(){
		let model = this.state.Model;
		ProductDataAccess.GetProductBySlug(model.productSlug, (res: BaseResponse) => {
		if(!res.success){
					this.state.Model.loading = false;
					this.UpdateViewModel();
					return;
		}
			
			model.product = res.data;
			model.quantity = 1;

			if(model.canReview === false){
				//model.product.ratings = 0;
			}

			if(model.product.reviews.length > 0){
				model.title = model.product.reviews[0]['title'];
				model.review = model.product.reviews[0]['review'];
				model.rating = model.product.reviews[0]['rating'];
				//model.product.ratings = 
				this.UpdateViewModel();
			}

			this.getRelatedProducts(model.product.id);
			this.updateRecentView(model.product.id);

			model.loading = false;

			this.setState({Model: model}, () => {
				this.subTotalCalculate();
			});
		});
		
	}

	getRelatedProducts(id: any){
		let params = {id: id}
		ProductDataAccess.GetRelatedProductsById(params, (res: BaseResponse) => {
			if(!res.success){
				this.UpdateViewModel();
				return;
			}
			this.state.Model.relatedProducts = res.data.items;
			this.UpdateViewModel();
		});
	}
	
	subTotalCalculate = () => {
		let model = this.state.Model;
		if(model.product){
			this.state.Model.subtotal = model.product.sale_price * Number(model.quantity);
			this.UpdateViewModel();
		}
	}
	
	addToWishlist = (e:any) => {
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		
		let model = this.state.Model;
		if(model.product.wishlist_id){
			WishlistDataAccess.RemoveWishlist(model.product.id, (res: BaseResponse) => {
				if(!res.success){
					return;
				}
				this.state.Model.product.wishlist_id = "";
				this.UpdateViewModel();
				this.UpdateWishlistCount(res.data.wishlist_count);
				this.ShowToast(res.message,"success");
				
			});
			
		}else{
			let params = {
				product_id: model.product.id
			}
			WishlistDataAccess.AddWishlist(params, (res: BaseResponse) => {
				if(!res.success){
					return;
				}
				this.state.Model.product.wishlist_id = res.data.wishlist_count;
				this.UpdateViewModel();
				this.UpdateWishlistCount(res.data.wishlist_count);
				this.ShowToast(res.message,"success");
			});
		}
		
	}
	
	addToCart = () => {
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		let model = this.state.Model;
		if(model.addToCartProcessing) return;
		let params = {
			product_id: model.product.id,
			quantity: model.quantity
		};
		
		this.state.Model.addToCartProcessing = true;
    this.UpdateViewModel();
		
		CustomerCartDataAccess.Create(params, async (res: BaseResponse) => {
			this.state.Model.addToCartProcessing = false;
			if(!res.success){
				this.UpdateViewModel();
				this.ShowToast(res.message,"warning");
				return false;
			}
			
			this.UpdateViewModel();
			this.UpdateCartCount(res.data.cart_count);
			this.ShowToast(res.message,"success");
			
		});
		
	}

	addToWishlistForRelatedProducts = (item: any, key: any) => { 
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		
		//let model = this.state.Model;
		if(item.wishlist_id){
			WishlistDataAccess.RemoveWishlist(item.id, (res: BaseResponse) => {
				if(!res.success){
					return;
				}
				this.state.Model.relatedProducts[key]['wishlist_id'] = "";
				this.UpdateViewModel();
				this.UpdateWishlistCount(res.data.wishlist_count);
				this.ShowToast(res.message,"success");
				
			});
			
		}else{
			let params = {
				product_id: item.id
			}
			WishlistDataAccess.AddWishlist(params, (res: BaseResponse) => {
				if(!res.success){
					return;
				}
				this.state.Model.relatedProducts[key]['wishlist_id'] = res.data.wishlist_count;
				this.UpdateViewModel();
				this.UpdateWishlistCount(res.data.wishlist_count);
				this.ShowToast(res.message,"success");
			});
		}
		
	}
	
	addToCartForRelatedProducts = (id: number) => {
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		let model = this.state.Model;
		if(this.checkIsProductProcessing(id)) return;
		let params = {
			product_id: id,
			quantity: 1
		};
		
		this.state.Model.itemsLoading.push(id);
    this.UpdateViewModel();
		
		CustomerCartDataAccess.Create(params, async (res: BaseResponse) => {
			model.itemsLoading = model.itemsLoading.filter(i => i !== id);
			this.UpdateViewModel();
			if(!res.success){
				this.ShowToast(res.message,"warning");
				return false;
			}
			
			this.UpdateCartCount(res.data.cart_count);
			this.ShowToast(res.message,"success");
		});
	}
	
	checkIsProductProcessing(id: number){
		let model = this.state.Model;
		if(model.itemsLoading.includes(id)){
			return true;
		}else{
			return false;
		}
	}
	
	buyNow = (slug: any) => {
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		let model = this.state.Model;
		let quantity = model.quantity;
		return this.props.history.push("/buynow?quantity=" + quantity + '&slug=' + slug);
	}

	ShowRateUsModal(){
		if(!this.Auth){
			return this.props.history.push("/login");
		}

		let model = this.state.Model;
		model.rateUsModal = true;
		this.UpdateViewModel();
	}
	
	RateUs = async () => { 
		let model = this.state.Model;
		
		if(!this.validateRating()){
			return false;
		}
		
		model.actionProcessing = true;
		this.UpdateViewModel();

		let data = {
			product_id: model.product.id,
			review: model.review,
			rating: model.rating,
			title: model.title
		}


		ProductDataAccess.saveRating(data,  async (res: BaseResponse) => {
			if(!res.success){
				return this.ShowToast(res.message, "warning");
			}
	
			this.handleCloseRateUsModal();
			
			const ratingData = res.data;
			model.product.ratings = ratingData.rating;

			this.UpdateViewModel();
			this.ShowToast(res.message,"success");
		});	
	}

	validateRating(){
		let model = this.state.Model;
		let error = true;

		if(!model.review){
			model.ErrorReview="Review is required";
			error = false;
		  }else{
			model.ErrorReview="";
		  }
		if(!model.title){
			model.ErrorTitle="Title is required";
			error = false;
		  }else{
			model.ErrorTitle="";
		  }	  

		  this.UpdateViewModel();
		  return error;
	}


	handleCloseRateUsModal = () => {
		this.state.Model.actionItem = null;
		this.state.Model.rateUsModal = false;
		this.state.Model.actionProcessing = false;
		this.UpdateViewModel();
	}
	
	SetRateUsStar = (star:number) => {
		this.state.Model.rating = star;
		this.UpdateViewModel();
	}

	updateRecentView(id: any){
		if(this.Auth){
			//let model = this.state.Model;
			let params = {
				product_id: id
			};
				
		   RecentlyViewedDataAccess.AddRecentlyViewd(params, async (res: BaseResponse) => {
			    if(!res.success){
				   this.UpdateViewModel();
				   return;
                 }
				this.UpdateViewModel();
		   });
		}
	}
	
	quantityMinus = () => {
		let model = this.state.Model;
		if(model.quantity <= 1) return;
		
		let newState = update(this.state, {
			Model: {
				quantity: {$set: (model.quantity - 1)},
			}
		});
		this.setState(newState, () => {
			this.subTotalCalculate();
		});
	}
	
	quantityPlus = () => {
		let model = this.state.Model;
		if(model.quantity >= model.product.quantity) return;
		
		let newState = update(this.state, {
			Model: {
				quantity: {$set: (model.quantity + 1)},
			}
		});
		this.setState(newState, () => {
			this.subTotalCalculate();
		});
	}
	
	SetZoomImage = (img:string) => {
		this.state.Model.product.media = img;
		this.UpdateViewModel();
	} 
  
	render(){
		let model = this.state.Model;
		let product = model.product;
		
		return(
			<React.Fragment>
				<HeaderPage />
				
				<div className="product_details_container">
					<section className="product_details_main_container">
						<div className="product_details_row">
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="breadcrumb-container">
									<Breadcrumb>
										<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
										<Breadcrumb.Item active>Our Products</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</Col>
							{
								model.loading ?
								<Col xs={12} sm={12} md={12} lg={12}>
									<div className="container_loader">
										<BaseLoader/>
									</div>
								</Col>
								:
								<React.Fragment>
								{
									!product ?
									<Col xs={12} sm={12} md={12} lg={12}>
										<Alert variant="danger" className="not_found_product_container">
											<span>Sorry! Product not found.</span>
										</Alert>
									</Col>
									:
									<React.Fragment>
										<div className="product_left">
											<div className="product_details_leftpanel_images">
												
												<div className="right_side_details_thumbnail_image">
													<div className="product-details-banner">
													{
														product.media ?
														<div className="magnifying_area">
														<ReactImageMagnify {...{
																smallImage: {
																		alt: 'godskart product',
																		className: "img-fluid",
																		isFluidWidth: true,
																		src: product.media
																},
																enlargedImageContainerStyle: {
																	zIndex: 999
																  },
																  enlargedImageContainerDimensions: {
																	width: '160%',
																	height: '100%'
																},
																largeImage: {
																		src: product.media,
																		width: 2400,
																		height: 1800,
																}
																
														}} />
														</div>
														: null
													}
													<div className="product-wishlist">
														<a href="javascript: void(0)" onClick={(e) => this.addToWishlist(e)} className={product.wishlist_id ? "active" : ""}><FontAwesomeIcon icon="heart" /></a>
													</div>
													</div> 
												</div>
												<div className="left_side_details_thumbnail_image">
													{
														product.medias.map((item:string, key:number) => {
															return <div key={key} onClick={() => this.SetZoomImage(item)}>
															<img src={item} alt="godskart"/>
														</div>
														})
													}
												</div>
											</div>
											
											<div className="product_images_slider">
												<OwlCarousel
													autoplay={false}
													className="owl-theme"
													loop
													margin={18}
													items={1}
													dots={false}
													nav={true}
												>
												{
													product.medias.map((item:string, key:number) => {
														return <div className="img-item" key={key}>
														<img src={item} alt="godskart"/>
													</div>
													})
												}
												</OwlCarousel>
											</div>
										</div>
										
										<div className="product_right">
											<div className="magnify_image_content">
												<div className="product-details-banner-header">
													<h1>{product.name}</h1>
												</div>
												<div className="product-details-banner-price">
													<h1>{ this.priceDisplay('Rs. ', product.sale_price) }</h1>
												</div>
												<div className="product-stock-status">
													{
														product.quantity > 0 ?
														<h1 className="text-success"><b>In Stock</b></h1>
														:
														<h1 className="text-danger"><b>Out of Stock</b></h1>
													}
												</div>
												<div className="product-details-banner-description">
													<p>{product.description}</p>
												</div>
												<div className="product-qty">
													<h1>Quantity</h1>
													<div className="def-number-input number-input">
														<InputGroup className="">
															<InputGroup.Prepend>
																<Button onClick={() => this.quantityMinus()} disabled={model.quantity <= 1}><FontAwesomeIcon icon="minus" /></Button>
															</InputGroup.Prepend>
															<FormControl aria-label="Amount (to the nearest dollar)" value={model.quantity} disabled={true} />
															<InputGroup.Append>
																<Button onClick={() => this.quantityPlus()}><FontAwesomeIcon icon="plus" /></Button>
															</InputGroup.Append>
														</InputGroup>
													</div>
												</div>
												<div className="sub-total">
													<label>Subtotal:</label>
													<span> { this.priceDisplay('Rs. ', model.subtotal) }</span>
												
													<div className="pincode_area">
													</div>
												</div>
												<div className="product_rating">
													{[...Array(model.totalStar)].map((star:number, i:number) => {
														return <span key={i}>
														
														{
															(i+1) <= product.ratings ?
															<FontAwesomeIcon icon="star" className="text-warning" />
															:
															<FontAwesomeIcon icon="star" className="unselect_star" />
														}
														
														</span>
													})}
												</div><br />
												<div> 
												<div className="purchase_btns">
													<Button variant="primary" className="el-button" disabled={product.quantity === 0} onClick={() => this.addToCart()}>
														{
															model.addToCartProcessing ?
															<Spinner animation="border" variant="light" />
															:
															"Add to cart"
														}
													</Button>
													<Button variant="primary" className="el-button" disabled={product.quantity === 0} onClick={() => this.buyNow(product.slug)}>
													Buy now
													</Button>
													<Button variant="primary" className="el-button" onClick={() => this.ShowRateUsModal()}>
													Review
													</Button>
												</div>
											</div>
											</div>
										</div>
									</React.Fragment>
								}
								</React.Fragment>
							}
						</div>
					</section>
					{
						model.relatedProducts.length ?
						<section>
							<div className="our-products">
								<div className="new_products_container">
									<div className="puja_feature_heading">
										<h1>Related Products</h1>
									</div>
									<React.Fragment>
										<div className="new_products_row">
												<OwlCarousel
													autoplay={false}
													className="owl-theme"
													loop
													margin={18}
													items={4}
													dots={false}
													nav={true}
													responsive={{
														0: {
																items: 1,
														},
														600: {
																items: 3,
														},
														1000: {
																items: 4,
														},
													}}
												>
													{
														model.relatedProducts.map((item: any, key:number) => {
														return <div className="contain-product layout-default" key={key}>
															{
																item.media ?
																<div className="product-thumb">
																	<Link to={"/products/" + item.slug} className="image link-to-product">
																		<div className="max-fit-2"><img src={item.media} className="img-fluid" alt="puja" /></div>
																	</Link>
																</div>
																: null
															}
															<div className="info">
																<h4 className="product-title">
																	<Link to={"/products/" + item.slug} className="pr-name">{ item.name }</Link>
																</h4>
																<div className="price">
																	<span className="price-amount"><span className="currencySymbol"></span>{ this.priceDisplay('Rs. ', item.sale_price) }</span>
																	{
																		item.price !== item.sale_price ?
																		<span className="price-amount original_price"><span className="currencySymbol"></span>{ this.priceDisplay('Rs. ', item.price) }</span>
																		: null
																	}
																</div>
																<div className="slide-down-box">
																	<p className="message">All products are carefully selected to ensure food safety.</p>
																	<div className="buttons">
																			<a href="javascript: void(0)" onClick={(e) => this.addToWishlistForRelatedProducts(item, key)} className={item.wishlist_id  ? "" : "btn compare-btn"}>
																				<FontAwesomeIcon icon="heart" />
																			</a>
																			<a href="javascript: void(0);" onClick={() => this.addToCartForRelatedProducts(item.id)} className="btn add-to-cart-btn">
																				<FontAwesomeIcon icon="shopping-cart" /> {this.checkIsProductProcessing(item.id) ? 'Processing...' : 'add to cart'}
																			</a>
																			<a href="javascript: void(0)" onClick={(e) => this.addToWishlistForRelatedProducts(item, key)} className={item.wishlist_id  ? "" : "btn compare-btn"}>
																				<FontAwesomeIcon icon="heart" />
																			</a>
																	</div>
																</div>
															</div>
														</div>
													})
												}
												</OwlCarousel>
											</div>
									</React.Fragment>
								</div>
							</div>
						</section>
						: null
					}
				<Modal
					show={model.rateUsModal}
					onHide={() => this.handleCloseRateUsModal()}
					backdrop="static"
					keyboard={false}
					className="rate_us_modal"
				>
					<Modal.Header>
						<Modal.Title>Rate Us</Modal.Title>
					</Modal.Header>
					<Modal.Body>
							<Form.Label>Enter Your Title</Form.Label>
							<Form.Control type="text"
								name="title"
								value={model.title}
								onChange={(e:any) => this.SetModelValue(e)}										
							/>
							{model.ErrorTitle ? <Form.Text className="text-danger">{model.ErrorTitle}</Form.Text>:null}
							<Form.Label>Review</Form.Label>
								<Form.Control 
									as="textarea" 
									rows={3} 
									name="review"
									value={model.review}
									onChange={(e:any) => this.SetModelValue(e)}										
								/>
							{model.ErrorReview ? <Form.Text className="text-danger">{model.ErrorReview}</Form.Text>:null}
					
							<Form.Label>Rating</Form.Label>
							<div className="rate_us_star">
								{[...Array(5)].map((star:number, i:number) => {
									return <span key={i} onClick={() => this.SetRateUsStar(i+1)}>
										{
											i <= (model.rating-1) ?
											<FontAwesomeIcon icon="star" className="text-warning" />
											:
											<FontAwesomeIcon icon="star" className="unselect_star" />
										}
										</span>
								})}
							</div>
						
					</Modal.Body>
					<Modal.Footer>
						<Button variant="success review_send_button" onClick={() => this.RateUs()} disabled={model.actionProcessing || !model.rating}>
							{
								model.actionProcessing ?
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/>
								:
								"Send"
							}
						</Button>
						{
							!model.actionProcessing ? 
							<Button variant="default review_cancel_button" onClick={() => this.handleCloseRateUsModal()}>Cancel</Button>
							: null
						}
					</Modal.Footer>
				</Modal>
			   </div>
				
		      <FooterPage />
				
			</React.Fragment>
    );
  }
}

export default withRouter(ProductViewPage)
