import BaseViewModel from "../../Core/BaseViewModel";

export default class CustomerEditViewModel extends  BaseViewModel{
    id:number=0;
    profile_processing:boolean=false;
    first_name:string="";
    last_name:string="";
    email:string="";
    mobile:string="";
    access_token:String="";
    step:Number=1;
		otpModal: boolean = false;
		otp: string="";

    //form validation
    ErrorFirstName:String="";
    ErrorLastName:String="";
    ErrorEmail:String="";
    ErrorMobile:String="";
    ErrorOtp:String="";
}