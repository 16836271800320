export default class UIHelper {

    public static ModelConvertToUrl(model:any) :String{
        if(!model){
            return model;
        }
        let url="?";
        for( var key  in model){
            url+=key+"="+model[key]+"&";
        }
        return url.slice(0, -1);
    }

    public static OnChangeReactSelect(value:any,e:any){         
        return UIHelper.OnChange(e.name,value.value);         
    }

    public static OnChangeDropZone(e:any,s:string,value:any[],name:string){  
                
        var actualFiles= value.map(i=>i.file);
        if(s==="removed"){
            actualFiles=value.filter(i=>{ return i.file.name!==e.file.name}).map(i=>i.file);
        }
        return UIHelper.OnChange(name,actualFiles);         
    }
    
    public static OnChange(name:string,value:any){                  
        var newE= {target:{name:name,value:value,id:name}};         
        return newE;        
    }


    public static  async CreateImageFromFileList(imageUrls:string[]):Promise<File[]> { 
        var files:File[]=[];
        return new Promise((resolve, reject) => {
            var count=0;
            imageUrls.forEach(async(i:string)=>{ 
                // @ts-ignore
                var tempitem= await UIHelper.CreateImageFromFile(i)
                .then(res=> {return res}); 
                if(tempitem){
                    files.push(tempitem); 
                }   
                count++; 
                if(count===imageUrls.length){
                    resolve(files)
                }  
            })
            
        }) 
    }
    
    public static CreateImageFromFile(imageUrl:string) {
        if(!imageUrl){
            return null;
        }
        var fileName= imageUrl.split('/').pop()||"";
        var ext = fileName.substr(fileName.lastIndexOf('.') + 1);
        return fetch(imageUrl).then(res => {
           return res.arrayBuffer().then(buf => {
              return new File([buf], fileName, { type: 'image/'+ext })
              
            })
          }).catch(function(e) {               
            return null;
        });

    }
}