export default class BaseViewModel{
    PageMode?:PageMode;
    FormSubmitStatus:boolean=false;
    ModalStatus:boolean=false;
    IsTableLoading:boolean=false;
    IsPageLoading:boolean=false;
    TableLoadingLabel:string="";
    PageLoadingLabel:string="";    
    
}


export enum PageMode{
    Create,
    Update,
    Delete
}

