import React  from 'react';
//import {Col, Container, Row, Tab, Tabs, Button } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseState from '../../Core/BaseState';
import BaseResponse from '../../Core/BaseResponse';
import CommunitiesViewModel from '../../ViewModels/Home/CommunitiesViewModel';
import HomeDataAccess from '../../DataAccess/HomeDataAccess';
//import { BaseLoader } from '../Core/BaseView';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

type Props = {
	section?: any
} 

export default class Communities extends BaseComponent<any,CommunitiesViewModel> {
  constructor(props:any) {
    super(props);
    this.state = new BaseState<CommunitiesViewModel>(new CommunitiesViewModel());
		this.state.Model.section = 'section' in this.props ? this.props.section : null;
		this.state.Model.loading = true;
  }
	
	componentDidMount(){
		this.getCommunities();
	}
	
	getCommunities(){
		HomeDataAccess.GetCommunities((res: BaseResponse) => {
      this.state.Model.loading = false;
      if(!res.success){
				this.UpdateViewModel();
				return;
      }
			this.state.Model.communities = res.data.items
			this.UpdateViewModel();
			
		});
		
	}
  
	render(){
		let model = this.state.Model;
		
		/* if(model.loading){
			return (
				<BaseLoader/>
			)
		} */
		
		if(!model.communities.length){
			return false;
		}
		
		return(
			<div className="home">
				<section className="new_products community_container">
					<div className="new_products_container">
						<div className="puja_feature_heading">
							<h1> {model.section.section_title} </h1>
						</div>
						<div className="new_products_communities_row">
							<div className="new_product_outer">
								<OwlCarousel
										autoplay={false}
										className="owl-theme"
										loop
										margin={18}
										items={6}
										dots={false}
										nav={true}
										responsive={{
											0: {
													items: 1,
											},
											600: {
													items: 3,
											},
											1000: {
													items: 6,
											},
										}}
									>
									{
										model.communities.map((item: any, key:number) => {
										return <div className="communities-slider-container" key={key}>
											{
												item.media ?
											  <Link to={"/communities/" + item.slug} className="image link-to-product">
												<div className="communities-slider-image">
													<img src={item.media} alt="puja" />
												</div>
											  </Link>
												: null
											}
											<div className="community_image_header"><h1>{item.name}</h1></div>
										</div>
										})
									}
								</OwlCarousel>
							</div>
						</div>
					 </div>
				</section>
			</div>
    );
  }
}