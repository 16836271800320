import React  from 'react';
import {Col, Container, Row, Button, Breadcrumb, Form, Spinner } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseState from '../../Core/BaseState';
import BaseResponse from '../../Core/BaseResponse';
import CustomerServiceViewModel from '../../ViewModels/Customer/CustomerServiceViewModel';
import CustomerDataAccess from '../../DataAccess/CustomerDataAccess';
import update from 'immutability-helper';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';

export default class Deals extends BaseComponent<any,CustomerServiceViewModel> {
  constructor(props:any) {
     super(props);
     this.state = new BaseState<CustomerServiceViewModel>(new CustomerServiceViewModel());
	 this.state.Model.loading = false;
	}
	
	onSubmit = async (event:any) => {
		event.preventDefault();

		 if(!this.ValidateFrom()){
		   return false;
		 }
			
		this.state.Model.service_processing = true;
		this.UpdateViewModel();

		CustomerDataAccess.ContactUs(this.state.Model,async (res: BaseResponse) => {
			this.state.Model.service_processing = false;
			if(!res.success){
				this.UpdateViewModel();
			    return this.ShowToast(res.message,"warning");
			}
			this.UpdateViewModel();
			this.ResetForm();
			return this.ShowToast(res.message,"success");
		});
	
	  }

	  hasError = (key: string) => {
		let formErrors = this.state.Model.formErrors;
		return (key in formErrors && formErrors[key]);
	  }
	
	  showError = (key: string) => {
		let formErrors = this.state.Model.formErrors;
		return this.hasError(key) ? <Form.Text className="text-danger">{formErrors[key]}</Form.Text> : null;
	  }
		
	  ValidateFrom = () => { 
			let model = this.state.Model; 
			
			let formErrors:any = {};
			if(!model.first_name){
				formErrors.first_name = 'First name is required.'
			}
			if(!model.last_name){
				formErrors.last_name = 'Last name is required.'
			}
			if(!model.phone){
				formErrors.phone = 'Phone is required.'
			}
			if(!model.email){
				formErrors.email = 'Email is required.'
			}
			if(!model.message){
				formErrors.message = 'Message is required.'
			}
		
		this.state.Model.formErrors = formErrors;
		this.UpdateViewModel();
		return Object.keys(formErrors).length === 0;	
	}

	ResetForm = () => {
		let newState = update(this.state, {
			Model: {
				first_name: {$set: ''},
				last_name: {$set: ''},
				phone: {$set: ''},
				message: {$set: ''},
				email: {$set: ''},
			}
		});
		
		this.setState(newState);
	}

	
	render(){
		let model = this.state.Model;
		
		return(
			<React.Fragment>
			<HeaderPage />
			<div className="customer-service-container">
				<div className="customer-service-banner">
					<div className="banner-contact-overlay"></div>	
					<h1>Contact Us</h1>
				</div>
				<Container>
					<Row>
						<Col xs={12} sm={12} md={12} lg={12}>
							<div className="breadcrumb-container">
								<Breadcrumb>
									<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
									<Breadcrumb.Item active>Customer Service</Breadcrumb.Item>
								</Breadcrumb>
							</div>
							<div className="contact-form-area">
								<h3>GOT A QUESTION?</h3>
								<h2>Contact Godskart</h2>
								<p>We’re here to help and answer any question you might have. We look forward to hearing from you </p>
							</div>
							<div className="contact-form-background">
								<Container>
								  <Form onSubmit={this.onSubmit}>
									<Row>
									<Col xs={12} sm={6} md={6} lg={6}>
									<Form.Group controlId="first_name">
										<Form.Label>First Name</Form.Label>
										<Form.Control type="text"
														placeholder="First name"
														name="first_name"
														value={model.first_name}
														isInvalid={this.hasError('first_name')}
														onChange={this.SetModelValue}
													/>
										 {this.showError('first_name')}
										</Form.Group>
									</Col>
									<Col xs={12} sm={6} md={6} lg={6}>
									 <Form.Group controlId="last_name">
										<Form.Label>Last Name</Form.Label>
										<Form.Control type="text"
														placeholder="Last name"
														name="last_name"
														value={model.last_name}
														isInvalid={this.hasError('last_name')}
														onChange={this.SetModelValue}
										 />
										{this.showError('last_name')}
									  </Form.Group>
									</Col>
									<Col xs={12} sm={6} md={6} lg={6}>
									 <Form.Group controlId="phone">	
										<Form.Label>Enter Your phone number</Form.Label>
										<Form.Control type="text"
														placeholder="Enter your phone number"
														name="phone"
														value={model.phone}
														isInvalid={this.hasError('phone')}
														onChange={this.SetModelValue}
										 />
										 {this.showError('phone')}
									   </Form.Group>
									</Col>
									<Col xs={12} sm={6} md={6} lg={6}>
									  <Form.Group controlId="email">	
										<Form.Label>Enter your Email</Form.Label>
										<Form.Control type="text"
														placeholder="Enter your email"
														name="email"
														value={model.email}
														isInvalid={this.hasError('email')}
														onChange={this.SetModelValue}
										 />
										 {this.showError('email')}
								 	  </Form.Group>
									</Col>
									<Col xs={12} sm={12} md={12} lg={12}>
										<Form.Group controlId="message">
											<Form.Label>Your Message</Form.Label>
											<Form.Control as="textarea" rows={6} placeholder="Your Message"
														name="message"
														value={model.message}
														isInvalid={this.hasError('message')}
														onChange={this.SetModelValue}
										 />
										 {this.showError('message')}
										</Form.Group>
									</Col>
									<Col xs={12} sm={12} md={12} lg={12}>
										<Button variant="outline-primary" type="submit" className="contact_submit_btn">
										{
													model.service_processing ? 
													<div>
														<Spinner
															as="span"
															animation="border"
															size="sm"
															role="status"
															aria-hidden="true"
														/>&nbsp;Processing....
													</div>
													:
													"Submit"
												}
										</Button>
									</Col>
									</Row>
									</Form>
								</Container>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<FooterPage />
			</React.Fragment>
	);
  }
}

