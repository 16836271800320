import React from "react";
import { Button, Spinner } from "react-bootstrap";

export function BaseButton(model:any) {
    return <div>
        {!model.FormSubmitStatus ? <Button variant="primary" type="submit">Login</Button>:<Spinner animation="border" variant="primary" />}
    </div>;
}

export function BaseLoader() {
    return <div className="container_loader">
					<div className="each_item_loader" style={{backgroundColor: 'unset', opacity: 1}}>
						<div className="spinner_conent"><Spinner animation="border" variant="primary"/></div>
					</div>
				</div>
}
