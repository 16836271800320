import React  from 'react';
import {Col, Row, Tab, Breadcrumb, Button, Spinner, Alert, Card, Accordion, Form, Nav, Badge } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import Pagination from "../../Core/Pagination";
import ProductDataAccess from '../../DataAccess/ProductDataAccess';
import CustomerCartDataAccess from '../../DataAccess/CustomerCartDataAccess';
import WishlistDataAccess from '../../DataAccess/WishlistDataAccess';
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import ProductSearchViewModel from '../../ViewModels/Product/ProductSearchViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import { BaseLoader } from '../Core/BaseView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ReactImageMagnify from 'react-image-magnify';
// import update from 'immutability-helper';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';

class ProductSearchPage extends BaseComponent<any,ProductSearchViewModel> {
  constructor(props:any) {
    super(props);
    this.state = new BaseState<ProductSearchViewModel>(new ProductSearchViewModel());
    this.state.Model.loading = true;
		
		let params = queryString.parse(this.props.location.search);
		this.state.Model.search = params.search ? params.search : "";
		let pageurl = '/search-results?search=' + this.state.Model.search;
		let type = params.type ? params.type : "";
		if(type !== 'product' && type !== 'category'){
			type = 'product';
		}
		if(type === 'category'){
			pageurl += '&type=category';
		}
		this.state.Model.type = type;
		this.state.Model.currentPageUrl = pageurl;
  }
	
	componentDidMount(){
		this.getProducts();
	}
	
	componentDidUpdate(prevProps:any) {
		if (this.props.location !== prevProps.location) {
			let params = queryString.parse(this.props.location.search);
			let search = params.search ? params.search : "";
			let type = params.type ? params.type : "";
			if(type !== 'product' && type !== 'category'){
				type = 'product';
			}
			let model = this.state.Model;
			if(model.search !== search || model.type !== type){
				model.search = search;
				model.type = type;
				model.currentPageUrl = '/search-results?search=' + search + '&type=' + type;
				model.page = 1;
				model.loading = true;
				model.isFirstLoad = true;
				this.setState({
					Model: model
				}, () => {
					this.clearAllFilter();
				})
			}
		}
	}
	
	getProducts(){
		let model = this.state.Model;
		this.UpdateViewModel();
		let params = {...{
			type: model.type,
			search: model.search,
			page: model.page
		}, ...model.filters};
		
		params = this.filterObject(params);
		
		window.scrollTo(0, 0);
		
		let _id = '';
		ProductDataAccess.GetProducts(params, (res: BaseResponse) => {
      if(!res.success){
				this.state.Model.loading = false;
				this.state.Model.productSearching = false;
				this.UpdateViewModel();
				return;
      }
			this.state.Model.loading = false;
			this.state.Model.productSearching = false;
			this.state.Model.products = res.data.items;
			this.state.Model.totalPage = res.data.total_pages;
			this.state.Model.categories = res.data.category;
			this.state.Model.discount_range = res.data.discount_range;
			this.state.Model.offers = res.data.offers;
			this.state.Model.price_range = res.data.price_range;
			
			if(model.isFirstLoad && model.type === 'category'){
				res.data.category.forEach((item:any) => {
					if(item.name.toLowerCase() === model.search.toLowerCase()){
						this.state.Model.filters.category_id = item.id;
						this.state.Model.search = '';
						this.state.Model.hasFilter = true;
						_id = "mb-category-" + item.id;
					}
				})
			}
			
			this.state.Model.isFirstLoad = false;
			this.state.Model.clearSearch = false;
			
			this.UpdateViewModel();
			
			if(this.isMobile() && _id !== ""){
				(document as any).getElementById(_id).checked = true;
				console.log('_id', _id);
			}
			
		});
		
	}
	
	filterObject = (obj:any) => {
		for (var propName in obj) {
			if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
				delete obj[propName];
			}
		}
		return obj
	}

	addToWishlist = (item: any, key: any) => { 
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		
		//let model = this.state.Model;
		if(item.wishlist_id){
			WishlistDataAccess.RemoveWishlist(item.id, (res: BaseResponse) => {
				if(!res.success){
					return;
				}
				this.state.Model.products[key]['wishlist_id'] = "";
				this.UpdateViewModel();
				this.UpdateWishlistCount(res.data.wishlist_count);
				this.ShowToast(res.message,"success");
				
			});
			
		}else{
			let params = {
				product_id: item.id
			}
			WishlistDataAccess.AddWishlist(params, (res: BaseResponse) => {
				if(!res.success){
					return;
				}
				this.state.Model.products[key]['wishlist_id'] = res.data.wishlist_count;
				this.UpdateViewModel();
				this.UpdateWishlistCount(res.data.wishlist_count);
				this.ShowToast(res.message,"success");
			});
		}
		
	}
	
	addToCart = (id: number) => {
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		let model = this.state.Model;
		if(this.checkIsProductProcessing(id)) return;
		let params = {
			product_id: id,
			quantity: 1
		};
		
		this.state.Model.itemsLoading.push(id);
    this.UpdateViewModel();
		
		CustomerCartDataAccess.Create(params, async (res: BaseResponse) => {
			model.itemsLoading = model.itemsLoading.filter(i => i !== id);
			this.UpdateViewModel();
			if(!res.success){
				this.ShowToast(res.message,"warning");
				return false;
			}
			
			this.UpdateCartCount(res.data.cart_count);
			this.ShowToast(res.message,"success");
		});
	}
	
	checkIsProductProcessing(id: number){
		let model = this.state.Model;
		if(model.itemsLoading.includes(id)){
			return true;
		}else{
			return false;
		}
	}
	
	paginationClick = (page:number) => {
		let model = this.state.Model;
		model.page = page;
		model.productSearching = true;
		this.setState({
			Model: model
		}, () => {
			this.getProducts();
		})
	}
	
	filterCollapsClick = (name:string) => {
		let model = this.state.Model;
		switch(name){
			case 'collaps_category':
				model.collaps_category = !model.collaps_category;
				break;
			case 'collaps_price':
				model.collaps_price = !model.collaps_price;
				break;
			case 'collaps_ratings':
				model.collaps_ratings = !model.collaps_ratings;
				break;
			case 'collaps_discount':
				model.collaps_discount = !model.collaps_discount;
				break;
			case 'collaps_availability':
				model.collaps_availability = !model.collaps_availability;
				break;
		}
		this.UpdateViewModel();
	}
	
	onFilterChange = (event:any, type:string, item:any) => {
		let model = this.state.Model;
		let isChecked = event.target.checked;
		let filters = model.filters;
		model.hasFilter = false;
		let _id;
		switch(type){
			case 'price':
				filters.min_price = '';
				filters.max_price = '';
				model.price_range.forEach((i:any, key:number) => {
					_id = "price-" + key;
					if((document as any).getElementById(_id).checked){
						let _f_min = parseFloat(filters.min_price) || 0;
						let _i_min = parseFloat(i.min) || 0;
						let _f_max = parseFloat(filters.max_price) || 0;
						let _i_max = parseFloat(i.max) || 0;
						if(_f_min > _i_min || _f_min < 1){
							filters.min_price = i.min;
						}else{
							//filters.min_price = filters.min_price;
						}
						if(_i_max === 0){
							filters.max_price = '';
						}else{
							filters.max_price = (_f_max < _i_max) ? i.max : filters.min_price;
						}
						
						model.hasFilter = true;
					}
				})
				
				break;
			case 'category':
				if(isChecked){
					filters.category_id = item.id;
					model.hasFilter = true;
					//model.search = '';
					model.type = 'product';
					
					model.categories.forEach((i:any) => {
						if(i.id !== item.id){
							let _id = "category-" + i.id;
							(document as any).getElementById(_id).checked = false;
						}
					})
				}else{
					filters.category_id = '';
				}
				break;
			case 'ratings':
				filters.min_rating = '';
				[4, 3, 2, 1].forEach((i:any, key:number) => {
					_id = "rating-" + key;
					if((document as any).getElementById(_id).checked){
						let _f_min = parseInt(filters.min_rating) || 0;
						if(_f_min > i || _f_min === 0){
							filters.min_rating = i;
							model.hasFilter = true;
						}
					}
				})
				
				break;
			case 'discount':
				filters.min_discount = '';
				let discount_range = model.discount_range.filter(i => i !== 0);
				discount_range.forEach((i:any, key:number) => {
					_id = "discount-" + key;
					if((document as any).getElementById(_id).checked){
						let _f_min = parseInt(filters.min_discount) || 0;
						if(_f_min > i || _f_min === 0){
							filters.min_discount = i;
							model.hasFilter = true;
						}
					}
				})
				
				break;
			case 'availability':
				if(isChecked){
					filters.availability = item;
					model.hasFilter = true;
				}else{
					filters.availability = '';
				}
				
				break;
		}

		model.filters = filters;
		model.page = 1;
		model.productSearching = true;
		this.setState({
			Model: model 
		}, () => {
			this.getProducts();
		})
		
	}
	
	onFilterChangeForMobile = (event:any, type:string, item:any) => {
		let model = this.state.Model;
		let isChecked = event.target.checked;
		switch(type){
			case 'category':
				if(isChecked){
					model.categories.forEach((i:any) => {
						if(i.id !== item.id){
							let _id = "mb-category-" + i.id;
							(document as any).getElementById(_id).checked = false;
						}
					})
				}
				break;
		}
	}
	
	handleSortBy = (event: any) => {
    const { target: { value } } = event;
		let model = this.state.Model;
		model.filters.sort_by = value;
		model.page = 1;
		model.productSearching = true;
		model.mobileSortFilterTab = '';
		this.setState({
			Model: model 
		}, () => {
			this.getProducts();
		});
		
	}
	
	handleFilterSortTab = (event:any) => {
		let model = this.state.Model;
		if(model.mobileSortFilterTab === event){
			model.mobileSortFilterTab = '';
		}else{
			model.mobileSortFilterTab = event;
		}
		this.setState({
			Model: model 
		});
	}
	
	handleOverlayClick = () => {
		let model = this.state.Model;
		model.mobileSortFilterTab = '';
		this.setState({
			Model: model 
		});
	}
	
	handleFilterApply = () => {
		let model = this.state.Model;
		let filters = model.filters;
		let _id;
		model.hasFilter = false;
		
		//price
		filters.min_price = '';
		filters.max_price = '';
		model.price_range.forEach((i:any, key:number) => {
			_id = "mb-price-" + key;
			if((document as any).getElementById(_id).checked){
				let _f_min = parseFloat(filters.min_price) || 0;
				let _i_min = parseFloat(i.min) || 0;
				let _f_max = parseFloat(filters.max_price) || 0;
				let _i_max = parseFloat(i.max) || 0;
				if(_f_min > _i_min || _f_min < 1){
					filters.min_price = i.min;
				}else{
					//filters.min_price = filters.min_price;
				}
				filters.max_price = (_f_max < _i_max) ? i.max : filters.min_price;
				model.hasFilter = true;
			}
		})
		
		//category
		filters.category_id = '';
		let category = document.querySelectorAll('[id^="mb-category"]');
		for(var i = 0; i < category.length; i++ ){
			if((category[i] as HTMLInputElement).checked){
				filters.category_id = (category[i] as HTMLInputElement).value;
				model.hasFilter = true;
			}
		}
		if(filters.category_id !== ''){
			//model.search = '';
			//model.type = '';
			model.type = 'product';
		}
		
		//ratings
		filters.min_rating = '';
		[4, 3, 2, 1].forEach((i:any, key:number) => {
			_id = "mb-rating-" + key;
			if((document as any).getElementById(_id).checked){
				let _f_min = parseInt(filters.min_rating) || 0;
				if(_f_min > i || _f_min === 0){
					filters.min_rating = i;
					model.hasFilter = true;
				}
			}
		})
		
		//discount
		filters.min_discount = '';
		let discount_range = model.discount_range.filter(i => i !== 0);
		discount_range.forEach((i:any, key:number) => {
			_id = "mb-discount-" + key;
			if((document as any).getElementById(_id).checked){
				let _f_min = parseInt(filters.min_discount) || 0;
				if(_f_min > i || _f_min === 0){
					filters.min_discount = i;
					model.hasFilter = true;
				}
			}
		})
		
		//availability
		filters.availability = (document as any).getElementById('mb-availability-0').checked ? 'include' : '';
		if(filters.availability !== ''){
			model.hasFilter = true;
		}
		
		model.mobileSortFilterTab = '';
		model.filters = filters;
		model.page = 1;
		model.productSearching = true;
		this.setState({
			Model: model 
		}, () => {
			this.getProducts();
		})
	}
	
	getTotalFilterAdded = () => {
		let model = this.state.Model;
		let filters = model.filters;
		let count = 0;
		if(filters.min_price !== '' || filters.max_price !== ''){
			count++;
		}
		if(filters.category_id !== ''){
			count++;
		}
		if(filters.min_rating !== ''){
			count++;
		}
		if(filters.min_discount !== ''){
			count++;
		}
		if(filters.availability !== ''){
			count++;
		}
		return count;
	}
	
	handleFilterClear = () => {
		let model = this.state.Model;
		let params = queryString.parse(this.props.location.search);
		model.search = params.search ? params.search : "";
		this.setState({
			Model: model
		}, () => {
			this.clearAllFilter(true);
		})
	}
	
	handleFilterBack = () => {
		let model = this.state.Model;
		let filters = model.filters;
		if(filters.min_price === '' && filters.max_price === ''){
			let prices = document.querySelectorAll('[id^="mb-price"]');
			for(let i = 0; i < prices.length; i++ ){
				(prices[i] as HTMLInputElement).checked = false;
			}
		}
		
		if(filters.category_id === ''){
			let category = document.querySelectorAll('[id^="mb-category"]');
			for(let i = 0; i < category.length; i++ ){
				(category[i] as HTMLInputElement).checked = false;
			}
		}
		
		if(filters.min_rating === ''){
			let rating = document.querySelectorAll('[id^="mb-rating"]');
			for(let i = 0; i < rating.length; i++ ){
				(rating[i] as HTMLInputElement).checked = false;
			}
		}
		
		if(filters.min_discount === ''){
			let discount = document.querySelectorAll('[id^="mb-discount"]');
			for(let i = 0; i < discount.length; i++ ){
				(discount[i] as HTMLInputElement).checked = false;
			}
		}
		
		if(filters.availability === ''){
			let availability = document.querySelectorAll('[id^="mb-availability"]');
			for(let i = 0; i < availability.length; i++ ){
				(availability[i] as HTMLInputElement).checked = false;
			}
		}
		
		model.mobileSortFilterTab = '';
		this.setState({
			Model: model 
		})
	}
	
	refreshFilter = (e:any) => {
		e.preventDefault();
		
		let model = this.state.Model;
		if(!model.hasFilter){
			model.search = '';
			model.clearSearch = true;
		}else{
			let params = queryString.parse(this.props.location.search);
			model.search = params.search ? params.search : "";
		}
		
		this.setState({
			Model: model
		}, () => {
			this.clearAllFilter(this.isMobile());
		})
		
		
	}
	
	clearAllFilter = (isMobile?:boolean) => {
		let _id = '';
		if(isMobile === true){
			_id = 'mb-';
		}
		let prices = document.querySelectorAll('[id^="'+_id+'price"]');
		for(let i = 0; i < prices.length; i++ ){
			(prices[i] as HTMLInputElement).checked = false;
		}
		let category = document.querySelectorAll('[id^="'+_id+'category"]');
		for(let i = 0; i < category.length; i++ ){
			(category[i] as HTMLInputElement).checked = false;
		}
		let rating = document.querySelectorAll('[id^="'+_id+'rating"]');
		for(let i = 0; i < rating.length; i++ ){
			(rating[i] as HTMLInputElement).checked = false;
		}
		let discount = document.querySelectorAll('[id^="'+_id+'discount"]');
		for(let i = 0; i < discount.length; i++ ){
			(discount[i] as HTMLInputElement).checked = false;
		}
		let availability = document.querySelectorAll('[id^="'+_id+'availability"]');
		for(let i = 0; i < availability.length; i++ ){
			(availability[i] as HTMLInputElement).checked = false;
		}
		
		let params = queryString.parse(this.props.location.search);
		let type = params.type ? params.type : "";
		if(type !== 'product' && type !== 'category'){
			type = 'product';
		}
		
		let model = this.state.Model;
		model.mobileSortFilterTab = '';
		model.page = 1;
		model.type = type;
		model.productSearching = true;
		model.isFirstLoad = true;
		model.hasFilter = false;
		model.filters = {min_price: "", max_price: "", category_id: "", min_rating: "", min_discount: "", availability: "", sort_by: "popularity"};
		this.setState({
			Model: model 
		}, () => {
			this.getProducts();
		})
	}
  
	render(){
		let model = this.state.Model;
		let ratings = [4, 3, 2, 1];
		let discount_range = model.discount_range.filter(i => i !== 0);
		let filters = model.filters;
		let totalFilter = this.getTotalFilterAdded();
		
		return(
			<React.Fragment>
				<HeaderPage search={model.search} searchType={model.type} clearSearch={model.clearSearch} />
					{
						model.mobileSortFilterTab === 'sort' ?
						<div className="overlay" onClick={() => this.handleOverlayClick()}></div>
						: null
					}
				
				<div className="new_products_container">
					<section className="filter-search-container">
						<div className="new_products_row">
								<div className="breadcrumb-container">
									<Breadcrumb>
										<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
										<Breadcrumb.Item active>Search Products</Breadcrumb.Item>
									</Breadcrumb>
								</div>
								
								<div className="mobile_filter">
									<Tab.Container id="left-tabs-example" activeKey={model.mobileSortFilterTab} onSelect={this.handleFilterSortTab}>
										<Row>
										
											<div className="category_tab_area">
												<Nav variant="pills">
													<Nav.Item className="sorting">
														<Nav.Link eventKey="sort"><FontAwesomeIcon icon="sort-amount-up" /> <span className="filter_txt">Sort</span></Nav.Link>
													</Nav.Item>
													<Nav.Item className="filtering">
														<Nav.Link eventKey="filter"><span className="filter_icon"><FontAwesomeIcon icon="filter" />{totalFilter > 0 ? <Badge variant="danger" className="total_filter">{totalFilter}</Badge> : null}</span> <span className="filter_txt">Filter</span></Nav.Link>
													</Nav.Item>
												</Nav>
											</div>
											<Col sm={12}>
											
												<Tab.Content className="custom_category_tab">
													<Tab.Pane className="custom-sort" eventKey="sort">
													<span className="sort_inner_header"><h1>sort by</h1></span>
														<div className="sort_formcheck_area">
															<Form.Check 
																type="radio"
																id="sort-popularity"
																label="Popularity"
																checked={filters.sort_by === "popularity"}
																value="popularity"
																name="sort_by"
																onChange={this.handleSortBy}
															/>
															<Form.Check 
																type="radio"
																id="sort-price-asc"
																label="Price - Low to High"
																checked={filters.sort_by === "price_asc"}
																value="price_asc"
																name="sort_by"
																onChange={this.handleSortBy}
															/>
															<Form.Check 
																type="radio"
																id="sort-price-desc"
																label="Price - High to Low"
																checked={filters.sort_by === "price_desc"}
																value="price_desc"
																name="sort_by"
																onChange={this.handleSortBy}
															/>
															<Form.Check 
																type="radio"
																id="sort-price-desc"
																label="Newest First"
																checked={filters.sort_by === "newest_first"}
																value="newest_first"
																name="sort_by"
																onChange={this.handleSortBy}
															/>
														</div>	
													</Tab.Pane>
													<Tab.Pane className="custom-filter" eventKey="filter">
														<Tab.Container id="left-tabs-example" defaultActiveKey="price">
															<Row>
															<Col xs={12} sm={12} md={12} lg={12} className="padd-0">
																<span className="filter_back_button" onClick={() => this.handleFilterBack()}><FontAwesomeIcon icon="arrow-left" /> &nbsp; Filters</span>
															</Col>
															<Col xs={4} sm={4} md={4} lg={4} className="padd-0">
																	<Nav variant="pills" className="flex-column">
																		{
																			model.price_range.length ?
																			<Nav.Item>
																				<Nav.Link eventKey="price">Price</Nav.Link>
																			</Nav.Item>
																			: null
																		}
																		{
																			model.categories.length ?
																			<Nav.Item>
																				<Nav.Link eventKey="category">Category</Nav.Link>
																			</Nav.Item>
																			: null
																		}
																		<Nav.Item>
																			<Nav.Link eventKey="ratings">Customer Ratings</Nav.Link>
																		</Nav.Item>
																		{
																			model.discount_range.length ?
																			<Nav.Item>
																				<Nav.Link eventKey="discount">Discount</Nav.Link>
																			</Nav.Item>
																			: null
																		}
																		<Nav.Item>
																			<Nav.Link eventKey="availability">Availability</Nav.Link>
																		</Nav.Item>
																	</Nav>
																</Col>
																<Col xs={8} sm={8} md={8} lg={8} className="padd-0">
																	<Tab.Content className="filter_inner_tab">
																		{
																			model.price_range.length ?
																			<Tab.Pane eventKey="price">
																				{
																					model.price_range.map((item: any, key: number) => {
																					return <Form.Group controlId={"mb-price-" + key} key={key}>
																							<Form.Check 
																								type="checkbox" 
																								label={item.price} 
																								onChange={(e: any) => this.onFilterChangeForMobile(e, 'price', item)} 
																							/>
																						</Form.Group>
																					})
																				}
																			</Tab.Pane>
																			: null
																		}
																		{
																			model.categories.length ?
																			<Tab.Pane eventKey="category">
																				{
																					model.categories.map((item: any, key: number) => {
																					return <Form.Group controlId={"mb-category-" + item.id} key={key}>
																							<Form.Check 
																								type="checkbox" 
																								label={item.name}
																								value={item.id}
																								onChange={(e: any) => this.onFilterChangeForMobile(e, 'category', item)} 
																							/>
																						</Form.Group>
																					})
																				}
																			</Tab.Pane>
																			: null
																		}
																		<Tab.Pane eventKey="ratings">
																			{
																				ratings.map((x: number, i: number) => {
																				return <Form.Group controlId={"mb-rating-" + i} key={i}>
																						<Form.Check 
																							type="checkbox" 
																							label={ratings[i] + "★ & above"} 
																							onChange={(e: any) => this.onFilterChangeForMobile(e, 'ratings', ratings[i])}
																						/>
																					</Form.Group>
																				})
																			}
																		</Tab.Pane>
																		{
																			model.discount_range.length ?
																			<Tab.Pane eventKey="discount">
																			{
																				discount_range.map((x: any, i: number) => {
																				return <Form.Group controlId={"mb-discount-" + i} key={i}>
																						<Form.Check 
																							type="checkbox" 
																							label={discount_range[i] + '% & above'} 
																							onChange={(e: any) => this.onFilterChangeForMobile(e, 'discount', discount_range[i])}
																						/>
																					</Form.Group>
																				})
																			}
																			</Tab.Pane>
																			: null
																		}
																		<Tab.Pane eventKey="availability">
																			<Form.Group controlId={"mb-availability-0"}>
																				<Form.Check 
																					type="checkbox" 
																					label="include out of stock" 
																					onChange={(e: any) => this.onFilterChangeForMobile(e, 'availability', 'include')}
																				/>
																			</Form.Group>
																		</Tab.Pane>
																	</Tab.Content>
																</Col>
																<Col sm={12} className="tab_apply_button">
																	{
																		totalFilter > 0 ?
																		<Button variant="default" onClick={() => this.handleFilterClear()}>Clear Filter</Button>
																		: null
																	}
																	<Button variant="primary" onClick={() => this.handleFilterApply()}>Apply</Button>
																</Col>
															</Row>
															
															<Row>
																
															</Row>
															
														</Tab.Container>
													</Tab.Pane>
												</Tab.Content>
											</Col>
										</Row>
									</Tab.Container>
								</div>
								
						
						</div>
						<div className="new_products_row">
							{
								model.loading ?
								<Col xs={12} sm={12} md={12} lg={12}>
									<div className="container_loader">
										<BaseLoader/>
									</div>
								</Col>
								:
								<React.Fragment>
									<Col xs={12} sm={12} md={12} lg={12}>
										<Row className="position_relative">
											<Col xs={12} sm={5} md={3} lg={3} className="desktop_filter">
												{
													model.price_range.length ?
													<React.Fragment>
														<Accordion defaultActiveKey="0">
															<Card>
																<Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.filterCollapsClick('collaps_price')}>
																	Price <span className="float-right">{model.collaps_price ? <FontAwesomeIcon icon="angle-down" /> : <FontAwesomeIcon icon="angle-up" />}</span>
																</Accordion.Toggle>
																<Accordion.Collapse eventKey="0">
																	<Card.Body>
																		{
																			model.price_range.map((item: any, key: number) => {
																			return <Form.Group controlId={"price-" + key} key={key}>
																					<Form.Check 
																						type="checkbox" 
																						label={item.price} 
																						onChange={(e: any) => this.onFilterChange(e, 'price', item)} 
																					/>
																				</Form.Group>
																			})
																		}
																	</Card.Body>
																</Accordion.Collapse>
															</Card>
														</Accordion>
														<br />
													</React.Fragment>
													: null
												}
												
												{
													model.categories.length ?
													<React.Fragment>
														<Accordion defaultActiveKey="0">
															<Card>
																<Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.filterCollapsClick('collaps_category')}>
																	Category <span className="float-right">{model.collaps_category ? <FontAwesomeIcon icon="angle-down" /> : <FontAwesomeIcon icon="angle-up" />}</span>
																</Accordion.Toggle>
																<Accordion.Collapse eventKey="0">
																	<Card.Body>
																		{
																			model.categories.map((item: any, key: number) => {
																			return <Form.Group controlId={"category-" + item.id} key={key}>
																					<Form.Check 
																						type="checkbox" 
																						label={item.name}
																						checked={filters.category_id === item.id}
																						onChange={(e: any) => this.onFilterChange(e, 'category', item)} 
																					/>
																				</Form.Group>
																			})
																		}
																	</Card.Body>
																</Accordion.Collapse>
															</Card>
														</Accordion>
														<br />
													</React.Fragment>
													: null
												}
												
												<Accordion defaultActiveKey="0">
													<Card>
														<Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.filterCollapsClick('collaps_ratings')}>
															Customer Ratings <span className="float-right">{model.collaps_ratings ? <FontAwesomeIcon icon="angle-down" /> : <FontAwesomeIcon icon="angle-up" />}</span>
														</Accordion.Toggle>
														<Accordion.Collapse eventKey="0">
															<Card.Body>
																{
																	ratings.map((x: number, i: number) => {
																	return <Form.Group controlId={"rating-" + i} key={i}>
																			<Form.Check 
																				type="checkbox" 
																				label={ratings[i] + "★ & above"} 
																				onChange={(e: any) => this.onFilterChange(e, 'ratings', ratings[i])}
																			/>
																		</Form.Group>
																	})
																}
															</Card.Body>
														</Accordion.Collapse>
													</Card>
												</Accordion>
												<br />
												
												{
													discount_range.length ?
													<React.Fragment>
														<Accordion defaultActiveKey="0">
															<Card>
																<Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.filterCollapsClick('collaps_discount')}>
																	Discount <span className="float-right">{model.collaps_discount ? <FontAwesomeIcon icon="angle-down" /> : <FontAwesomeIcon icon="angle-up" />}</span>
																</Accordion.Toggle>
																<Accordion.Collapse eventKey="0">
																	<Card.Body>
																		{
																			discount_range.map((x: any, i: number) => {
																			return <Form.Group controlId={"discount-" + i} key={i}>
																					<Form.Check 
																						type="checkbox" 
																						label={discount_range[i] + '% & above'} 
																						onChange={(e: any) => this.onFilterChange(e, 'discount', discount_range[i])}
																					/>
																				</Form.Group>
																			})
																		}
																	</Card.Body>
																</Accordion.Collapse>
															</Card>
														</Accordion>
														<br />
													</React.Fragment>
													: null
												}
												
												<Accordion defaultActiveKey="0">
													<Card>
														<Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.filterCollapsClick('collaps_availability')}>
															Availability <span className="float-right">{model.collaps_availability ? <FontAwesomeIcon icon="angle-down" /> : <FontAwesomeIcon icon="angle-up" />}</span>
														</Accordion.Toggle>
														<Accordion.Collapse eventKey="0">
															<Card.Body>
																<Form.Group controlId={"availability-0"}>
																	<Form.Check 
																		type="checkbox" 
																		label="include out of stock" 
																		onChange={(e: any) => this.onFilterChange(e, 'availability', 'include')}
																	/>
																</Form.Group>
															</Card.Body>
														</Accordion.Collapse>
													</Card>
												</Accordion>
											</Col>
											<Col xs={12} sm={7} md={9} lg={9}>
												{
													!model.products.length ?
													<Row>
														<Col xs={12} sm={12} md={12} lg={12}>
															<Alert variant="danger" className="not_found_product_container">
																<span>Sorry! No product found.</span> <h2>Click <a href="#" onClick={(e:any) => this.refreshFilter(e)}>here</a> to refresh filter or try to searching for something else.</h2>
															</Alert>
														</Col>
													</Row>
													:
													<Row>
														{
															model.products.map((item: any, index: number) => {
															return <div className="single-product-column contain-product layout-default margin-bottom-15" key={index}>
																{
																	item.media ?
																	<div className="product-thumb">
																		<Link to={"/products/" + item.slug} target="_blank" className="image link-to-product">
																			<div className="max-fit-2 product-image">
																				<img src={item.media} className="img-fluid" alt="puja" />
																			</div>
																		 </Link>
																	</div>
																	: null
																}
																<div className="info">
																	<h4 className="product-title">
																		<Link to={"/products/" + item.slug} target="_blank" className="pr-name">{ item.name }</Link>
																	</h4>
																	<div className="price">
																		<span className="price-amount"><span className="currencySymbol"></span>{ this.priceDisplay('Rs. ', item.sale_price) }</span>
																		{
																			item.price !== item.sale_price ?
																			<span className="price-amount original_price"><span className="currencySymbol"></span>{ this.priceDisplay('Rs. ', item.price) }</span>
																			: null
																		}
																		</div>
																		<div className="slide-down-box">
																			<p className="message">All products are carefully selected to ensure food safety.</p>
																			<div className="buttons">
																					<a href="javascript: void(0)" onClick={(e) => this.addToWishlist(item, index)} className={item.wishlist_id  ? "" : "btn compare-btn"}>
																				 <FontAwesomeIcon icon="heart" />
																					</a>
																				<a href="javascript: void(0)" onClick={() => this.addToCart(item.id)} className="btn add-to-cart-btn">
																					<FontAwesomeIcon icon="shopping-cart" /> {this.checkIsProductProcessing(item.id) ? 'Processing...' : 'add to cart'}
																				</a>
																				<a href="javascript: void(0)" onClick={(e) => this.addToWishlist(item, index)} className={item.wishlist_id  ? "" : "btn compare-btn"}>
																				 <FontAwesomeIcon icon="heart" />
																					</a>
																			</div>
																		</div>
																	</div>
																</div>
															})
														}
														<Col xs={12} sm={12} md={12} lg={12}>
															<Pagination
																page={model.page}
																totalPage={model.totalPage}
																clickHandler={this.paginationClick}
															/>
														</Col>
													</Row>
												}
											</Col>
											{
												model.productSearching ? 
												<div className="each_item_loader">
													<div className="spinner_conent"><Spinner animation="border" variant="primary"/></div>
												</div>
												: null
											}
										</Row>
									</Col>
								</React.Fragment>
							}
						</div>
					</section>
				</div>
				
				<FooterPage />
				
			</React.Fragment>
    );
  }
}

export default withRouter(ProductSearchPage)
