import BaseViewModel from "../../Core/BaseViewModel";

export default class TicketCreateViewModel extends  BaseViewModel{
	loading: boolean = false;
	id: any;
	order: any;
	orderid: any;
	file: any;
  subject: string="";
  message: string="";
	formErrors:any = {};
	onProcess: boolean=false;
	isSuccess: string="";
}