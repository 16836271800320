import React from "react"
import ContentLoader from "react-content-loader"

const CartLoader = (props:any) => (
  <ContentLoader 
    speed={1.5}
    width={702}
    height={95}
    viewBox="0 0 702 95"
    backgroundColor="#ada9a9"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="144" height="80" /> 
    <rect x="158" y="0" rx="0" ry="0" width="600" height="16" /> 
    <rect x="158" y="29" rx="0" ry="0" width="300" height="16" />
  </ContentLoader>
)

export default CartLoader