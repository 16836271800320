import BaseViewModel from "../../Core/BaseViewModel";
import CustomerAddressModel from "../../Models/CustomerAddressModel";

export default class CustomerAddressListViewModel extends  BaseViewModel{

    addresses:CustomerAddressModel[]=[];
    selected_index:number=0;
    showAddModal:boolean=false;
    showEditModal:boolean=false;
    cancelModal:boolean=false;
    actionAddress:any;
    actionProcessing:boolean=false;
    loading: boolean = false;
}