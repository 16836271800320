import React  from 'react';
//import Modal from 'react-bootstrap/Modal';
import { Button, Col, Container, Nav, Navbar, Row, NavDropdown, Badge, FormControl, ListGroup, DropdownButton, Dropdown, InputGroup} from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import SessionHelper from '../../Core/SessionHelper';
import HeaderViewModel, { ViewType } from '../../ViewModels/Home/HeaderViewModel';
import HeaderDataAccess from '../../DataAccess/HeaderDataAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter, Link } from "react-router-dom";
// import update from 'immutability-helper';
// import { Parallax } from 'react-parallax';
import logo_area from '../../Assets/Images/logo_area.png'
// import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";

type Props = {
	search?: any,
	searchType?: any
	clearSearch?: any
}

class HeaderPage extends BaseComponent<any,HeaderViewModel> {
	public wrapperRef: any;
  constructor(props:any) {
		super(props)
		this.state = new BaseState<HeaderViewModel>(new HeaderViewModel());
		this.state.Model.ViewType = ViewType.Login;
		this.state.Model.currentPage = window.location.pathname.split("/").pop() || "";
		this.state.Model.search = this.props.search ? this.props.search : '';
		this.state.Model.searchType = this.props.searchType ? this.props.searchType : '';
		this.state.Model.navbarShow = [false, false, false];
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.wrapperRef = React.createRef();
  }
	
	componentDidMount(){
		this.getAllCategories();
		this.getAutocompletes();
		
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}
	
	handleClickOutside(event:any) {
		try{
			if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
				this.state.Model.autocompleteSearchData = [];
				this.UpdateViewModel();
			}
		}catch(err){
			
		}
	}
	
  logout(){
    this.ShowToast("Logout successfully done","success");
    window.location.href="/";
    SessionHelper.SetSession(null as any);
  }
	
	getAllCategories(){
		HeaderDataAccess.GetCategories(async (res: BaseResponse) => {
      if(!res.success){
				return;
        //return this.ShowToast(res.message,"warning");
      }
			
      this.state.Model.categories = res.data;
      this.UpdateViewModel();
    });
	}
	
	getAutocompletes = () => {
		HeaderDataAccess.GetAutocompletes(async (res: BaseResponse) => {
      if(!res.success){
				return;
      }
			
      this.state.Model.autocompleteItems = res.data;
      this.UpdateViewModel();
    });
		
	}
	
	onSearchChange = (e:any) => {
		let model = this.state.Model;
		model.searchType = '';
		model.search = e.target.value;
		if(!model.search){
			model.autocompleteSearchData = [];
		}else{
			let autocomplete = model.autocompleteItems;
			let searchKey = model.search.toLowerCase();
			model.autocompleteSearchData = autocomplete.filter((x:any) => x.label.toLowerCase().indexOf(searchKey) > -1 );
		}
		
		this.UpdateViewModel();
	}
	
	onSearchClick = () => {
		let model = this.state.Model;
		model.autocompleteSearchData = [];
		this.setState({
			Model: model
		}, () => {
			let url = "/search-results?search=" + this.state.Model.search;
			if(this.state.Model.searchType === 'category'){
				url += '&type=category'; 
			}
			this.props.history.push(url);
		})
		
	}
	
	handleSearchItemClick = (item:any) => {
		let model = this.state.Model;
		model.search = item.label;
		model.searchType = item.type;
		model.autocompleteSearchData = [];
		this.setState({
			Model: model
		}, () => {
			let url = "/search-results?search=" + item.label;
			if(item.type === 'category'){
				url += '&type=category'; 
			}
			this.props.history.push(url);
		})
		
	}

	navBarClick = (navID:any) => { 
		switch(navID){ 
           case 'basic-navbar-nav2' :
			this.state.Model.navbarShow[0] = !this.state.Model.navbarShow[0];
			this.state.Model.navbarShow[1] = true;
			this.state.Model.navbarShow[2] = false;
			this.UpdateViewModel();
		    break;	
		   
		   case 'basic-navbar-nav3' :
			this.state.Model.navbarShow[0] = false;
			this.state.Model.navbarShow[1] = false;
			this.state.Model.navbarShow[2] = true;
			this.UpdateViewModel();
		    break;
		} 
	}
	
	goCategoryPage = (name:string) => {
		this.props.history.push({
			pathname: '/category/' + name
		});
	}
	
	onSearchKeyDown = (e:any) => {
		if (e.key === 'Enter') {
			let model = this.state.Model;
			model.autocompleteSearchData = [];
			this.setState({
				Model: model
			}, () => {
				this.onSearchClick();
			})
			
		}
	}
	
	handleClearSearch = () => {
		let model = this.state.Model;
		model.autocompleteSearchData = [];
		model.search = '';
		model.searchType = '';
		this.setState({
			Model: model
		}, () => {
			if(this.props.location.pathname === "/search-results"){
				this.onSearchClick();
			}
		})
	}
	
	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		let search = prevState.Model.search;
		if('search' in nextProps && nextProps.search !== search && 'clearSearch' in nextProps && nextProps.clearSearch === true){
			search = nextProps.search;
			console.log(nextProps, prevState)
		}
		return {
			Model: {
				...prevState.Model,
				search: search
			}
		}
		
	}
	
  render(){
		var model = this.state.Model;
		let session = SessionHelper.GetSession();
		
		return(
			<div className="main-thea-header sticky-top">
				<div className="top-bar">
          <Container>
						<Row>
						  <Col xs={12} sm={12} md={6} lg={6}>
							<div className="topbar-left">
								<ul>
									<li>
										<i className="fas fa-phone-volume" /> +91 98745 97555 &nbsp;
									</li>
									<li>
										<i className="fas fa-envelope" /> webappssol@gmail.com
									</li>
								</ul>
							  </div>
							</Col>
							<Col xs={12} sm={12} md={6} lg={6}>
							  <div className="topbar-center">
							     <ul>
									<li>
									 <Link to="#"><FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon> </Link>
									</li>
									<li>
									 <Link to="#"><FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon> </Link>
									</li>
									<li>
									 <Link to="#"><FontAwesomeIcon icon={faGoogle}></FontAwesomeIcon> </Link>
									</li>
									<li>
									  <Link to="#"><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></Link>
									</li>
								 </ul>
							  </div>
							</Col>

						</Row>
					</Container>
				</div>
			
				<div className="mobile_menu">
					<Navbar bg="light" expand="lg">
						

					 <div className="mobile_top_menu">
					 <div className="logoinner">
							<Link to="/"> <img src={logo_area} alt="" /></Link>
						</div>
						<React.Fragment>
						  <nav className="container-fluid navbar navbar-expand-md navbar-static-top">
							{
								this.Auth ?
								
								<ul className="navbar-nav ml-auto">
									<li className="nav-item">
										<span className="dropdown-item cart-qty-icon nav-link"><FontAwesomeIcon icon="search" onClick={() => this.navBarClick('basic-navbar-nav2')} /></span>
									</li>
									<li className="nav-item">
										<Link className="dropdown-item cart-qty-icon nav-link" to="/carts" >
											<FontAwesomeIcon icon="shopping-cart" />{session && session.cart_count > 0 ? <Badge variant="danger" className="cart_badge">{session.cart_count}</Badge> : null}
										</Link>
									</li>
									<li className="nav-item">
										<Link className="dropdown-item cart-qty-icon nav-link" to="/wishlists">
											<FontAwesomeIcon icon="heart" />{session && session.wishlist_count > 0 ? <Badge variant="danger" className="cart_badge">{session.wishlist_count}</Badge> : null}
										</Link>
									</li>
									<li className="nav-item">
										<Link className="dropdown-item cart-qty-icon nav-link" to="/notifications">
											<FontAwesomeIcon icon="bell" />{session && session.notification_count > 0 ? <Badge variant="danger" className="cart_badge">{session.notification_count}</Badge> : null}
										</Link>
									</li>
									<li className="nav-item">
									
									<div className="purchase_important_icons">
										<Nav className="mr-auto">
											<NavDropdown title={<FontAwesomeIcon icon="user" />} id="basic-nav-dropdown">
														<Link to="/my-profile" className="dropdown-item">
															Account Details
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/brahmins" className="dropdown-item">
															Brahmins
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/notifications" className="dropdown-item">
															My Notifications
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/addresses" className="dropdown-item">
															My Addresses
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/orders" className="dropdown-item">
															My Order
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/events" className="dropdown-item">
															Events
														</Link>
														<div className="dropdown-divider"></div>
														{/* <Link to="/categories" className="dropdown-item">
															Category
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/faq" className="dropdown-item">
															FAQ
														</Link> */}
														<div className="dropdown-divider"></div>
														<Link to="#" className="dropdown-item" onClick={()=>this.logout()}>
															Log Out
														</Link>
											</NavDropdown>
										</Nav>
									</div>
									</li>
								</ul>
								
									: 
								<ul className="navbar-nav ml-auto">
									<li className="nav-item">
										<span className="dropdown-item cart-qty-icon nav-link"><FontAwesomeIcon icon="search" onClick={() => this.navBarClick('basic-navbar-nav2')} /></span>
									</li>
									<li className="nav-item">
										<Link className="dropdown-item cart-qty-icon nav-link" to="/login" >
											<FontAwesomeIcon icon="shopping-cart" />
										</Link>
									</li>
									<li className="nav-item">
										<Link className="dropdown-item cart-qty-icon nav-link" to="/login">
											<FontAwesomeIcon icon="heart" />
										</Link>
									</li>
									<li className="nav-item">
										<Link className="dropdown-item cart-qty-icon nav-link" to="/login">
											<FontAwesomeIcon icon="bell" />
										</Link>
									</li>
									<li className="nav-item">
										<Link className="dropdown-item cart-qty-icon nav-link" to="/login">
											<FontAwesomeIcon icon="sign-in-alt" />
										</Link>
									</li>
								</ul>

							}
							</nav>
						 </React.Fragment>
				      </div>

						<React.Fragment>
						 {
							 model.navbarShow[0] === true ?

							 <Navbar.Collapse id="basic-navbar-nav" className="show">
								<Nav className="ml-auto custom_navbar_container">
								<DropdownButton id="category-dropdown" title="Select Category" className="category_dropdown_container">
									{
											model.categories ?
											<React.Fragment>
											{
												model.categories.map((item: any, key: number) => {
													return <Dropdown.Item key={key} onClick={() => this.goCategoryPage(item.slug)}>{item.name}</Dropdown.Item>
												})
											}
											</React.Fragment>
											: null
										}
								</DropdownButton>
								</Nav>
				            </Navbar.Collapse>

							 : null
                         } 

						 </React.Fragment>

						 <React.Fragment>
						 {
							 model.navbarShow[0] === true ?
						
								<Navbar.Collapse id="basic-navbar-nav2" className="show" ref={this.wrapperRef}>
									<Nav className="ml-auto custom_navbar_container">
										<div className="gods_mobile_search_form">
										<InputGroup className="">
											<FormControl
												placeholder="Search Products"
												aria-describedby="basic-addon2"
												name="search"
												value={model.search}
												autoComplete="off"
												onChange={(e:any) => this.onSearchChange(e)} 
												onKeyDown={(e:any) => this.onSearchKeyDown(e)}
											/>
											{
												model.search !== '' ?
												<span className="close_icon_autocomplete">
													<FontAwesomeIcon icon="times" onClick={() => this.handleClearSearch()} />
												</span>
												:
												null
											}
											<InputGroup.Append>
												<InputGroup.Text id="basic-addon2" onClick={() => this.onSearchClick()} ><i className="fa fa-search" ></i></InputGroup.Text>
											</InputGroup.Append>
										</InputGroup>
										{
											model.autocompleteSearchData.length ?
											<div className="autocomplete_list">
												<ListGroup variant="flush">
												{
													model.autocompleteSearchData.map((item:any, key: number) => {
														return <ListGroup.Item key={key} onClick={() => this.handleSearchItemClick(item)}>
															{item.label}
														</ListGroup.Item>
													})
												}
												</ListGroup>
											</div>
											: null
										}
										</div>
									</Nav>
								</Navbar.Collapse>
								
								: null
							}
						</React.Fragment>
						<React.Fragment>	
							{
						      model.navbarShow[2] === true ?
								<Navbar.Collapse id="basic-navbar-nav3">
								<Nav className="ml-auto custom_navbar_container">
							

									<div className="purchase_important_icons">
										<Nav className="mr-auto">
											<NavDropdown title={session ? session.first_name : ''} id="basic-nav-dropdown">
														<Link to="/my-profile" className="dropdown-item">
															Account Details
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/brahmins" className="dropdown-item">
															Brahmins
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/notifications" className="dropdown-item">
															My Notifications
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/addresses" className="dropdown-item">
															My Addresses
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/orders" className="dropdown-item">
															My Order
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/events" className="dropdown-item">
															Events
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/categories" className="dropdown-item">
															Category
														</Link>
														<div className="dropdown-divider"></div>
														{/* <Link to="/faq" className="dropdown-item">
															FAQ
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="#" className="dropdown-item" onClick={()=>this.logout()}>
															Log Out
														</Link> */}
											</NavDropdown>
										</Nav>
									</div>

									</Nav>
						
								</Navbar.Collapse>
								: null
							} 
						 </React.Fragment>
						</Navbar>
					</div>
				<section className="godskart_header">
					
				<Navbar expand="lg" className="container">
					<Link to="/"> 
						<div className="logoinner">
							<img src={logo_area} alt="" />
						</div>
					</Link>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ml-auto custom_navbar_container">
					<DropdownButton id="category-dropdown" title="Select Category" className="category_dropdown_container">
						{
								model.categories ?
								<React.Fragment>
								{
									model.categories.map((item: any, key: number) => {
										return <Dropdown.Item key={key} onClick={() => this.goCategoryPage(item.slug)}>{item.name}</Dropdown.Item>
									})
								}
								</React.Fragment>
								: null
							}
					</DropdownButton>
					<div ref={this.wrapperRef} className="position_relative">
						<FormControl 
							type="text" 
							placeholder="Search for products..." 
							className="" 
							name="search"
							value={model.search}
							autoComplete="off"
							onChange={(e:any) => this.onSearchChange(e)} 
							onKeyDown={(e:any) => this.onSearchKeyDown(e)}
						/>
						{
							model.autocompleteSearchData.length ?
							<div className="autocomplete_list">
								<ListGroup variant="flush">
								{
									model.autocompleteSearchData.map((item:any, key: number) => {
										return <ListGroup.Item key={key} onClick={() => this.handleSearchItemClick(item)}>
											{item.label}
										</ListGroup.Item>
									})
								}
								</ListGroup>
							</div>
							: null
						}
						{
							model.search !== '' ?
							<span className="close_icon_autocomplete">
							<FontAwesomeIcon icon="times" onClick={() => this.handleClearSearch()} />
							</span>
							:
							null
						}
						<Button variant="outline-success" onClick={() => this.onSearchClick()}><i className="fas fa-search" /></Button>
					</div>
					
					</Nav>
					<div className="important_link">
									<div className="">
										<nav className="navbar navbar-expand-md navbar-static-top">
										{
											this.Auth ?
											<ul className="navbar-nav ml-auto">
												<li className="nav-item">
													<Link className="dropdown-item cart-qty-icon nav-link" to="/carts" >
														<FontAwesomeIcon icon="shopping-cart" />{session && session.cart_count > 0 ? <Badge variant="danger" className="cart_badge">{session.cart_count}</Badge> : null}
													</Link>
												</li>
												<li className="nav-item">
													<Link className="dropdown-item cart-qty-icon nav-link" to="/wishlists">
														<FontAwesomeIcon icon="heart" />{session && session.wishlist_count > 0 ? <Badge variant="danger" className="cart_badge">{session.wishlist_count}</Badge> : null}
													</Link>
												</li>

												<li className="nav-item">
													<Link className="dropdown-item cart-qty-icon nav-link" to="/notifications">
														<FontAwesomeIcon icon="bell" />{session && session.notification_count > 0 ? <Badge variant="danger" className="cart_badge">{session.notification_count}</Badge> : null}
													</Link>
												</li>
												
												<li className="nav-item">
													<NavDropdown title={session ? session.first_name : ''} id="basic-nav-dropdown">
														<Link to="/my-profile" className="dropdown-item">
															Account Details
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/brahmins" className="dropdown-item">
															Brahmins
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/notifications" className="dropdown-item">
															My Notifications
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/addresses" className="dropdown-item">
															My Addresses
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/orders" className="dropdown-item">
															My Order
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/events" className="dropdown-item">
															Events
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/categories" className="dropdown-item">
															Category
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="/faq" className="dropdown-item">
															FAQ
														</Link>
														<div className="dropdown-divider"></div>
														<Link to="#" className="dropdown-item" onClick={()=>this.logout()}>
															Log Out
														</Link>
													</NavDropdown>
												</li>
											</ul>
											:
											<ul className="navbar-nav ml-auto">
												<li className="nav-item">
													<Link className="dropdown-item cart-qty-icon nav-link" to="/login" >
														<FontAwesomeIcon icon="shopping-cart" />
													</Link>
												</li>
												<li className="nav-item">
													<Link className="dropdown-item cart-qty-icon nav-link" to="/login">
														<FontAwesomeIcon icon="heart" />
													</Link>
												</li>

												<li className="nav-item">
													<Link className="dropdown-item cart-qty-icon nav-link" to="/login">
														<FontAwesomeIcon icon="bell" />
													</Link>
												</li>
												<li className="nav-item">
													<Link className="nav-link custom-menu nav-link" to="/login">Login</Link>
												</li>
											</ul>
										}
										</nav>
									</div>
							   </div>
				</Navbar.Collapse>
				</Navbar>
			</section>
		    </div>
    );
  }
 }

 export default withRouter(HeaderPage);
