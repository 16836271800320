import React  from 'react';
import {Col, Row, Breadcrumb } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import Pagination from "../../Core/Pagination";
import ProductDataAccess from '../../DataAccess/ProductDataAccess';
import CustomerCartDataAccess from '../../DataAccess/CustomerCartDataAccess';
import WishlistDataAccess from '../../DataAccess/WishlistDataAccess';
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import CategoryProductViewModel from '../../ViewModels/Category/CategoryProductViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import { BaseLoader } from '../Core/BaseView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import ReactImageMagnify from 'react-image-magnify';
//import update from 'immutability-helper';
import { Link, withRouter } from 'react-router-dom';

class CategoryProductPage extends BaseComponent<any,CategoryProductViewModel> {
  constructor(props:any) {
    super(props);
    this.state = new BaseState<CategoryProductViewModel>(new CategoryProductViewModel());
		this.state.Model.categorySlug = this.props.match.params.slug;
    this.state.Model.loading = true;
  }
	
	componentDidMount(){
		this.getCategoryProduct();
	}
	
	componentDidUpdate(prevProps:any) {
		if (this.props.location !== prevProps.location) {
			let model = this.state.Model;
			model.categorySlug = this.props.match.params.slug;
			model.page = 1;
			model.loading = true;
			this.setState({
				Model: model
			}, () => {
				this.getCategoryProduct();
			})
		}
	}
	
	getCategoryProduct(){
		let model = this.state.Model;
		let params = {
			category_slug: model.categorySlug,
			page: model.page
		}
		ProductDataAccess.GetProducts(params, (res: BaseResponse) => {
      if(!res.success){
				this.state.Model.loading = false;
				this.UpdateViewModel();
				return;
      }
			this.state.Model.loading = false;
			this.state.Model.products = res.data.items;
			this.state.Model.totalPage = res.data.total_pages;
			this.UpdateViewModel();
			
		});
		
	}

	addToWishlist = (item: any, key: any) => { 
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		
		//let model = this.state.Model;
		if(item.wishlist_id){
			WishlistDataAccess.RemoveWishlist(item.id, (res: BaseResponse) => {
				if(!res.success){
					return;
				}
				this.state.Model.products[key]['wishlist_id'] = "";
				this.UpdateViewModel();
				this.UpdateWishlistCount(res.data.wishlist_count);
				this.ShowToast(res.message,"success");
				
			});
			
		}else{
			let params = {
				product_id: item.id
			}
			WishlistDataAccess.AddWishlist(params, (res: BaseResponse) => {
				if(!res.success){
					return;
				}
				this.state.Model.products[key]['wishlist_id'] = res.data.wishlist_count;
				this.UpdateViewModel();
				this.UpdateWishlistCount(res.data.wishlist_count);
				this.ShowToast(res.message,"success");
			});
		}
		
	}
	
	addToCart = (id: number) => {
		if(!this.Auth){
			return this.props.history.push("/login");
		}
		let model = this.state.Model;
		if(this.checkIsProductProcessing(id)) return;
		let params = {
			product_id: id,
			quantity: 1
		};
		
		this.state.Model.itemsLoading.push(id);
    this.UpdateViewModel();
		
		CustomerCartDataAccess.Create(params, async (res: BaseResponse) => {
			model.itemsLoading = model.itemsLoading.filter(i => i !== id);
			this.UpdateViewModel();
			if(!res.success){
				this.ShowToast(res.message,"warning");
				return false;
			}
			
			this.UpdateCartCount(res.data.cart_count);
			this.ShowToast(res.message,"success");
		});
	}
	
	checkIsProductProcessing(id: number){
		let model = this.state.Model;
		if(model.itemsLoading.includes(id)){
			return true;
		}else{
			return false;
		}
	}
	
	paginationClick = (page:number) => {
		let model = this.state.Model;
		model.page = page;
		this.setState({
			Model: model
		}, () => {
			this.getCategoryProduct();
		})
	}
  
	render(){
		let model = this.state.Model;
		
		return(
			<React.Fragment>
				<HeaderPage />
				
				<div className="container">
					<section className="category_product_container">
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="breadcrumb-container">
									<Breadcrumb>
										<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
										<Breadcrumb.Item active>Category Products</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</Col>
						</Row>
						<Row>
							{
								model.loading ?
								<Col xs={12} sm={12} md={12} lg={12}>
									<div className="container_loader">
										<BaseLoader/>
									</div>
								</Col>
								:
								<React.Fragment>
								{
									model.products.map((item: any, index: number) => {
									return <div className="single-product-column contain-product layout-default margin-bottom-15" key={index}>
										{
											item.media ?
											<div className="product-thumb">
												<Link to={"/products/" + item.slug} target="_blank" className="image link-to-product">
													<div className="max-fit-2 product-image">
														<img src={item.media} className="img-fluid" alt="puja" />
													</div>
												 </Link>
											</div>
											: null
										}
										<div className="info">
											<h4 className="product-title">
												<Link to={"/products/" + item.slug} target="_blank" className="pr-name">{ item.name }</Link>
											</h4>
											<div className="price">
												<span className="price-amount"><span className="currencySymbol"></span>{ this.priceDisplay('Rs. ', item.sale_price) }</span>
												{
													item.price !== item.sale_price ?
													<span className="price-amount original_price"><span className="currencySymbol"></span>{ this.priceDisplay('Rs. ', item.price) }</span>
													: null
												}
												</div>
												<div className="slide-down-box">
													<p className="message">All products are carefully selected to ensure food safety.</p>
													<div className="buttons">
													    <a href="javascript: void(0)" onClick={(e) => this.addToWishlist(item, index)} className={item.wishlist_id  ? "" : "btn compare-btn"}>
													        	<FontAwesomeIcon icon="heart" />
												        </a>
														<a href="javascript: void(0)" onClick={() => this.addToCart(item.id)} className="btn add-to-cart-btn">
															<FontAwesomeIcon icon="shopping-cart" /> {this.checkIsProductProcessing(item.id) ? 'Processing...' : 'add to cart'}
														</a>
														<a href="javascript: void(0)" onClick={(e) => this.addToWishlist(item, index)} className={item.wishlist_id  ? "" : "btn compare-btn"}>
													        	<FontAwesomeIcon icon="heart" />
												        </a>
													</div>
												</div>
											</div>
										</div>
								})
								}
								
								<Col xs={12} sm={12} md={12} lg={12}>
									<Pagination
										page={model.page}
										totalPage={model.totalPage}
										clickHandler={this.paginationClick}
									/>
								</Col>
								
								</React.Fragment>
							}
						</Row>
					</section>
				</div>
				
				<FooterPage />
				
			</React.Fragment>
    );
  }
}

export default withRouter(CategoryProductPage)
