import React  from 'react';
import {Col, Container, Row, Image, Alert } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseState from '../../Core/BaseState';
import BaseResponse from '../../Core/BaseResponse';
import BrahminModel from '../../Models/BrahminModel';
import BrahminsViewModel from '../../ViewModels/Brahmins/BrahminsViewModel';
import BrahminDataAccess from '../../DataAccess/BrahminDataAccess';
import SessionHelper from '../../Core/SessionHelper';
import HeaderPage from '../Core/HeaderPage';
import { BaseLoader } from '../Core/BaseView';
import FooterPage from '../Core/FooterPage';
import brahmin_image from '../../Assets/Images/brahmin.png';
import Pagination from "../../Core/Pagination";

export default class BrahminPage extends BaseComponent<any, BrahminsViewModel> {

  constructor(props:any) {
      super(props);
      SessionHelper.HandleAuthentication();
      this.state = new BaseState<BrahminsViewModel>(new BrahminsViewModel());
	  this.state.Model.loading = true;
  }


  componentDidMount(){
	if(!this.Auth){
		return null;
	}
    this.BindData();
  }


  BindData(){
    this.GetList();
  }

  getDefaultImg = (index: any) => {
	let model = this.state.Model;
	model.brahmins[index]['photo'] = brahmin_image; 
	this.UpdateViewModel();
  }


  	GetList = async  () => {
		let params = {
			page: this.state.Model.current_page
		}
		BrahminDataAccess.GetList(params, async (res: BaseResponse) => {
			if(!res.success){
				return this.ShowToast(res.message,"warning");
			}
			this.state.Model.loading = false;		
			this.state.Model.brahmins=res.data.items as BrahminModel[];
			this.state.Model.totalPage = res.data.total_pages;
			this.UpdateViewModel();
    	});

  	}

	paginationClick = (page:number) => {
		let model = this.state.Model;
		model.current_page = page;
		model.loading = true;
		this.setState({
			Model: model
		}, () => {
			this.GetList();
		})
	}
	
	render(){
		let model = this.state.Model;

		if(!this.Auth){
			return null;
		}

		return(
			<div>
				<HeaderPage/>
				<div className="brahmin_profile_page">

				{
					model.loading ?
					<Col xs={12} sm={12} md={12} lg={12}>
						<div className="container_loader">
							<BaseLoader/>
						</div>
					</Col>
					:
					<React.Fragment>
					{
						!model.brahmins.length ?
						<Col xs={12} sm={12} md={12} lg={12}>
							<Alert variant="danger" className="not_found_product_container">
								<span>Sorry! No brahmins found.</span>
							</Alert>
						</Col>
						:
						<Container>
							{
								model.brahmins.map((item:any, index:number) => {
								return <Row key={item.id}>
                                        <div className="brahmins-list-area">
										<div className="row no-gutters">
											<div className="col-auto">
												<Image src={item.photo ? item.photo : brahmin_image} alt="" 
													onError={(e) => this.getDefaultImg(index)}
													height="100"
													width="100"
												/>
											</div>
											<div className="col">
												<div className="card-block px-2">
													<h4 className="card-title">{item.name}</h4>
													<p className="card-text"><span> <strong>Email:</strong></span> {item.email}</p>
													<p className="card-text"><span> <strong>Mob:</strong></span> {item.mobile}</p>
													<p className="card-text"><span> <strong>Qualification:</strong></span> {item.qualification}</p>
                                					<p className="card-text"><span> <strong>Experience:</strong></span> {item.experience}</p>
													<p className="card-text">{item.description}</p>
												</div>
											</div>
										</div>
									</div>
                                    </Row>
								})
							}
							
						</Container>
					}
					<Container>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} className="pl-0">
								<Pagination
									page={model.current_page}
									totalPage={model.totalPage}
									clickHandler={this.paginationClick}
								/>
							</Col>
						</Row>
					</Container>
					</React.Fragment>
				}

				</div> 
				<FooterPage />
			</div>
		);
	}
}
