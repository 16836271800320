import React ,{StrictMode} from 'react';
import { BrowserRouter as Router, Switch} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-awesome-slider/dist/styles.css';
import 'react-multi-carousel/lib/styles.css';
import AppStart from './Page/Core/AppStart';
import fontawesome from '@fortawesome/fontawesome';
import { faCheckSquare, faCoffee,faUser } from '@fortawesome/fontawesome-free-solid';
import 'react-toastify/dist/ReactToastify.css';
import 'aos/dist/aos.css';
import './Assets/Css/app.scss';
import {toast } from 'react-toastify';
//import HomePage from './Page/Home/HomePage';
import ScrollToTop from './Core/ScrollToTop';

fontawesome.library.add(faCheckSquare, faCoffee,faUser);
toast.configure();
function App() {
  return (
    <StrictMode>
      <Router>
				<ScrollToTop>
					<Switch>
						
						<AppStart/>
					</Switch>
				</ScrollToTop>
      </Router>
    </StrictMode>
  );
}

export default App;
