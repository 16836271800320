 export default class PagePath{
    Text:string="";
    Path:string="";
    Icon?:Object;
    Component?:any;
    ShowInNavbar:boolean=true;
    IsSelected:boolean=false;
    constructor(Text:string,Path:string,Icon:Object,Component:any){
        this.Text=Text;
        this.Path=Path;
        this.Icon=Icon;
        this.Component=Component;
    }     
}