import React  from 'react';
import { Col, Container, Row, Button, Modal, Spinner, Card, Form, Breadcrumb } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import SessionHelper from '../../Core/SessionHelper';
import CustomerOrderDataAccess from '../../DataAccess/CustomerOrderDataAccess';
import TicketCreateViewModel from '../../ViewModels/Ticket/TicketCreateViewModel';
import HeaderPage from '../Core/HeaderPage';
import { BaseLoader } from '../Core/BaseView';
import { Link } from 'react-router-dom';
import FooterPage from '../Core/FooterPage';
import { withRouter } from 'react-router-dom';
import PageNotFoundContent from '../Core/PageNotFoundContent';

class TicketCreatePage extends BaseComponent<any, TicketCreateViewModel> {

  constructor(props:any) {
		super(props);
		SessionHelper.HandleAuthentication();
		this.state = new BaseState<TicketCreateViewModel>(new TicketCreateViewModel());
	  this.state.Model.loading = true;
  }

  componentDidMount(){
		if(!this.Auth){
			return null;
		}
    this.GetOrderDetails();
  }

  GetOrderDetails = async  () => {
		let orderId = this.props.match.params.orderid;
		this.state.Model.orderid = orderId;
		this.UpdateViewModel();
		
		CustomerOrderDataAccess.OrderView(orderId, async (res: BaseResponse) => {
			if(!res.success){
				this.state.Model.loading = false;
				this.UpdateViewModel();
				return this.ShowToast(res.message,"warning");
			}
			this.state.Model.order = res.data;
			this.state.Model.loading = false;
			this.UpdateViewModel();
		});
  }
	
	Create = async () => {
		if(!this.ValidateFrom() || this.state.Model.onProcess){
      return false;
    }
		this.state.Model.onProcess = true;
		this.UpdateViewModel();
		let model = this.state.Model;
		
		const formData = new FormData();
		if(model.file){
			for (let i = 0; i < model.file.length; i++) {
				formData.append(`file[${i}]`, model.file[i])
			}
		}
		formData.append('message', model.message);
		formData.append('subject', model.subject);
		formData.append('order_id', model.orderid);
		formData.append('status', 'active');
		
    CustomerOrderDataAccess.createTicket(formData, async (res: BaseResponse) => {
			this.state.Model.onProcess = false;
			if(!res.success){
				try {
					let errArr = JSON.parse(res.message);
					let formErrors:any = {};
					if('subject' in errArr){
						formErrors.subject=errArr.subject[0];
					}
					if('message' in errArr){
						formErrors.message=errArr.message[0];
					}
					this.state.Model.formErrors = formErrors;
					this.UpdateViewModel();
					return;
				} catch (e) {
					this.UpdateViewModel();
					return this.ShowToast(res.message,"success");
				}
			}
			
			this.state.Model.isSuccess = res.message;
			this.state.Model.id = res.data.id;
			this.UpdateViewModel();
			
			this.props.history.push('/order/' + this.state.Model.orderid + '/tickets/' + this.state.Model.id);
			
    });
	}
	
	handleFileChange = (e:any) => {
		this.state.Model.file = e.target.files;
	}
	
	
	ValidateFrom = () => {
		let model = this.state.Model;
		let formErrors:any = {};
		if(!model.subject){
			formErrors.subject = 'This field is required.'
		}
		if(!model.message){
			formErrors.message = 'This field is required.'
		}
		
		this.state.Model.formErrors = formErrors;
		this.UpdateViewModel();
		return Object.keys(formErrors).length === 0;
	}
	
	hasError = (key: string) => {
		let formErrors = this.state.Model.formErrors;
		return (key in formErrors && formErrors[key]);
	}
	
	showError = (key: string) => {
		let formErrors = this.state.Model.formErrors;
		return this.hasError(key) ? <Form.Text className="text-danger">{formErrors[key]}</Form.Text> : null;
	}

	render(){
		let model = this.state.Model;
		let order = model.order;

		if(!this.Auth){
			return null;
		}
		
		return(
			<div>
				<HeaderPage />
				
				<div className="container">
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="breadcrumb-container">
									<Breadcrumb>
										<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
										<Breadcrumb.Item href="/orders">My Order</Breadcrumb.Item>
										<Breadcrumb.Item href={"/order-details/" + model.orderid}>Order Details</Breadcrumb.Item>
										<Breadcrumb.Item href={"/order/"+model.orderid+"/tickets"}>All Tickets</Breadcrumb.Item>
										<Breadcrumb.Item active>Create Ticket</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</Col>
							{
								model.loading ?
								<Col xs={12} sm={12} md={12} lg={12}>
									<div className="container_loader">
										<BaseLoader/>
									</div>
								</Col>
								:
								<React.Fragment>
								{
									!model.loading && !order ?
									<Col md={{span: 8, offset: 2}}>
										<PageNotFoundContent/>
									</Col>
									:
									<React.Fragment>
										<Col xs={12} sm={12} md={12} lg={12}>
										<Form className="tick_form_container">
										
												<Col xs={12} md={6}>
													<Form.Group controlId="subject">
														<Form.Label>Subject</Form.Label>
														<Form.Control 
															type="text" 
															name="subject"
															value={model.subject}
															isInvalid={this.hasError('subject')}
															onChange={this.SetModelValue}
															placeholder="Subject" 
														/>
														{this.showError('subject')}
													</Form.Group>
												</Col>
												<Col xs={12} md={12}>
													<Form.Group controlId="message">
														<Form.Label>Please explain your issue</Form.Label>
														<Form.Control 
															as="textarea" 
															rows={4} 
															placeholder="Write Something..." 
															name="message"
															value={model.message}
															isInvalid={this.hasError('message')}
															onChange={this.SetModelValue}
														/>
														{this.showError('message')}
													</Form.Group>
												</Col>
												<Col xs={12} md={6}>
													<Form.Group controlId="file">
														<Form.Label>Image</Form.Label>
														<Form.File 
															type="text"
															name="file"
															multiple
															accept="image/*"
															onChange={this.handleFileChange}
														/>
													</Form.Group>
												</Col>
												<Col xs={12} md={12}   className="text-right">
													<Form.Group controlId="formSubmit" className="ticket_submit_button">
														<Button 
															onClick={this.Create}
															disabled={model.onProcess}
														>
														{
															model.onProcess ? 
															<div>
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>&nbsp;Processing....
															</div>
															:
															"Submit"
														}
														</Button>
													</Form.Group>
												</Col>
										
										</Form>
										</Col>
									</React.Fragment>
								}
								</React.Fragment>
							}
						</Row>
						
				</div>
				
				<FooterPage />
			</div>
		);
	}
}

export default withRouter(TicketCreatePage)