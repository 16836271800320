import { Component } from 'react'
import BaseState from './BaseState';
import { RouteComponentProps } from 'react-router-dom';
import CustomerModel from '../Models/CustomerModel';
import SessionHelper from './SessionHelper';
//import SessionHelper from './SessionHelper';

type userLocationModel = {
  latitude: string;
  longitude: string;
};

export default abstract class BaseComponentCore<T extends RouteComponentProps<any>, U> extends Component<T, BaseState<U>> {

  //hare implament template set up done

  public Auth:CustomerModel=SessionHelper.GetSession();
	
	public paymentOptions:any = {
		upi: 'UPI',
		card: 'Credit / Debit / ATM Card',
		netbanking: 'Net Banking',
		cash: 'Cash On Delivery'
	}
	
	public RECAPTCHA_SITE_KEY:string = "6LdXPwIaAAAAAE52hKRSsrxPCQ34Wm_7f__J-q53";
	public RECAPTCHA_SECRET_KEY:string = "6LdXPwIaAAAAAMICEHXYGdXaZ__gYYsYJc1iP5QT";
	
	public FACEBOOK_CLIENT_ID:string = "314711296347174";
	public GOOGLE_CLIENT_ID:string = "711798545850-1lq6sa52nv64vsigcu7vvv57u9lp2p0e.apps.googleusercon$";
	public TWITTER_CLIENT_ID:string = "VfLQAAokzsSA3PcBxEFA80wmI";
	
  // ViewModel: any = undefined;
  // constructor(props: T) {
  //   super(props);
  // }


  // HandleAuthentication(props: T) {
  //   var user = SessionHelper.GetSession();
  //   if (user === null && props.location && props.location.pathname !== "/") {
  //     props.history.push("/");
  //   }

  // }

  SetModelValue = (event: any) => {
    const { target: { id, name, value } } = event;
    var propertyName = id;
    if (!propertyName) {
      propertyName = name;
    }
    if (!propertyName) {
      return alert("propertyName undefined")
    }

    Object.assign(this.state.Model, { [propertyName]: value })
    this.setState({
      Model: this.state.Model
    } as Pick<BaseState<U>, keyof BaseState<U>>);
  }


  SetModelValueByObject = (name: string, text: any) => {
    if (!name) {
      return alert("propertyName undefined")
    }

    Object.assign(this.state.Model, { [name]: text })

    this.setState({
      Model: this.state.Model
    } as Pick<BaseState<U>, keyof BaseState<U>>);
  }
 
  UpdateViewModelUnknown(Model: any) {
    this.setState({
      Model: Model as unknown as U
    })
  }
  UpdateViewModel() {
    this.setState({
      Model: this.state.Model
    })
  }
 
}

