import React  from 'react';
//import Modal from 'react-bootstrap/Modal';
import { Col, Row, Button, Spinner, Form, Breadcrumb, Table  } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import SessionHelper from '../../Core/SessionHelper';
import CustomerCartDataAccess from '../../DataAccess/CustomerCartDataAccess';
import CustomerAddressDataAccess from '../../DataAccess/CustomerAddressDataAccess';
import CustomerOrderDataAccess from '../../DataAccess/CustomerOrderDataAccess';
//import CustomerCartModel from '../../Models/CustomerCartModel';
import CustomerAddressModel from '../../Models/CustomerAddressModel';
import CustomerCartCheckoutViewModel from '../../ViewModels/CustomerCart/CustomerCartCheckoutViewModel';
import HeaderPage from '../Core/HeaderPage';
import { withRouter } from 'react-router-dom';
import CartLoader from '../../Skeletons/CartLoader';
import FooterPage from '../Core/FooterPage';
import update from 'immutability-helper';

class CustomerCartCheckoutPage extends BaseComponent<any, CustomerCartCheckoutViewModel> {

  constructor(props:any) {
		super(props);
		SessionHelper.HandleAuthentication();
		this.state = new BaseState<CustomerCartCheckoutViewModel>(new CustomerCartCheckoutViewModel());
  }


  componentDidMount(){
		if(!this.Auth){
			return null;
		}
		this.BindData();
  }


  BindData(){
    this.GetList();
  }


  GetList = async  () => {
    CustomerCartDataAccess.GetList(async (res: BaseResponse) => {
      if(!res.success){
        return this.ShowToast(res.message,"warning");
      }
			
			if(!res.data.length){
				window.location.href = '/';
			}
			
			let carts = {
				items: res.data,
				amount: 0,
				charge: 0,
				cgst: 0,
				igst: 0,
				sgst: 0,
				discount: 0,
				total: 0,
				total_item: res.data.length
			}
			
			let newState = update(this.state, {
				Model: {
					carts: {$set: carts},
					IsPageLoading: {$set: false},
					cartLoading: {$set: false},
				}
			});
			this.setState(newState, () => {
				this.UpdateCartCount(carts.total_item);
				this.updateOrderSummery();
			});
			
    });
		
		CustomerAddressDataAccess.GetList(async (res: BaseResponse) => {
      if(!res.success){
        return this.ShowToast(res.message,"warning");
      }
      this.state.Model.addresses=res.data as CustomerAddressModel[];
      this.UpdateViewModel();
    });
  }
	
	updateOrderSummery = () => {
		let model = this.state.Model;
		let carts = {
			items: model.carts.items,
			amount: 0,
			charge: 0,
			cgst: 0,
			igst: 0,
			sgst: 0,
			discount: 0,
			total: 0,
			total_item: 0
		}
	
		if(model.carts.items.length > 0){
			model.carts.items.forEach((cart:any) => {
				carts.amount += cart.quantity * cart.product_sale_price;
				carts.cgst += cart.quantity * cart.cgst;
				carts.igst += cart.quantity * cart.igst;
				carts.sgst += cart.quantity * cart.sgst;
				carts.charge = cart.charge;
			});
			carts.total = (carts.amount + carts.cgst + carts.igst + carts.sgst + carts.charge);
    }
		
		let newState = update(this.state, {
			Model: {
				carts: {$set: carts}
			}
		});
		this.setState(newState);
	}
	
	SelectAddress = (event: any) => {
    const { target: { value } } = event;
		console.log(value);
		this.state.Model.select_address = parseInt(value);
		this.UpdateViewModel();
	}
	
	addAddress = () => {
		this.props.history.push("/addresses");
	}
	
	Proceed = async () => {
		let model = this.state.Model;
		if(!model.select_address){
			return this.ShowToast('Please select address',"warning");
		}
		
		if(!model.selected_method){
			return this.ShowToast('Please select payment option',"warning");
		}
		
		if(this.state.Model.order_processing){
			return;
		}
		
		this.state.Model.order_processing=true;
		this.UpdateViewModel();
		
		let orderData = {
			mode: 'cart',
			default_address_id: model.select_address,
			payment_mode: model.selected_method,
			coupon_id: 0, //model.coupon_id,
			discount_amount: model.deducted_amount,
			use_wallet: false
		};
		
		CustomerOrderDataAccess.Create(orderData, async (res: BaseResponse) => {
      if(!res.success){
				this.state.Model.order_processing=false;
				this.UpdateViewModel();
        return this.ShowToast(res.message,"warning");
      }
			
			if(model.selected_method === 'cash'){
				this.ShowToast(res.message,"success");
				this.UpdateCartCount(0);
				setTimeout(() => {
					this.props.history.push("/orders");
				}, 1000);
			}else{
				let order_id = res.data.order_id;
				let _this = this;
				var options = {
						key: res.data.payment_method_Id,
						amount: res.data.amount,
						currency: "INR",
						name: res.data.site_name,
						description: res.data.description,
						image: res.data.site_image,
						order_id: res.data.payment_order_id,
						remember_customer: false,
						prefill: {
							name: res.data.name,
							email: res.data.email,
							contact: res.data.phone,
							method: model.selected_method
						},
						notes:{
							address: res.data.address,
						},
						theme:{
							color:"#1545ef",
						},
						handler: function(res2:any){
							res2 = {...res2, ...{
								order_id: order_id,
								mode: 'cart'
							}};
							
							CustomerOrderDataAccess.PaymentVerify(res2, async (res: BaseResponse) => {
								_this.state.Model.order_processing=false;
								_this.UpdateViewModel();
								if(!res.success){
									let params = {
										order_id: order_id,
										payment_status: 'failed',
										transaction_id: res2.razorpay_payment_id
									}
									_this.updatePaymentStatus(params);
									return;
									//return _this.ShowToast(res.message,"warning");
								}
								_this.UpdateCartCount(0);
								_this.ShowToast(res.message,"success");
								setTimeout(() => {
									_this.props.history.push("/orders");
								}, 1000);
								
							});
							
						},
						"modal": {
							"ondismiss": function(){
								let params = {
									order_id: order_id,
									payment_status: 'failed',
									transaction_id: ''
								}
								_this.updatePaymentStatus(params);
							}
						}
				};
				var razorPay = new (window as any).Razorpay(options);
				razorPay.open();
			}
			
			//this.ShowToast(res.message,"success");
			
			
    });
		
	}
	
	updatePaymentStatus = async (params: any) => {
		let postParams = {
			order_id: params.order_id,
			payment_status: params.payment_status,
			payment_transaction_id: params.transaction_id,
			payment_mode: 'online'
		};
		CustomerOrderDataAccess.UpdatePaymentStatus(postParams, async (res: BaseResponse) => {
			this.state.Model.order_processing=false;
			this.UpdateViewModel();
			this.UpdateCartCount(0);
      if(!res.success){
				setTimeout(() => {
					this.props.history.push("/orders");
				}, 1000);
        return this.ShowToast(res.message,"warning");
      }
			
			this.ShowToast(res.message,"success");
			setTimeout(() => {
				this.props.history.push("/orders");
			}, 1000);
			
		});
		
	}
	
	handlePaymentOption = (event: any) => {
		const { target: { value } } = event;
		this.state.Model.selected_method=value;
		this.UpdateViewModel();
	}
	
	render(){
		let model = this.state.Model;
		
		if(!this.Auth){
			return null;
		}
		
		
		return(
			<div>
				<HeaderPage />
				
				<div className="container">
					<section className="checkout-main-container">
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="breadcrumb-container">
									<Breadcrumb>
										<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
										<Breadcrumb.Item active>Secure Checkout</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</Col>
							{
							(model.IsPageLoading || !model.carts) ?
							<Col xs={12} sm={12} md={12} lg={12}>
								<Row className="position_relative">
								{
									[...Array(4)].map((x, i) => {
										return <Col lg={12} key={i}>
											<CartLoader />
										</Col>
									})
								}
								</Row>
							</Col>
							:
							<React.Fragment>
								<Col xs={12} sm={8} md={8} lg={8}>
									<Row>
										<Col xs={12} sm={12} md={12} lg={12}>
											<div className="checkout-header">
												<h1>Checkout Details</h1>
											</div>
											<div className="checkout-table-container">
												<Table striped bordered hover>
													<thead>
														<tr>
															<th>Address</th>
															<th>Select Address</th>
														</tr>
													</thead>
													<tbody>
														{
															model.addresses.map((item:any, index:number) => {
															return <tr key={index}>
																<td>{item.street}, {item.city}, {item.state}, {item.country}, {item.zip}</td>
																<td>
																	<Form.Check 
																		checked={model.select_address === item.id}
																		value={item.id}
																		custom 
																		inline 
																		label="" 
																		name="address"
																		type="radio" 
																		id={"address-" + item.id}
																		onChange={this.SelectAddress}
																	/>
																</td>
															</tr>
														})
														}
													</tbody>
												</Table>
											</div>
										</Col>
										<Col xs={12} sm={12} md={12} lg={12}>
											<div className="new-address-btn">
												<Button variant="primary" className="el-button el-button--primary" onClick={() => this.addAddress()}>Add a New Address</Button>
											</div>
										</Col>
										<Col xs={12} sm={12} md={12} lg={12}>
											{/*<div className="order-summary">
												<el-checkbox v-model="walletCheck" @change="applyWallet($event)">Use Your Wallet Balance ({{priceDisplay('Rs. ', walletBalance)}})</el-checkbox>
											</div>*/}
											<div className="product_payment_mode">
												{
													Object.keys(this.paymentOptions).map((k:string) => {
													return <Form.Check 
														name="payment_options" 
														label={this.paymentOptions[k]} 
														value={k} 
														custom 
														type="radio" 
														id={`inline-${k}`}
														onChange={this.handlePaymentOption}
														key={k} 
													/>
													})
												}
												
											</div>
										</Col>

										
									</Row>
								</Col>
								
								<Col xs={12} sm={4} md={4} lg={4}>
									<div className="checkout-header">
										<h1>Price Details</h1>
									</div>
									<div className="checkout_order_area">
										<div className="checkout_page_total_column">
											<div className="total-amount-name">Amount</div>
											<div className="subtotal-amount">{ this.priceDisplay('Rs. ', model.carts.amount) }</div>
										</div>
										<div className="checkout_page_total_column"><div className="total-tax-name">CGST</div>
											 <div className="total-tax-amount">{ this.priceDisplay('Rs. ', model.carts.cgst) }</div>
										</div>
										<div className="checkout_page_total_column"><div className="total-tax-name">IGST</div>
											 <div className="total-tax-amount">{ this.priceDisplay('Rs. ', model.carts.igst) }</div>
										</div>
										<div className="checkout_page_total_column"><div className="total-tax-name">SGST</div>
										 <div className="total-tax-amount">{ this.priceDisplay('Rs. ', model.carts.sgst) }</div>
										</div>
										<div className="checkout_page_total_column"><div className="total-tax-name">Shipping Charge</div>
										 <div className="total-tax-amount">{ this.priceDisplay('Rs. ', model.carts.charge) }</div>
										</div>
										{/*<div className="checkout_page_total_column" v-if="finalCarts.discount > 0"><div className="total-tax-name">Discount</div>
										 <div className="total-tax-amount">{ this.priceDisplay('Rs. ', model.carts.discount) }</div>
										</div> */}
										<div className="checkout_page_total_column"><div className="total-name">Total Amount</div>
										 <div className="total-amount">{ this.priceDisplay('Rs. ', model.carts.total) }</div>
										</div>
									</div>
								</Col>
							</React.Fragment>
							}
						</Row>
						<Row>
						<Col xs={12} sm={12} md={12} lg={12}>
											<div className="margin-top-30 checkout-placeorder">
												<Button variant="primary" className="el-button el-button--primary" onClick={() => this.Proceed()} disabled={model.order_processing}>
													{
														model.order_processing ? 
														<div className="spinner_container">
															<Spinner
																as="span"
																animation="border"
																size="sm"
																role="status"
																aria-hidden="true"
															/>&nbsp;Processing...
														</div>
														:
														"Place Order"
													}
												</Button>
											</div>
										</Col>
						</Row>
					</section>
				</div>
				
				<FooterPage />
			</div>
		);
	}
}

export default withRouter(CustomerCartCheckoutPage)
