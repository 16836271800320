import BaseViewModel from "../../Core/BaseViewModel";
import SectionModel from "../../Models/SectionModel";
import OfferModel from "../../Models/OfferModel";

export default class SlidesViewModel extends BaseViewModel{
	loading: boolean = false;
	section: any = SectionModel;
	slides: SlidesItem[] = [];
	offers: OfferModel[] = [];
	active_product: number = 0;
}

export class SlidesItem {
	media: string = "";
	tag_line: string = "";
	title: string = "";
}