interface IBaseResponse<T>{  
    success:boolean;
    data?:T;
    message:any;
}
export enum ErrorType {
    Error,
    Warning,
    Info,
    Success
}
export interface IErrorMessage {
    FieldName? :string;
    Message :string;
    AdditionInfo? :object|null;
    ErrorType:ErrorType;
}
export class BaseResponseCore<T>{
    success:boolean;
    data:any;
    message:any;
    constructor(data:IBaseResponse<T>= {success:false,data:{} as T, message:""}){        
        this.success=data.success;
        this.data=data.data; 
        this.message=data.message;
    } 
}
export default class BaseResponse extends BaseResponseCore<object>{      
    constructor(data:IBaseResponse<object>= {success:false,data:{}, message:""}){
        super(data);        
    } 
}

export class ErrorMessage {
    Key:string;
    FieldName? :string;
    Message :string;
    AdditionInfo? :object|null;
    ErrorType:ErrorType;

    constructor(data:IErrorMessage= {AdditionInfo:null,FieldName:undefined, Message:"", ErrorType:ErrorType.Error}){
        this.Key= Math.random().toString(36).substr(2, 5);        
        this.FieldName=data.FieldName;
        this.Message=data.Message;
        this.AdditionInfo=data.AdditionInfo;
        this.ErrorType=data.ErrorType;
        //console.log(this.Key)
    }
}

