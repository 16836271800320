import React  from 'react';
import {Col, Row, Breadcrumb, Button, Spinner, Modal } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
//import Pagination from "../../Core/Pagination";
import WishlistDataAccess from '../../DataAccess/WishlistDataAccess';
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import WishlistViewModel from '../../ViewModels/Wishlist/WishlistViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import { BaseLoader } from '../Core/BaseView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, withRouter } from 'react-router-dom';

class WishlistPage extends BaseComponent<any,WishlistViewModel> {
  constructor(props:any) {
    super(props);
    this.state = new BaseState<WishlistViewModel>(new WishlistViewModel());
    this.state.Model.loading = true;
  }
	
	componentDidMount(){
		this.getWishlist();
	}
	
	getWishlist(){
		WishlistDataAccess.GetWishlist((res: BaseResponse) => {
      if(!res.success){
				this.state.Model.loading = false;
				this.UpdateViewModel();
				return;
      }
			this.state.Model.loading = false;
			this.state.Model.wishlists = res.data.items;
			this.UpdateViewModel();
			
		});
		
	}
	
	removeWishlist = (item: any) => {
		this.state.Model.actionItem = item;
		this.state.Model.wishlistDeleteModal = true;
		this.UpdateViewModel();
	}
	
	removeWishlistProcess = () => {
		let model = this.state.Model;
		model.deleteProcessing = true;
		this.UpdateViewModel();
		
		WishlistDataAccess.RemoveWishlist(model.actionItem.product.id, (res: BaseResponse) => {
			if(!res.success){
				model.deleteProcessing = true;
				model.wishlistDeleteModal = false;
				this.UpdateViewModel();
				return;
			}
			model.deleteProcessing = true;
			model.wishlistDeleteModal = false;
			let wishlists = model.wishlists;
			wishlists.forEach((item:any, key:number) => {
				if(item.id === model.actionItem.id){
					wishlists.splice(key, 1);
				}
			})
			model.wishlists = wishlists;
			
			this.UpdateViewModel();
			this.UpdateWishlistCount(res.data.wishlist_count);
			this.ShowToast(res.message,"success");
			
		});
	}
	
	redirectUrl = (item: any) => {
		this.props.history.push("/category/" + item.slug);
	}
	
	handleCloseRemoveConfirm = () => {
		this.state.Model.wishlistDeleteModal = false;
		this.UpdateViewModel();
	}
  
	render(){
		let model = this.state.Model;
		
		return(
			<React.Fragment>
				<HeaderPage />
				
				<div className="container home">
					<section>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="breadcrumb-container">
									<Breadcrumb>
										<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
										<Breadcrumb.Item active>Wishlist</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</Col>
							<div className="mywishlist-area">
								{
									model.loading ?
									<Col xs={12} sm={12} md={12} lg={12}>
										<div className="container_loader">
											<BaseLoader/>
										</div>
									</Col>
									:
									<Col xs={12} sm={12} md={12} lg={12} style={{padding: '0px'}} className="position_relative">
										<div className="header-mywishlist"> My Wishlist {model.wishlists.length ? <span>({model.wishlists.length})</span> : null } </div>
										{
											model.wishlists.map((item: any, key: number) => {
											return <Row key={key}>
												<Col xs={12} sm={2} md={2} lg={2}>
													<div className="wishlist_single_image_area">
														<Link to={"/products/" + item.product.slug} className="image">
															<img src={item.product.media} className="img-fluid" alt={item.product.name} />
														</Link>
													</div>
												</Col>
												<Col xs={12} sm={10} md={10} lg={10}>
													<div className="wishlist_desription_product">
														<div className="wishlist_description_area">
															<div className="mywishlist_stock">In Stock</div>
															<div className="wishlist_product_area">{item.product.name}</div>
															<div className="wishlist_product_price_area">
																<h1>{ this.priceDisplay('Rs. ', item.product.sale_price) } 
																	{
																		item.product.price !== item.product.sale_price ?
																		<span>{ this.priceDisplay('Rs. ', item.product.price) }</span>
																		: null
																	}
																</h1>
															</div>
														</div>
													<div className="wishlist_product_del_area">
														<div className="del-icon">
															<Button variant="danger" onClick={() => this.removeWishlist(item)}>
																<FontAwesomeIcon icon="trash" />
															</Button>
														</div>
													</div>
												</div>
											</Col>
										</Row>
										})
										}
										
									</Col>
								}
							</div>
						</Row>
					</section>
					
					<Modal
						show={model.wishlistDeleteModal}
						onHide={() => this.handleCloseRemoveConfirm()}
						keyboard={false}
						className="cart_remove_item_modal"
						centered
					>
						<Modal.Header closeButton>
							<Modal.Title>Warning</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p>Are you sure to delete?</p>
							<div className="text-right">
								<Button variant="primary" onClick={() => this.removeWishlistProcess()} disabled={model.deleteProcessing}>
									{
										model.deleteProcessing ?
										<Spinner animation="border" />
										:
										"Yes"
									}
								</Button>
								<Button variant="default" onClick={() => this.handleCloseRemoveConfirm()}>No</Button>
							</div>
						</Modal.Body>
					</Modal>
					
				</div>
				
				<FooterPage />
				
			</React.Fragment>
    );
  }
}

export default withRouter(WishlistPage)
