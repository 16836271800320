import BaseViewModel from "../../Core/BaseViewModel";
import CustomerAddressModel from "../../Models/CustomerAddressModel";

export default class CustomerAddressUpdateViewModel extends  BaseViewModel{
    addresses:CustomerAddressModel[]=[];

    id:number=0;
    default:number=1;
    address_processing:boolean=false;
    name:string="";
    mobile:string="";
    street:string="";
    street2:string="";
    city:string="";
    state:string="";
    country:string="";
    zip:string="";
    landmark:string="";


    //form validation
    ErrorZip:String="";
    ErrorName:String="";
    ErrorMobile:String="";
    ErrorCity:String="";
    ErrorState:String="";
    ErrorLandmark:String="";
    ErrorStreet:String="";
    ErrorCountry:string="";

    loading: boolean = false;
    selected_index:number = 0;
}