import React  from 'react';
import { Form,  Button, Spinner, Col, Alert, Row } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import { BaseLoader } from '../Core/BaseView';
import CustomerDataAccess from '../../DataAccess/CustomerDataAccess';
import ResetPassowordViewModel from '../../ViewModels/Customer/ResetPassowordViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import PageNotFoundContent from '../Core/PageNotFoundContent';
import { withRouter } from 'react-router-dom';

class ResetPasswordPage extends BaseComponent<any,ResetPassowordViewModel> {
  constructor(props:any) {
    super(props);
    var model = new ResetPassowordViewModel();
	this.state = new BaseState(Object.assign(model,this.Auth));
	this.state.Model.loading=true;
  }

  componentDidMount(){
	this.verifyToken();
  }

  verifyToken = () => {
	var model = this.state.Model;  
	let query = new URLSearchParams(this.props.location.search);
	model.access_token = query.get('token');
	
	let params = {token: model.access_token};

	CustomerDataAccess.VerifyToken(params,(res: BaseResponse) => {
		model.loading=false;
		if(!res.success){
			this.UpdateViewModel();
		}
		else{
			model.valid_token = res.data.validToken;
			this.UpdateViewModel();
		}
	});
  }

  
	
  submitPassword = async (event:any) => {

	event.preventDefault();
	
	if(!this.ValidateFrom() || this.state.Model.pwd_processing){
		return false;
	}

	var model = this.state.Model;
	this.state.Model.pwd_processing=true;
	this.UpdateViewModel();
	
	CustomerDataAccess.ChangePassword(model,(res: BaseResponse) => {
		model.pwd_processing=false;
		if(!res.success){
			//this.UpdateViewModel();
			//return this.ShowToast(res.message,"warning");
		}
		else{
			model.isSuccess = res.message;
			model.password = '';
			model.confirm_password = '';
			this.UpdateViewModel();
			this.props.history.push("/login");
			//return this.ShowToast(res.message,"success");
		}
	});
}

ValidateFrom(){
	var model = this.state.Model; 
	
	var status=true;

	if(!model.password){
		model.ErrorPassword="Password is required";
		status=false;
	} 
	else if(!model.confirm_password){
		model.ErrorConfirmPassword="Confirm Password is required";
		status=false;
	} 
	else if(model.password!==model.confirm_password){
		model.ErrorPassword="Password not match";
		status=false;
	}
	else{
		model.ErrorPassword="";
		model.ErrorConfirmPassword="";
	} 

	this.UpdateViewModel();
	return status?true:false;
}


render(){
	let model = this.state.Model;
	
	return(
		<React.Fragment>
			<HeaderPage />
			
			<div className="reset_password_area">
				<div className="container">
				{
                       (model.loading) ?
                          <div className="container_loader">
					   				<BaseLoader/>
						  </div>
						: 
						 <Row>
								<React.Fragment>
								{
									(model.valid_token) ?
									
									  <Col md={{span: 8, offset: 2}}>
											<div className="reset_password_box_area">
												<div className="cart_header">
													<h1>Reset Password</h1>
												</div>
												
												<Form className="reset_password_form" onSubmit={this.submitPassword}>
												 {
													model.isSuccess ?
													<Alert variant="success">
													{model.isSuccess}
													</Alert>
													: null
												 }
													<Row>
														<Col xs={12} sm={12} md={12} lg={12} xl={12}>
															<Form.Group controlId="password">
																<Form.Label>Password</Form.Label>
																<Form.Control type="password"
																	placeholder="Enter Password"
																	name="password"
																	value={model.password}
																	onChange={this.SetModelValue} />
																{model.ErrorPassword ? <Form.Text className="text-danger">{model.ErrorPassword}</Form.Text> : null}
															</Form.Group>
														</Col>
													</Row>
													<Row>
														<Col xs={12} sm={12} md={12} lg={12} xl={12}>
															<Form.Group controlId="confirm_password">
																<Form.Label>Confirm Password</Form.Label>
																<Form.Control type="password"
																	placeholder="Enter Confirm Password"
																	name="confirm_password"
																	value={model.confirm_password}
																	onChange={this.SetModelValue} />
																{model.ErrorConfirmPassword ? <Form.Text className="text-danger">{model.ErrorConfirmPassword}</Form.Text> : null}
															</Form.Group>
														</Col>
													</Row>

													<Row>
														<Col xs={12} md={6} sm={6} lg={6} xl={6}>
														<div>
																<Button variant="primary" type="submit" >
																	{
																	model.pwd_processing ? 
																	<div>
																		<Spinner
																		as="span"
																		animation="border"
																		size="sm"
																		role="status"
																		aria-hidden="true"
																		/>&nbsp;Processing...
																	</div>
																	:
																	"Submit"
																	}
																	</Button>
															</div>
														</Col>
													</Row>
												</Form>
											</div>
										</Col>
										:
										<Col md={{span: 8, offset: 2}}>
											<PageNotFoundContent/>
								     	</Col>
								}
								</React.Fragment>
							</Row> 
						}
				</div>
			</div>
			
			<FooterPage />
			
		</React.Fragment>
   );
  }
}

export default withRouter(ResetPasswordPage)
