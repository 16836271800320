import Axios, { AxiosError, AxiosResponse } from "axios";
import BaseResponse from "../Core/BaseResponse";
import SessionHelper from "./SessionHelper";
import UIHelper from "./UIHelper";
import { toast } from 'react-toastify';
export default class BaseApi {

  protected static BaseUrl: String = "https://api.godskart.com/api/front/";
  //protected static BaseUrl: String = "http://localhost/godskart/api/front/";
  protected static GetConfig(){
    const config = {
      headers: {
        'Authorization': 'Bearer '+ SessionHelper.GetSession()?.access_token
      }
    }

    return config;
  }


  protected static BaseURLPostRequest(url: string, model: any, callBack: any) {
    //var urlData=UIHelper.ModelConvertToUrl(model);
    
    Axios.post(this.BaseUrl + url, model,BaseApi.GetConfig()).then(res => {
      this.ProcessSucess(res, callBack);
    }).catch(error => {
      this.ProcessFailure(error, callBack);
    });
  }

  protected static BaseURLPutRequest(url: string, model: any, callBack: any) {
    //var urlData=UIHelper.ModelConvertToUrl(model);
    Axios.put(this.BaseUrl + url, model,BaseApi.GetConfig()).then(res => {
      this.ProcessSucess(res, callBack);
    }).catch(error => {
      this.ProcessFailure(error, callBack);
    });
  }

  protected static BaseURLDeleteRequest(url: string, model: any, callBack: any) {
    var urlData=UIHelper.ModelConvertToUrl(model);
    Axios.delete(this.BaseUrl + url+urlData,BaseApi.GetConfig()).then(res => {
      this.ProcessSucess(res, callBack);
    }).catch(error => {
      this.ProcessFailure(error, callBack);
    });
  }


  protected static BaseURLGetRequest(url: string, model: any, callBack: any) {
    var urlData=UIHelper.ModelConvertToUrl(model);
    Axios.get(this.BaseUrl + url+urlData,BaseApi.GetConfig()).then(res => {
      this.ProcessSucess(res, callBack);
    }).catch(error => {
      this.ProcessFailure(error, callBack);
    });
  }



  protected static BasePostRequest(url: string, model: any, callBack: any) {
    Axios.post(this.BaseUrl + url, model).then(res => {
      this.ProcessSucess(res, callBack);
    }).catch(error => {
      this.ProcessFailure(error, callBack);
    });
  }

  protected static BaseGetRequest(url: string, callBack: any) {
    Axios.get(this.BaseUrl + url,BaseApi.GetConfig()).then(res => {
      this.ProcessSucess(res, callBack);
    }).catch(error => {
      this.ProcessFailure(error, callBack);
    });
  }

  private static ProcessSucess(res: AxiosResponse, callBack: any) {
    let r = res.data as BaseResponse;
    r.success = (r as any).success;
    callBack(r);
  }


  private static ProcessFailure(error: AxiosError, callBack: any) {
    let response = new BaseResponse({ success: false, data: undefined, message: error.message });
    response.message = error.message;
		if(error.response){
			if(error.response.status === 401){
				SessionHelper.SetSession(null as any);
				toast.error('You have been logged out due to inactivity.');
				setTimeout(() => {
					window.location.href="/";
				}, 1500);
				return;
			}else{
				callBack(response);
			}
		}else{
			callBack(response);
		}
  }
}