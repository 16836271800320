import React  from 'react';
import { Container} from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import { BaseLoader } from '../Core/BaseView';
import CustomerEventDataAccess from '../../DataAccess/CustomerEventDataAccess';
import CustomerEventListViewModel from '../../ViewModels/CustomerEvents/CustomerEventsListViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';

export default class EventDetailsViewPage extends BaseComponent<any, CustomerEventListViewModel> {

  constructor(props:any) {
		super(props);
		this.state = new BaseState<CustomerEventListViewModel>(new CustomerEventListViewModel());
	    this.state.Model.loading = true;
  }


  componentDidMount(){
    this.getEvents();
   }
	
	getEvents = () => {
		let params = this.props.match.params;
	
		 CustomerEventDataAccess.GetEventDetails(params.slug, (res: BaseResponse) => {
			this.state.Model.loading = false;
			if(!res.success){
				this.UpdateViewModel();
				return;
			}
			this.state.Model.events = res.data.events;
			this.UpdateViewModel();
		});
	}


	render(){
		let model = this.state.Model;
		
		return(
			<div>
			<HeaderPage/>
	  <div>
	  <br/>
		   {
		   (model.loading) ?
			  <div>
				<BaseLoader/>
			  </div>
			  : 
			  <React.Fragment>
				  <Container>
						  <div className="new_products_container" style={{padding:'0px'}}>
						    <div className="new_products_row">
							 <div className="new_product_outer">
										{
											model.events.map((item:any, key:number) => {
											return <div key={key}>
													<div className="events_inner_container">
													<div className="row">
														<div className="col-md-4">
															{
																item.media ?
																<div className="allevents-single-image">
																	<img src={item.media} alt="godskart"/>
																</div>
																: null
															}
														</div>
														<div className="col-md-8">
															<div className="event_description">
																<h1>{item.name}</h1>
																<p> Event Start Time: {item.event_date} { item.start_time } </p>
																<p> Event End Time: {item.event_date} { item.end_time } </p>
																<p> Event Description: {item.description} </p>
															</div>
														</div>
													</div>
												</div>
												<br />
												</div>
											})
										}
										
										{
											!model.events.length ?
											<p>Event not found</p>
											: null
										}
										
									</div>
								</div>
							</div>
				</Container>
			  </React.Fragment>
		   }
		  </div> 
		  <FooterPage />
	  </div>
		);
	}
}
