import React  from 'react';
import {Button, Spinner } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseState from '../../Core/BaseState';
import BaseResponse from '../../Core/BaseResponse';
import SlidesViewModel from '../../ViewModels/Home/SlidesViewModel';
import HomeDataAccess from '../../DataAccess/HomeDataAccess';
import OfferDataAccess from '../../DataAccess/OfferDataAccess';
//import { BaseLoader } from '../Core/BaseView';
import OwlCarousel from 'react-owl-carousel';
import { Link, withRouter } from "react-router-dom";
import banner_grid_1 from '../../Assets/Images/banner-grid-01.jpg';
import banner_grid_2 from '../../Assets/Images/banner-grid-02.jpg';
import banner_grid_3 from '../../Assets/Images/banner-grid-03.jpg';
import banner_grid_4 from '../../Assets/Images/banner-grid-04.jpg';
import banner_grid_5 from '../../Assets/Images/banner-grid-05.jpg';
//import thumb_bn_special from '../../Assets/Images/thumb-bn-special-02.jpg';
import freeshipping from '../../Assets/Images/freeshipping.png';
import free from '../../Assets/Images/24X7.png';
import return_product from '../../Assets/Images/return.png';
type Props = {
	section?: any
} 

class Slides extends BaseComponent<any,SlidesViewModel> {
	private productAnimateIntervl: any;
  constructor(props:any) {
    super(props);
    this.state = new BaseState<SlidesViewModel>(new SlidesViewModel());
		this.state.Model.section = 'section' in this.props ? this.props.section : null;
		this.state.Model.loading = true;
  }
	
	componentDidMount(){
		this.slideList();
	}
	
	componentWillUnmount(){
		clearInterval(this.productAnimateIntervl);
	}
	
	slideList(){
		let model = this.state.Model;
		let params = {
			section_name: model.section.section_name
		}
		HomeDataAccess.GetSlides(params,(res: BaseResponse) => {
      this.state.Model.loading = false;
      if(!res.success){
				this.UpdateViewModel();
				return;
      }
			this.state.Model.slides = res.data
			this.UpdateViewModel();
			
		});
		
		OfferDataAccess.GetOffers((res: BaseResponse) => {
      model.loading = false;
      if(!res.success){
				this.UpdateViewModel();
				return;
      }
			model.offers = res.data;
			
			this.setState({
				Model: model
			}, () => {
				this.productOfferAnimation();
			})
			
		});
	}
	
	productOfferAnimation = () => {
		if(this.productAnimateIntervl){
			clearInterval(this.productAnimateIntervl);
		}
		this.productAnimateIntervl = setInterval(() => {
			let model = this.state.Model;
			if(model.offers.length){
				let products = model.offers[0].products;
				let indx = model.active_product;
				if(indx >= (products.length - 1)){
					indx = 0;
				}else{
					indx = indx + 1;
				}
				this.state.Model.active_product = indx;
				this.UpdateViewModel();
				this.productOfferAnimation();
			}
			
		}, 4000);

	}
	
	offerRedirect = (item:any) => {
		this.props.history.push("/special-offers");
	}
  
	render(){
		let model = this.state.Model;
		
		if(model.loading){
			return (
				<div className="container_loader">
					<div className="each_item_loader" style={{backgroundColor: 'unset', opacity: 1}}>
						<div className="spinner_conent"><Spinner animation="border" variant="primary"/></div>
					</div>
				</div>
			)
		}
		
		if(!model.slides.length){
			return false;
		}
		
		return(
			<div className="home">
        <section className="new_products">
					<div className="slider_container">
						<OwlCarousel
							autoplay={true}
							className="owl-theme"
							loop
							margin={10}
							items={1}
							dots={true}
							nav={true}
							responsiveClass={true}
						>
						{
							model.slides.map((item: any, key:number) => {
              return <div key={key}>
								{
									item.media ?
									<div className="slider-banner">
										<img src={item.media} className="img-fluid" alt="puja" />
									</div>
									: null
								}
                <div className="slider-content">
                  <h2>Beautiful</h2>
                  <h1>Lord Ganesh</h1>
									<Button variant="primary">Shop Now</Button>
                </div>
              </div>
							})
						}
            </OwlCarousel>
          </div>
				<div className="service-provide">
				<div className="service-provider-container">
					<div className="service-provider-row">
						<div className="single-service-provider">
							<div className="service-style-image">
							<img src={freeshipping} alt="" />
							</div>
						<div className="service-style-image-content">
							<h1>Free Shipping</h1>
							<p>Free Shipping on all order<br></br> order above 499</p>
						</div>
						
						</div>
						<div className="single-service-provider">
							<div className="service-style-image">
							<img src={free} alt="" />
							</div>
						<div className="service-style-image-content">
							<h1>Support 24/7</h1>
							<p>Contact us 24 hours a day<br></br>7 days a weeek </p>
						</div>
						
						</div>
						<div className="single-service-provider">
							<div className="service-style-image">
							<img src={return_product} alt="" />
							</div>
						<div className="service-style-image-content">
							<h1>30 Days Return</h1>
							<p>Simply return it within 7<br></br>days for an exchange</p>
						</div>
						
						</div>
					</div>	
				</div>
					
				</div>

	<section className="godskart-category">
		<div className="style-container">
		<div className="puja_feature_heading">
			<h1> Explore Popular categories </h1>
		</div>
			<div className="style-row">
			<div className="single-style">
			<div className="single-style-image">
				<img src={banner_grid_1} alt="" />
				<div className="single-inner-text">
					<h1>Himalaya lamp</h1>
					<div className="shop_link">
					<Link to="/category/lamps">Shop Now</Link>
					</div>
					
				</div>
			</div>
			</div>
			<div className="single-style">
			<div className="single-style-image">
			<img src={banner_grid_2} alt="" />
				<div className="single-inner-text">
					<h1>Flower Boxes</h1>
					<div className="shop_link">
					<Link to="/category/flowers">Shop Now</Link>
					</div>
				</div>
			</div>
			</div>
			<div className="single-style">
			<div className="single-style-image">
			<img src={banner_grid_3} alt="" />
				<div className="single-inner-text">
					<h1>Rose Quartz</h1>
					<div className="shop_link">
					<Link to="/category/idols">Shop Now</Link>
					</div>
				</div>
			</div>
			</div>
			<div className="single-style">
			<div className="single-style-image">
			<img src={banner_grid_4} alt="" />
				<div className="single-inner-text">
					<h1>Idols</h1>
					<div className="shop_link">
					<Link to="/category/idols">Shop Now</Link>
					</div>
				</div>
			</div>
			</div>
			<div className="single-style">
			<div className="single-style-image">
			<img src={banner_grid_5} alt="" />
				<div className="single-inner-text">
					<h1>Home Decor</h1>
					<div className="shop_link">
					<Link to="/category/lamps">Shop Now</Link>
					</div>
				</div>
			</div>
			</div>
			<div className="single-style">
			<div className="single-style-image">
			<img src={banner_grid_1} alt="" />
				<div className="single-inner-text">
					<h1>Birthstone</h1>
					<div className="shop_link">
					<Link to="/category/idols">Shop Now</Link>
					</div>
				</div>
			</div>
			</div>
			</div>
		</div>
	</section>
          {/* <div className="category">
            <div className="container-fluid no-padd">
              <div className="row margin-0">
								<div className="col-12 col-sm-3 no-padd">
									<div className="max-fit-1">
										<img src={banner_grid_1} className="img-fluid" alt="" />
										<div className="category-image-button">Himalaya Lamp</div>
									</div>
								</div>
								<div className="col-12 col-sm-3 no-padd">
									<div className="max-fit-2">
										<img src={banner_grid_2} className="img-fluid" alt="" />
										<div className="category-image-button">Flower Boxes</div>
									</div>
									<div className="max-fit-2">
										<img src={banner_grid_3} className="img-fluid" alt="" />
										<div className="category-image-button">Roes Quartz</div>
									</div>
									</div>
								<div className="col-12 col-sm-3 no-padd">
									<div className="max-fit-2">
										<img src={banner_grid_4} className="img-fluid" alt="" />
										<div className="category-image-button">Idols</div>
									</div>
									<div className="max-fit-2">
										<img src={banner_grid_5} className="img-fluid" alt="" />
										<div className="category-image-button">Home Decor</div>
									</div>
								</div>
								<div className="col-12 col-sm-3 no-padd">
									<div className="max-fit-1">
										<img src={banner_grid_1} className="img-fluid" alt="" />
										<div className="category-image-button">Birthstone</div>
									</div>
								</div>
              </div>
            </div>
          </div> */}
					
					{
						model.offers.length ?
						<div className="offerbanner">
							<div className="offerbanner_container">
								<div className="offerbanner_row">
									<div className="single_offer">
										<div className="offerbanner-image">
											<img src={model.offers[0].media} alt="" />
										</div>
									</div>
									
									<div className="single_offer">
										<div className="text-content">
											<i className="text01">&nbsp;</i>
											<b className="text02">{model.offers[0].title}</b>
											<span className="text04"> <i>{model.offers[0].description}</i></span>
											{
												model.offers[0].products.map((item:any, key:number) => {
													return <div className={"offer_product-detail" + (key === model.active_product ? ' active' : ' hidden')} key={key}>
															<h4 className="product-name">{item.name}</h4>
															<div className="price price-contain">
															<span className="price-amount today_price"><span className="currencySymbol">₹</span>{item.sale_price}</span>
															<span className="price-amount past_price"><del><span className="currencySymbol">₹</span>{item.price}</del></span>
															</div>
															<p className="measure">per piece</p>
														</div>
												})
											}
											
											
											<div className="offer-buttons">
													<Button variant="primary" onClick={() => this.offerRedirect(model.offers[0])}>Shop Now</Button>
											</div>
										</div>
									</div>
									
									
								</div>
							</div>
						</div>
						: null
					}
					
				</section>
			</div>
    );
  }
}

export default withRouter(Slides)