import React  from 'react';
import { Col, Row, Breadcrumb, Form, Tab, Nav} from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import { BaseLoader } from '../Core/BaseView';
import SessionHelper from '../../Core/SessionHelper';
import CustomerEventDataAccess from '../../DataAccess/CustomerEventDataAccess';
import HomeDataAccess from '../../DataAccess/HomeDataAccess';
import CustomerEventListViewModel from '../../ViewModels/CustomerEvents/CustomerEventsListViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import { Link } from 'react-router-dom';

export default class CustomerEventListPage extends BaseComponent<any, CustomerEventListViewModel> {

  constructor(props:any) {
		super(props);
		SessionHelper.HandleAuthentication();
		this.state = new BaseState<CustomerEventListViewModel>(new CustomerEventListViewModel());
	  this.state.Model.loading = true;
  }


  componentDidMount(){
		if(!this.Auth){
			return null;
		}
    this.getEvents();
    this.getCommunities();
  }
	
	getEvents = () => {
		let params = {
			community_id: this.state.Model.community_id
		}
		CustomerEventDataAccess.GetEventList(params, (res: BaseResponse) => {
      this.state.Model.loading = false;
      if(!res.success){
				this.UpdateViewModel();
				return;
      }
			this.state.Model.events = res.data.events;
			this.state.Model.specialEvents = res.data.special_events;
			this.UpdateViewModel();
			
		});
	}
  getCommunities = () => {
		HomeDataAccess.GetCommunities((res: BaseResponse) => {
      if(!res.success){
				this.UpdateViewModel();
				return;
      }
			this.state.Model.communities = res.data.items;
			this.UpdateViewModel();
			
		});
		
	}
	
	onComminityChange = (event:any) => {
		const { target: { value } } = event;
		let model = this.state.Model;
		model.community_id = value;
		this.setState({
			Model: model
		}, () => {
			this.getEvents();
		})
	}

  	
	render(){
		let model = this.state.Model;

		if(!this.Auth){
			return null;
		}
		
		return(
			<React.Fragment>
				<HeaderPage />
				
				<div className="container">
					<section className="new_products">
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="breadcrumb-container">
									<Breadcrumb>
										<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
										<Breadcrumb.Item active>Events</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</Col>
							{
								model.loading ?
								<Col xs={12} sm={12} md={12} lg={12}>
									<div className="container_loader">
										<BaseLoader/>
									</div>
								</Col>
								:
								<Col xs={12} sm={12} md={12} lg={12}>
									<div className="events_tab_container">
										<div className="new_products_row">
											<Form.Group controlId="community_id" className="community_list_container">
												<Form.Control as="select" onChange={this.onComminityChange}>
													<option value="">All Community</option>
													{
														model.communities.map((item:any, key:number) => {
															return <option value={item.id} key={key}>{item.name}</option>
														})
													}
												</Form.Control>
											</Form.Group>
										</div>
										<Tab.Container defaultActiveKey="events">
											<Row>
												<Col sm={12}>
													<Nav variant="pills" className="">
														<Nav.Item>
															<Nav.Link eventKey="events">Events</Nav.Link>
														</Nav.Item>
														<Nav.Item>
															<Nav.Link eventKey="specialEvents">Special Events</Nav.Link>
														</Nav.Item>
													</Nav>
												</Col>
												<Col sm={12}>
													<Tab.Content>
														<Tab.Pane eventKey="events">
															<div className="new_products_container" style={{padding:'0px'}}>
																<div className="new_products_row">
																	<div className="new_product_outer">
																		{
																			model.events.map((item:any, key:number) => {
																			return <div key={key}>

																					<div className="events_inner_container">
																					<Link to={"/events/" + item.slug} className="image link-to-product">
																						<div className="row">
																							<div className="col-md-4">
																								{
																									item.media ?
																									<div className="allevents-single-image">
																										<img src={item.media} alt="godskart"/>
																									</div>
																									: null
																								}
																							</div>
																							<div className="col-md-8">
																								<div className="event_description">
																									<h1>{item.name}</h1>
																									<p> Event Start Time: {item.event_date} { item.start_time } </p>
																									<p> Event End Time: {item.event_date} { item.end_time } </p>
																									<p> Event Description: {item.description} </p>
																								</div>
																							</div>
																						</div>
																					 </Link>
																				</div>
																				<br />
																			 </div>
																			})
																		}
																		
																		{
																			!model.events.length ?
																			<p>Event not found</p>
																			: null
																		}
																		
																	</div>
																</div>
															</div>
														</Tab.Pane>
														<Tab.Pane eventKey="specialEvents">
															<div className="new_products_container" style={{padding:'0px'}}>
																<div className="new_products_row">
																	<div className="new_product_outer">
																		{
																			model.specialEvents.map((item:any, key:number) => {
																			return <div key={key}>
																					<div className="events_inner_container">
																					<Link to={"/events/" + item.slug} className="image link-to-product">
																					<div className="row">
																						<div className="col-md-4">
																							{
																								item.media ?
																								<div className="allevents-single-image">
																									<img src={item.media} alt="godskart"/>
																								</div>
																								: null
																							}
																						</div>
																						<div className="col-md-8">
																							<div className="event_description">
																								<h1>{item.name}</h1>
																								<p> Event Start Time: {item.event_date} { item.start_time } </p>
																								<p> Event End Time: {item.event_date} { item.end_time } </p>
																								<p> Event Description: {item.description} </p>
																							</div>
																						</div>
																					</div>
																					</Link>
																				</div>
																				<br />
																			 </div>
																			})
																		}
																		
																		{
																			!model.specialEvents.length ?
																			<p>Special Event not found</p>
																			: null
																		}
																		
																	</div>
																</div>
															</div>
														</Tab.Pane>
													</Tab.Content>
												</Col>
											</Row>
										</Tab.Container>
									</div>
								</Col>
							}
						</Row>
					</section>
				</div>
				
				<FooterPage />
			</React.Fragment>
		);
	}
}
