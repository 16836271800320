import BaseViewModel from "../../Core/BaseViewModel";
//import TicketModel from "../../Models/TicketModel";

export default class TicketDetailsViewModel extends  BaseViewModel{
	loading: boolean = false;
	ticket: any;
	messages: any=[];
	id: any;
	orderid: any;
	message: any;
	onProcess: boolean = false;
	formErrors:any = {};
	isSuccess: string="";
	file: any;
}