import BaseViewModel from "../../Core/BaseViewModel";
import CategoryModel from "../../Models/CategoryModel";
export default class HeaderViewModel extends BaseViewModel{
  ViewType?:ViewType;
	categories: CategoryModel[] = [];
	currentPage: string = "";
	search: string = "";
	searchType: string = "";
	autocompleteItems: autocomplete[] = [];
	autocompleteSearchData: autocomplete[] = [];
	navbarShow: any = [];
}

export enum ViewType{
	Login,
	Register
}

export class autocomplete{
	label: string = "";
	type: string = "";
}