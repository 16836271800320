import BaseApi from "../Core/BaseApi";
import UIHelper from "../Core/UIHelper";

export default class CustomerOrderDataAccess extends BaseApi{
	
	public static Create(model:any,callback:any){
	 	this.BaseURLPostRequest("customer/orders",model,callback);
	}

	public static CreateBuyNowOrder(model:any,callback:any){
		this.BaseURLPostRequest("customer/checkouts",model,callback);
    }

	public static GetBuyNowOrderData(model:any,callback:any){
		this.BaseURLPostRequest("customer/checkouts/get-order-data",model,callback);
	}
	
	public static PaymentVerifyBuyNowOrder(model:any,callback:any){
		this.BaseURLPostRequest("customer/checkouts/payment-verify",model,callback);
	}
	
	public static UpdatePaymentStatusBuyNowOrder(model:any,callback:any){
		this.BaseURLPostRequest("customer/checkouts/payment-status",model,callback);
   }
	
	public static PaymentVerify(model:any,callback:any){
	 	this.BaseURLPostRequest("customer/orders/payment-verify",model,callback);
	}
	
	public static UpdatePaymentStatus(model:any,callback:any){
	 	this.BaseURLPostRequest("customer/payment-status",model,callback);
	}
	
	public static GetList(params:any, callback:any){
		var urlData = UIHelper.ModelConvertToUrl(params);
		this.BaseURLGetRequest("customer/orders" + urlData, {}, callback);
	}
	
	public static CancelOrder(model:any,callback:any){
		this.BaseURLPostRequest("customer/cancel-order",model,callback);
	}
	
	public static OrderGenerate(model:any,callback:any){
		this.BaseURLPostRequest("customer/orders/order-generate",model,callback);
	}
	
	public static OrderView(id:number,callback:any){
		this.BaseURLGetRequest("customer/orders/" + id, {}, callback);
	}

	public static checkTicket(id:any, callback:any){
		this.BaseURLPostRequest("customer/tickets/check-ticket/" + id, {}, callback);
	}
	
	public static createTicket(params:any, callback:any){
		this.BaseURLPostRequest("customer/tickets", params, callback);
	}
	
	public static getTickets(params:any, callback:any){
		this.BaseURLGetRequest("customer/tickets", params, callback);
	}

	public static getTicketDetails(id:any, callback:any){
		this.BaseURLGetRequest("customer/tickets/details/"+ id, {}, callback);
	}

	public static getTicketMessages(id:any, callback:any){
		this.BaseURLGetRequest("customer/tickets/"+ id, {}, callback);
	}

	public static createMessage(id:any, params:any, callback:any){
		this.BaseURLPostRequest("customer/tickets/" + id, params, callback);
	}

}