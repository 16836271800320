import BaseApi from "../Core/BaseApi";

export default class CustomerCartDataAccess extends BaseApi{

    public static GetList(callback:any){
        this.BaseURLGetRequest("customer/carts", {}, callback);
    }

    public static Create(model:any,callback:any){
        this.BaseURLPostRequest("customer/carts",model,callback);
    }

    public static Update(model:any,callback:any){
        this.BaseURLPutRequest("customer/carts/" + model.id,model,callback);
    }

    public static Delete(id:number,callback:any){
        this.BaseURLDeleteRequest("customer/carts/" + id,{},callback);
    }

}