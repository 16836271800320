import React  from 'react';
import { Form, Button, Spinner, Col, Row, Alert } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseState from '../../Core/BaseState';
import BaseResponse from '../../Core/BaseResponse';
import SessionHelper from '../../Core/SessionHelper';
import CustomerDataAccess from '../../DataAccess/CustomerDataAccess';
import CustomerModel from '../../Models/CustomerModel';
import CustomerLoginViewModel from '../../ViewModels/Customer/CustomerLoginViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import woman from '../../Assets/Images/woman.jpg';
import {faFacebookF, faGoogle} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
//import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

class LoginPage extends BaseComponent<any,CustomerLoginViewModel> {
  constructor(props:any) {
		super(props)
		this.state = new BaseState<CustomerLoginViewModel>(new CustomerLoginViewModel());
  }
	
	Login = async (event:any) => {
    event.preventDefault();
		
		if(!this.ValidateFrom() || this.state.Model.onProcess){
      return false;
    }
		
    this.state.Model.onProcess = true;
    this.UpdateViewModel();
		
		let model = this.state.Model;
		let params = {
			mobile: model.mobile,
			password: model.password
		}
		
    CustomerDataAccess.Login(params,(res: BaseResponse) => {
      this.state.Model.onProcess = false;
      if(!res.success){
				this.state.Model.loginErrorMsg = res.message;
				this.UpdateViewModel();
				return;
        //return this.ShowToast(res.message,"warning");
      }
			this.UpdateViewModel();
			SessionHelper.SetSession(res.data as CustomerModel);
			this.ShowToast(res.message,"success");
			window.location.href = '/';
			
		});

  }
	
	ValidateFrom = () => {
		let model = this.state.Model;
		this.state.Model.loginErrorMsg = "";
		let formErrors:any = {};
		if(!model.mobile){
			formErrors.mobile = 'This field is required.'
		}
		if(!model.password){
			formErrors.password = 'This field is required.'
		}
		this.state.Model.formErrors = formErrors;
		this.UpdateViewModel();
		return Object.keys(formErrors).length === 0;
	}
	
	hasError = (key: string) => {
		let formErrors = this.state.Model.formErrors;
		return (key in formErrors && formErrors[key]);
	}
	
	showError = (key: string) => {
		let formErrors = this.state.Model.formErrors;
		return this.hasError(key) ? <Form.Text className="text-danger">{formErrors[key]}</Form.Text> : null;
	}
	
	handleFaceBookResponse = (response:any) => {
		console.log('facebook', response);
		if(typeof response === 'object' && response !== null){
			let params = {
				name: 'name' in response ? response.name : '',
				email: 'email' in response ? response.email : '',
				mobile: 'name' in response ? response.mobile : '',
				social_type: 'facebook',
				social_id: 'userID' in response ? response.userID : ''
			}
			this.state.Model.facebookBtnTxt = ' Processing...';
			this.UpdateViewModel();
			CustomerDataAccess.SocialLogin(params,(res: BaseResponse) => {
				this.state.Model.facebookBtnTxt = ' Facebook';
				if(!res.success){
					this.state.Model.loginErrorMsg = res.message;
					this.UpdateViewModel();
					return;
				}
				this.UpdateViewModel();
				SessionHelper.SetSession(res.data as CustomerModel);
				this.ShowToast(res.message,"success");
				window.location.href = '/';
				
			});
			
		}else{
			this.state.Model.loginErrorMsg = 'Login failed';
			this.UpdateViewModel();
		}
	}
	
	handleGoogleRespone = (response:any) => {
		console.log('google', response);
		if(typeof response === 'object' && response !== null && response.profileObj){
			let params = {
				name: 'name' in response.profileObj ? response.profileObj.name : '',
				email: 'email' in response.profileObj ? response.profileObj.email : '',
				mobile: 'name' in response.profileObj ? response.profileObj.mobile : '',
				social_type: 'google',
				social_id: 'googleId' in response ? response.googleId : ''
			}
			this.state.Model.googleBtnTxt = ' Processing...';
			this.UpdateViewModel();
			CustomerDataAccess.SocialLogin(params,(res: BaseResponse) => {
				this.state.Model.googleBtnTxt = 'Google';
				if(!res.success){
					this.state.Model.loginErrorMsg = res.message;
					this.UpdateViewModel();
					return;
				}
				this.UpdateViewModel();
				SessionHelper.SetSession(res.data as CustomerModel);
				this.ShowToast(res.message,"success");
				window.location.href = '/';
				
			});
			
		}else{
			if(!response.error){
				this.state.Model.loginErrorMsg = 'Login failed';
				this.UpdateViewModel();
			}
		}
	}
	
  render(){
		let model = this.state.Model;
		return(
			<React.Fragment>
			
				<HeaderPage />
				
				
				<div className="customer_login_area">
					<div className="container">
						<Row>
							<Col md={{span: 8, offset: 2}}>
								<Row className="customer_box_area">
									<Col xs={12} sm={4} md={4} lg={4} style={{padding: '0px'}}>
										<div className="login_image_container">
											<img src={woman} alt="" />
										</div>
									</Col>
									<Col xs={12} sm={8} md={8} lg={8} style={{padding: '35px'}}>
										<div className="login_form_content">
											{
												model.loginErrorMsg ?
												<Alert variant="danger">{model.loginErrorMsg}</Alert>
												: null
											}
											<div className="login_form_header">Login</div>
											<Form onSubmit={this.Login}>
												<Form.Group controlId="mobile">
													<Form.Label>Mobile</Form.Label>
													<Form.Control type="text"
														placeholder="Enter Phone Number"
														name="mobile"
														value={model.mobile}
														isInvalid={this.hasError('mobile')}
														onChange={this.SetModelValue}
													/>
													{this.showError('mobile')}
												</Form.Group>

												<Form.Group controlId="password">
													<Form.Label>Password</Form.Label>
													<Form.Control type="password"
														placeholder="Enter Password"
														name="password"
														value={model.password}
														isInvalid={this.hasError('password')}
														onChange={this.SetModelValue}
													/>
													{this.showError('password')}
												</Form.Group>

												<div className="form-group">
													<div className="login_container_btns">
														<Button variant="primary" type="submit">
															{
																model.onProcess ? 
																<Spinner animation="border" variant="light" />
																:
																"Login"
															}
														</Button>
														<Link to="register" className="btn btn-success">Sign up It's free</Link>
														<Link to="forget-password" className="btn btn-link">Forgot Your Password?</Link>
													</div>
												</div>
											</Form>
											<div className="login_social_icon">
												<FacebookLogin
													appId={this.FACEBOOK_CLIENT_ID}
													fields="name,email,picture"
													callback={this.handleFaceBookResponse} 
													icon={<FontAwesomeIcon icon={faFacebookF} />}
													textButton={model.facebookBtnTxt}
													disableMobileRedirect={true}
													size="small"
													cssClass="btn btn-facebook bg-primary text-light"
												/>
												<GoogleLogin
													clientId={this.GOOGLE_CLIENT_ID}
													render={renderProps => (
														<button onClick={renderProps.onClick} className="btn btn-google bg-danger text-light"><FontAwesomeIcon icon={faGoogle} /> {model.googleBtnTxt}</button>
													)}
													onSuccess={this.handleGoogleRespone}
													onFailure={this.handleGoogleRespone}
													cookiePolicy={'single_host_origin'}
												/>
												{/*<a href="#" className="btn btn-twitter bg-info text-light"><FontAwesomeIcon icon={faTwitter} /> twitter</a>*/}
											</div>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</div>
				
				<FooterPage />
				
			</React.Fragment>
		);
  }
}
 
export default LoginPage
