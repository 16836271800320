import BaseApi from "../Core/BaseApi";
import UIHelper from "../Core/UIHelper";

export default class CustomerDataAccess extends BaseApi{

    public static SignUp(Model:any,callback:any){
        this.BaseURLPostRequest("customer/signup",Model,callback);
    }

    public static Login(Model:any,callback:any){
        this.BaseURLPostRequest("customer/login",Model,callback);
    }
		
		public static SocialLogin(Model:any,callback:any){
        this.BaseURLPostRequest("customer/social-login",Model,callback);
    }

    public static EditProfile(Model:any,callback:any){
        this.BaseURLPostRequest("customer/user/edit-profile",Model,callback);
    }

    public static ChangePassword(Model:any,callback:any){
        this.BaseURLPostRequest("customer/reset-password",Model,callback);
    }

    public static ForgetPassword(Model:any,callback:any){
        this.BaseURLPostRequest("customer/forgot-password",Model,callback);
    }

    public static ContactUs(model:any,callback:any){
		this.BaseURLPostRequest("customer/support",model,callback);
    }
    
    public static VerifyToken(params:any, callback:any){
		var urlData = UIHelper.ModelConvertToUrl(params);
		this.BaseURLGetRequest("customer/verify-token" + urlData, {}, callback);
	}

}