import BaseApi from "../Core/BaseApi";

export default class WishlistDataAccess extends BaseApi{

  public static GetWishlist(callback:any){
		this.BaseURLGetRequest("customer/wishlists", {}, callback);
	}
	
	public static AddWishlist(model:any,callback:any){
		this.BaseURLPostRequest("customer/wishlists",model,callback);
	}
	
	public static RemoveWishlist(id:number,callback:any){
		this.BaseURLDeleteRequest("customer/wishlists/" + id,{},callback);
	}
	
}