import BaseViewModel from "../../Core/BaseViewModel";
import ProductModel from "../../Models/ProductModel";


export default class CustomerCartListViewModel extends  BaseViewModel{
	carts:any;
	product_id:number=0;
	quantity:number=0;
	vendor_id:number=0;
	address_id :number=0;
	cartLoading: boolean=false;
	promo: string = "";
	products: ProductModel[] = [];
	cartRemoveModal: boolean = false;
	actionItem: any;
	itemsLoading: Array<number> = [];
}