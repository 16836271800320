import BaseViewModel from "../../Core/BaseViewModel";
import CustomerAddressModel from "../../Models/CustomerAddressModel";

export default class CustomerBuyNowViewModel extends  BaseViewModel{
	product:any;
	address_id :number=0;
	select_address: number=0;
	addresses: CustomerAddressModel[] = [];
	selected_method: string = "";
	deducted_amount:number=0;
	order_processing: boolean=false;
	slug: any = "";
	quantity:any=0;
	amount: number =0;
	total: number =0;
	cgst: number =0;
	sgst: number =0;
	igst: number =0;
	shipping_charge: number =0;

}