import React  from 'react';
import { Form, Button, Spinner, Col, Row } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseState from '../../Core/BaseState';
import BaseResponse from '../../Core/BaseResponse';
import SessionHelper from '../../Core/SessionHelper';
import CustomerDataAccess from '../../DataAccess/CustomerDataAccess';
import CustomerModel from '../../Models/CustomerModel';
import CustomerRegisterViewModel from '../../ViewModels/Customer/CustomerRegisterViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
// import woman from '../../Assets/Images/woman.jpg';
// import {faFacebookF, faTwitter, faGoogle} from "@fortawesome/free-brands-svg-icons";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Link } from "react-router-dom";

class RegisterPage extends BaseComponent<any,CustomerRegisterViewModel> {
  constructor(props:any) {
		super(props)
		this.state = new BaseState<CustomerRegisterViewModel>(new CustomerRegisterViewModel());
  }
	
	onSubmit = async (event:any) => {
    event.preventDefault();
		
		if(!this.ValidateFrom() || this.state.Model.onProcess){
      return false;
    }
		
    this.state.Model.onProcess = true;
    this.UpdateViewModel();
		
		let model = this.state.Model;
		let params = {
			first_name: model.first_name,
			last_name: model.last_name,
			email: model.email,
			mobile: model.mobile,
			password: model.password,
			referral_code: model.referral_code,
		}
		
    CustomerDataAccess.SignUp(params,(res: BaseResponse) => {
      this.state.Model.onProcess = false;
      if(!res.success){
				try {
					let errArr = JSON.parse(res.message);
					let formErrors:any = {};
					if('first_name' in errArr){
						formErrors.first_name = errArr.first_name[0];
					}
					if('last_name' in errArr){
						formErrors.last_name = errArr.last_name[0];
					}
					if('email' in errArr){
						formErrors.email = errArr.email[0];
					}
					if('mobile' in errArr){
						formErrors.mobile = errArr.mobile[0];
					}
					if('password' in errArr){
						formErrors.password = errArr.password[0];
					}
					this.state.Model.formErrors = formErrors;
					this.UpdateViewModel();
					return;
				} catch (e) {
					this.UpdateViewModel();
					return this.ShowToast(res.message,"success");
				}
				
			}
			SessionHelper.SetSession(res.data as CustomerModel);
			this.ShowToast(res.message,"success");
			window.location.href="/";
			
		});

  }
	
	ValidateFrom = () => {
		let model = this.state.Model;
		let formErrors:any = {};
		if(!model.first_name){
			formErrors.first_name = 'This field is required.'
		}
		if(!model.last_name){
			formErrors.last_name = 'This field is required.'
		}
		if(!model.email){
			formErrors.email = 'This field is required.'
		}
		if(!model.mobile){
			formErrors.mobile = 'This field is required.'
		}
		if(!model.password){
			formErrors.password = 'This field is required.'
		}
		this.state.Model.formErrors = formErrors;
		this.UpdateViewModel();
		return Object.keys(formErrors).length === 0;
	}
	
	hasError = (key: string) => {
		let formErrors = this.state.Model.formErrors;
		return (key in formErrors && formErrors[key]);
	}
	
	showError = (key: string) => {
		let formErrors = this.state.Model.formErrors;
		return this.hasError(key) ? <Form.Text className="text-danger">{formErrors[key]}</Form.Text> : null;
	}
	
  render(){
		let model = this.state.Model;
		return(
			<React.Fragment>
			
				<HeaderPage />
				
				
				<div className="customer_register_area">
					<div className="container" >
						<Row>
							<Col md={{span: 8, offset: 2}}>
								<div className="customer_box_area" style={{padding: '30px'}}>
									<Form onSubmit={this.onSubmit}>
										<Row>
											<Col xs={12} sm={6} md={6} lg={6} xl={6}>
												<Form.Group controlId="first_name">
													<Form.Label>First Name</Form.Label>
													<Form.Control type="text"
														placeholder="First name"
														name="first_name"
														value={model.first_name}
														isInvalid={this.hasError('first_name')}
														onChange={this.SetModelValue}
													/>
													{this.showError('first_name')}
												</Form.Group>
											</Col>
											<Col xs={12} sm={6} md={6} lg={6} xl={6}>
												<Form.Group controlId="last_name">
													<Form.Label>Last name</Form.Label>
													<Form.Control type="text"
														placeholder="Last name"
														name="last_name"
														value={model.last_name}
														isInvalid={this.hasError('last_name')}
														onChange={this.SetModelValue}
													/>
													{this.showError('last_name')}
												</Form.Group>
											</Col>
											<Col xs={12} sm={6} md={6} lg={6} xl={6}>
												<Form.Group controlId="mobile">
													<Form.Label>Mobile Number</Form.Label>
													<Form.Control type="text"
														placeholder="Mobile Number"
														name="mobile"
														value={model.mobile}
														isInvalid={this.hasError('mobile')}
														onChange={this.SetModelValue}
													/>
													{this.showError('mobile')}
												</Form.Group>
											</Col>
											<Col xs={12} sm={6} md={6} lg={6} xl={6}>
												<Form.Group controlId="email">
													<Form.Label>Email Address</Form.Label>
													<Form.Control type="text"
														placeholder="Email Address"
														name="email"
														value={model.email}
														isInvalid={this.hasError('email')}
														onChange={this.SetModelValue}
													/>
													{this.showError('email')}
												</Form.Group>
											</Col>
											<Col xs={12} sm={6} md={6} lg={6} xl={6}>
												<Form.Group controlId="password">
													<Form.Label>Password</Form.Label>
													<Form.Control type="password"
														placeholder="Password"
														name="password"
														value={model.password}
														isInvalid={this.hasError('password')}
														onChange={this.SetModelValue}
													/>
													{this.showError('password')}
												</Form.Group>
											</Col>
											<Col xs={12} sm={6} md={6} lg={6} xl={6}>
												<Form.Group controlId="referral_code">
													<Form.Label>Referral Code</Form.Label>
													<Form.Control type="text"
														placeholder="Referral Code"
														name="referral_code"
														value={model.referral_code}
														isInvalid={this.hasError('referral_code')}
														onChange={this.SetModelValue}
													/>
													{this.showError('referral_code')}
												</Form.Group>
											</Col>
										</Row>

										<Row>
											<Col lg={12} className="text-right">
												<div className="register_signup_btn">
													<Button variant="primary" type="submit">
														{
															model.onProcess ? 
															<Spinner animation="border" variant="light" />
															:
															"Sign Up"
														}
													</Button>
												</div>
											</Col>
										</Row>
									</Form>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				
				<FooterPage />
				
			</React.Fragment>
		);
  }
}
 
export default RegisterPage
