import React  from 'react';
import { Col, Container, Row, Button, Alert } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import { BaseLoader } from '../Core/BaseView';
import SessionHelper from '../../Core/SessionHelper';
import CustomerOrderDataAccess from '../../DataAccess/CustomerOrderDataAccess';
import NotificationDataAccess from '../../DataAccess/NotificationDataAccess';
import CustomerOrderListViewModel from '../../ViewModels/CustomerOrder/CustomerOrderListViewModel';
import CustomerOrderModel from "../../Models/CustomerOrderModel";
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import Pagination from "../../Core/Pagination";

export default class CustomerOrderListPage extends BaseComponent<any, CustomerOrderListViewModel> {

  constructor(props:any) {
		super(props);
		SessionHelper.HandleAuthentication();
		this.state = new BaseState<CustomerOrderListViewModel>(new CustomerOrderListViewModel());
	  this.state.Model.loading = true;
  }


  componentDidMount(){
		if(!this.Auth){
			return null;
		}
		this.BindData();
  }


  BindData(){
    this.GetList();
  }

	priceDisplay(price: any){
		price = Number.parseFloat(price).toFixed(2)
		return price;
  }


  GetList = async  () => {
		let params = {
			page: this.state.Model.current_page
		}
    CustomerOrderDataAccess.GetList(params, async (res: BaseResponse) => {
      if(!res.success){
        return this.ShowToast(res.message,"warning");
	  }
	  
	  let currentOrders = this.state.Model.orders;
	  let newOrders = res.data.items as CustomerOrderModel[];
      this.state.Model.orders = [...currentOrders, ...newOrders];
      this.state.Model.total_pages=res.data.total_pages;
     // this.state.Model.current_page=res.data.current_page;
  	  this.state.Model.loading=false;
      this.UpdateViewModel();
    });
		
		//if from notification page
		let query = new URLSearchParams(this.props.location.search);
		let notification = query.get('notification');
		if(notification){
			let qPrms = {
				notification_id: notification
			}
			NotificationDataAccess.MarkRead(qPrms, async (res: BaseResponse) => {
                let count = res.data.notification_count;
				this.UpdateNoficationCount(count);
			});
		}
		
  }

  	redirectOrderDetialsPage = (item:any) => {
		let orderId = item.id;
		this.props.history.push("/order-details/" + orderId);
  	}

	paginationClick = (page:number) => {
		let model = this.state.Model;
		model.current_page = page;
		model.loading = true;
		this.setState({
			Model: model
		}, () => {
			this.GetList();
		})
	}
  
	
	render(){
		let model = this.state.Model;

		if(!this.Auth){
			return null;
		}
		
		return(
			<React.Fragment>
			<div>
				<div>
					<HeaderPage />
				</div>
				<br/>
				{
				 (model.loading) ?
					<div className="container_loader">
					  <BaseLoader/>
					</div>
					: 
					<React.Fragment>
					<div className="order-details-main-page">
						{
							!model.orders.length ?
							<Col xs={12} sm={12} md={12} lg={12}>
								<Alert variant="danger" className="not_found_product_container">
									<span>Sorry! No order found.</span>
								</Alert>
							</Col>
							: null
						}
					  <Container>
              				{model.orders.map((item) =>
							  <div className="myorderlist-area" onClick={() => this.redirectOrderDetialsPage(item)} key={item.id}>
									<div className="order_area_container">
									<Row>  
										<Col xs={12} sm={4} md={4} lg={4}>
											<div className="orderlist_product_ordertiming">
											<div>
												<strong>
													<span>{item.date}</span>&nbsp;
													<span>{item.time}</span>
												</strong>
											</div>
											<div>
												{item.currency}{this.priceDisplay(item.total_price)}
											</div>
											</div>		
										</Col>
										<Col xs={12} sm={4} md={4} lg={4}>
											<div className="product_order">
											<div>Order No <span>{item.order_no}</span></div>
											<div>Invoice No <span>{item.invoice_no}</span></div>
											</div>
										</Col>
										<Col xs={12} sm={4} md={4} lg={4}>
											<div className="order_status">
											   <Button variant="primary">{this.ucFirst(item.status)}</Button>
											</div>
										</Col>
									</Row>
								  </div> 
							  </div>
					  )}
					  </Container>
					  </div>
					</React.Fragment>
	             }
				<Container>
					<Row>
						<Col xs={12} sm={12} md={12} lg={12} className="pl-0">
							<Pagination
								page={model.current_page}
								totalPage={model.total_pages}
								clickHandler={this.paginationClick}
							/>
						</Col>
					</Row>
				</Container>
		    </div>
			<FooterPage />
			</React.Fragment>
		);
	}
}
