import BaseViewModel from "../../Core/BaseViewModel";

export default class ForgetPassowordViewModel extends  BaseViewModel{
    id:number=0;
    email_processing:boolean=false;
    token_status:number=0;
    email:string="";
    access_token:string="";
    isSuccess: string="";
    //form validation
    ErrorEmail:string="";
}