import React  from 'react';
import { Col, Row, Button, Modal, Spinner, Card, Form, Breadcrumb } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import SessionHelper from '../../Core/SessionHelper';
import CustomerOrderDataAccess from '../../DataAccess/CustomerOrderDataAccess';
import CustomerOrderDetailsViewModel from '../../ViewModels/CustomerOrder/CustomerOrderDetailsViewModel';
import HeaderPage from '../Core/HeaderPage';
import { BaseLoader } from '../Core/BaseView';
//import { Link } from 'react-router-dom';
import FooterPage from '../Core/FooterPage';
import { withRouter, Link } from 'react-router-dom';


class CustomerOrderViewPage extends BaseComponent<any, CustomerOrderDetailsViewModel> {

  constructor(props:any) {
		super(props);
		SessionHelper.HandleAuthentication();
		this.state = new BaseState<CustomerOrderDetailsViewModel>(new CustomerOrderDetailsViewModel());
	  this.state.Model.loading = true;
		this.state.Model.activities = [
			{
				content: 'Ordered',
			}, {
				content: 'Packed',
			}, {
				content: 'Shipped',
			},{
				content: 'Delivered',
			}
		];
  }


  componentDidMount(){
		if(!this.Auth){
			return null;
		}
    this.BindData();
  }


  BindData(){
    this.GetOrderDetails();
  }


  GetOrderDetails = async  () => {
		let orderId = this.props.match.params.id;
		CustomerOrderDataAccess.OrderView(orderId, async (res: BaseResponse) => {
		if(!res.success){
			return this.ShowToast(res.message,"warning");
		}
		this.state.Model.order=res.data;
		this.state.Model.loading=false;
		this.UpdateViewModel();
	 });
  }
	
	CancelOrderModal = () => {
		this.state.Model.cancelModal = true;
		this.UpdateViewModel();
	}
	
	ReOrderModal = () => {
		this.state.Model.reorderModal = true;
		this.UpdateViewModel();
	}
	
	handleCloseReorederModal = () => {
		this.state.Model.reorderModal=false;
    this.UpdateViewModel();
	}
	
	ReOrder = async () => {
		if(this.state.Model.actionProcessing){
			return;
		}
		
		this.state.Model.actionProcessing=true;
		this.UpdateViewModel();
		
		let model = this.state.Model;
		let order = model.order;
		
		let orderData = {
			mode: 'repayment',
			payment_mode: model.selected_method,
			order_id: order.id,
			use_wallet: ''
		};
		
		CustomerOrderDataAccess.Create(orderData, async (res: BaseResponse) => {
      if(!res.success){
				this.state.Model.actionProcessing = false;
				this.state.Model.reorderModal=false;
				this.UpdateViewModel();
        return this.ShowToast(res.message,"warning");
      }
			
			if(model.selected_method === 'cash'){
				
				this.state.Model.actionProcessing = false;
				this.state.Model.reorderModal=false;
				this.state.Model.order = res.data;
				this.UpdateViewModel();
				this.ShowToast(res.message,"success");
				
			}else{
				let order_id = res.data.order_id;
				let _this = this;
				var options = {
						key: res.data.paymen_order_id,
						amount: res.data.amount,
						currency: "INR",
						name: res.data.site_name,
						description: res.data.description,
						image: res.data.site_image,
						order_id: res.data.payment_order_id,
						remember_customer: false,
						prefill: {
							name: res.data.name,
							email: res.data.email,
							contact: res.data.phone,
							method: model.selected_method
						},
						notes:{
							address: res.data.address,
						},
						theme:{
							color:"#1545ef",
						},
						handler: function(res2:any){
							res2 = {...res2, ...{
								order_id: order_id,
								mode: 'repayment'
							}};
							
							CustomerOrderDataAccess.PaymentVerify(res2, async (res: BaseResponse) => {
								if(!res.success){
									let params = {
										order_id: order_id,
										payment_status: 'failed',
										transaction_id: res2.razorpay_payment_id
									}
									_this.updatePaymentStatus(params);
									return;
								}
								
								_this.state.Model.actionProcessing = false;
								_this.state.Model.reorderModal = false;
								_this.state.Model.order = res.data;
								_this.UpdateViewModel();
								_this.ShowToast(res.message,"success");
								
							});
							
						},
						"modal": {
							"ondismiss": function(){
								let params = {
									order_id: order_id,
									payment_status: 'failed',
									transaction_id: ''
								}
								_this.updatePaymentStatus(params);
							}
						}
				};
				var razorPay = new (window as any).Razorpay(options);
				razorPay.open();
			}
			
			
    });
		
	}
	
	
	updatePaymentStatus = async (params: any) => {
		let postParams = {
			order_id: params.order_id,
			payment_status: params.payment_status,
			payment_transaction_id: params.transaction_id,
			payment_mode: 'online'
		};
		CustomerOrderDataAccess.UpdatePaymentStatus(postParams, async (res: BaseResponse) => {
			this.state.Model.actionProcessing = false;
			this.state.Model.reorderModal = false;
      if(!res.success){
				this.UpdateViewModel();
        return this.ShowToast(res.message,"warning");
      }
			
			this.state.Model.order = res.data;
			this.UpdateViewModel();
			this.ShowToast(res.message,"success");
			
		});
		
	}

	helpCustomer = () => {
	   let id = this.state.Model.order.id;
	  
	   CustomerOrderDataAccess.checkTicket(id, async (res: BaseResponse) => {
		
		
		// if(!res.success){
		//     this.UpdateViewModel();
		// 	return this.ShowToast(res.message,"warning");
		// }
		
		this.state.Model.count = res.data.count;
		this.UpdateViewModel();

		if(this.state.Model.count > 0){
			this.props.history.push('/order/'+this.state.Model.order.id+'/tickets');
		}

		else{
			 this.state.Model.ticketModal = true;
			 this.UpdateViewModel();
		}
		
	});

	   
	}
	
	handlePaymentOption = (event: any) => {
		const { target: { value } } = event;
		this.state.Model.selected_method=value;
		this.UpdateViewModel();
	}
	
	goBackLink = () => {
	 	this.props.history.goBack();
	}
	
	handleCloseCancelModal = () => {
		this.state.Model.cancelModal=false;
    this.UpdateViewModel();
	}
	handleCloseTicketModal = () => {
	  this.state.Model.ticketModal=false;
      this.UpdateViewModel();
	}
	createTicket = () => {
		this.props.history.push('/order/'+this.state.Model.order.id+'/ticket/create');
	}
	CancelOrder = async () => {
		let model = this.state.Model
		if(model.actionProcessing){
			return;
		}
		let order = model.order;
		let params = {
			mode: 'cancelorder',
			order_id: order.id,
			payment_transaction_id: order.payment_transaction_id
		}
		this.state.Model.actionProcessing = true;
		this.UpdateViewModel();
		CustomerOrderDataAccess.CancelOrder(params, async (res: BaseResponse) => {
			this.state.Model.actionProcessing = false;
			this.state.Model.cancelModal=false;
      if(!res.success){
				this.UpdateViewModel();
        return this.ShowToast(res.message,"warning");
      }
			
			this.state.Model.order = res.data;
      this.UpdateViewModel();
			
			this.ShowToast(res.message,"success");
    });
		
	}


	render(){
		let model = this.state.Model;
		let order = model.order;

		if(!this.Auth){
			return null;
		}
		
		return(
			<div>
				<HeaderPage />
				<div className="order-details-main-page">
				<div className="container">
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} className="pl-0">
								<div className="breadcrumb-container pt-0 mt-0">
									<Breadcrumb>
										<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
										<Breadcrumb.Item href="/orders">My Order</Breadcrumb.Item>
										<Breadcrumb.Item active>Order Details</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</Col>
							{
							model.loading ?
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="container_loader">
									<BaseLoader/>
								</div>
							</Col>
							: 
							<React.Fragment>
								<Col xs={12} sm={12} md={12} lg={12} className="myorderlist-area">
										<Row className="order_area_container">
											<Col xs={12} sm={6} md={6} lg={6}>
												<div className="orderlist_product_ordertiming">
													<div className="order_details_delivery_address">
														<h1> Delivery Address </h1>
														<strong>
															<span>{order.first_name}&nbsp;{order.last_name}</span><br/>
														</strong>
														<span>{order.address}</span>
														<br />
														<strong>
															<div className="customer_ph_no">Phone Number <span> {order.phone}</span></div>
														</strong>
													</div>
												</div>
											</Col>
											<Col xs={12} sm={3} md={3} lg={3}>
												<div className="product_order">
													<div>Order No <span>{order.order_no}</span></div>
													<div>Invoice No <span>{order.invoice_no}</span></div>
												</div>
											</Col>
											<Col xs={12} sm={3} md={3} lg={3} className="order_details_btns text-right">
											    
												<Button variant="primary" >{this.ucFirst(order.status)}</Button>
												{
													order.status === 'failed' ?
													<Button variant="success" onClick={() => this.ReOrderModal()}>Re Payment</Button>
													: null
												}
												{
													(order.status === 'pending') ?
													<Button variant="danger" onClick={() => this.CancelOrderModal()}>Cancel Order</Button>
													: null
												}
											</Col>
										</Row>
										<Row className="order_area_container">
											<Col xs={12} sm={12} md={12} lg={12}  className="text-right">
												<Button variant="default" onClick={() => this.helpCustomer()}>Help</Button>
											</Col>
										</Row>

								</Col>
								<Col xs={12} sm={12} md={12} lg={12} className="myorderlist-area">
									<Row className="order_area_container">
										<Col xs={12} sm={3} md={3} lg={3}>
											<div>
												<div className="orderlist_product_ordertiming">
													<div>
														<strong>
														<span>{order.date} </span>
														<span>{order.time}</span>
														</strong>
													</div>
													<div>
													 { this.priceDisplay('Rs. ', order.total_price) }
													 </div>
												</div>
											</div>
										</Col>
										<Col xs={12} sm={7} md={7} lg={7}>
											<div className="product_order_timeline order_area_container">
											</div>
										</Col>
										<Col xs={12} sm={2} md={2} lg={2}>
											<div className="payment_status order_area_container">
												<Button variant="primary" className="btn btn-primary return-btn">{'payment_mode' in order ? (order.payment_mode.charAt(0).toUpperCase() + order.payment_mode.slice(1)) : ''}</Button>
											</div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12}>
									<Row className="position_relative">
										<React.Fragment>
											<Col xs={12} sm={12} md={12} lg={12} className="pl-0 pr-0">
												<div className="cart_column_header">
													<h1>Products</h1>
												</div>
												<div className="underline"> &nbsp;</div>
												{
													model.order.order_transactions.map((item:any, key:number) => {
													return <div className="cart_single_image_container" key={key}>
														<Col xs={12} sm={4} md={4} lg={4} style={{padding: '0px'}}>
															{
																item.media ?
																<Link to={'/products/' + item.product_slug } className="image link-to-product">
																<div className="cart_single_image">
																	<img src={item.media} alt="puja" />
																</div>
																</Link>
																: null
															}
														</Col>
														<Col xs={12} sm={8} md={8} lg={8}>
															<div className="cart_page_header">
																<h1> { item.product_name }</h1>
															</div>
															<div className="carts-item-price">
																{ this.priceDisplay('Rs. ', item.sale_price)}
																{
																	item.price ?
																	<del style={{color: '#c5c5c5'}}> { this.priceDisplay('Rs. ', item.price) }</del>
																	: null
																}
															</div>
															<div className="product-qty">
																<div>
																<label style={{margin: '0px'}}>Quantity:</label> <span>{item.quantity}</span>
																</div>
															</div>
															<div className="sub-total">
																<label style={{margin: '0px'}}>Total Price:</label> <span>{this.priceDisplay('Rs. ', item.quantity * item.sale_price) }</span>
															</div>
														</Col>
													</div>
													})
												}
											</Col>
										</React.Fragment>
									</Row>
								</Col>
								
								<Modal
									show={model.cancelModal}
									onHide={() => this.handleCloseCancelModal()}
									backdrop="static"
									keyboard={false}
								>
									<Modal.Header>
										<Modal.Title>Cancel Order</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										Are you sure want to cancel this order?
									</Modal.Body>
									<Modal.Footer>
										<Button variant="danger" onClick={() => this.CancelOrder()} disabled={model.actionProcessing}>
											{
												model.actionProcessing ?
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												:
												"Yes"
											}
										</Button>
										{
											!model.actionProcessing ? 
											<Button variant="success" onClick={() => this.handleCloseCancelModal()}>No</Button>
											: null
										}
									</Modal.Footer>
								</Modal>
								
								<Modal
									show={model.reorderModal}
									onHide={() => this.handleCloseReorederModal()}
									backdrop="static"
									keyboard={false}
								>
									<Modal.Header>
										<Modal.Title>Retry Payment</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Card>
											<Card.Body>
												<h4><strong>{order.date}</strong></h4>
												<p>Order No: <strong>{order.order_no}</strong></p>
												<p>Invoice No: <strong>{order.invoice_no}</strong></p>
												<h3><strong>{order.currency}{order.total_price}</strong></h3>
											</Card.Body>
										</Card>
										<br />
										{
											Object.keys(this.paymentOptions).map((k:string) => {
												return <Form.Check 
													inline 
													name="payment_options" 
													label={this.paymentOptions[k]} 
													value={k} 
													type="radio" 
													id={`inline-${k}`} 
													key={k} 
													onChange={this.handlePaymentOption}
													/>
											})
										}
									</Modal.Body>
									<Modal.Footer>
										<Button variant="success" onClick={() => this.ReOrder()} disabled={model.actionProcessing || !model.selected_method}>
											{
												model.actionProcessing ?
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												:
												"PROCEED"
											}
										</Button>
										{
											!model.actionProcessing ? 
											<Button variant="danger" onClick={() => this.handleCloseReorederModal()}>No</Button>
											: null
										}
									</Modal.Footer>
								</Modal>
								<Modal
									show={model.ticketModal}
									onHide={() => this.handleCloseTicketModal()}
									backdrop="static"
									keyboard={false}
									className="gods_page_modal"
								>
									<Modal.Header>
										<Modal.Title>Message</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										You currently don't have any ticket related to this.order. Do
										you want to create one?
									</Modal.Body>
									<Modal.Footer>
										<Button variant="success" onClick={() => this.createTicket()} >Yes</Button>
										<Button variant="danger" onClick={() => this.handleCloseTicketModal()}>No</Button>
									</Modal.Footer>
								</Modal>
								
							</React.Fragment>
							}
						</Row>
						
				</div>
				</div>
				<FooterPage />
			</div>
		);
	}
}

export default withRouter(CustomerOrderViewPage)