import React  from 'react';
import BaseComponent from './BaseComponent';
import BaseViewModel from './BaseViewModel';
import BaseState from './BaseState';
import {Pagination as BootstrapPagination } from 'react-bootstrap';

type Props = {
	page: number,
	totalPage: number,
	clickHandler: any
} 

export default class Pagination extends BaseComponent<any,PaginationViewModel> {
	constructor(props:any) {
    super(props);
		this.state = new BaseState<PaginationViewModel>(new PaginationViewModel());
    this.state.Model.page = this.props.page;
    this.state.Model.totalPage = this.props.totalPage;
    this.state.Model.clickHandler = this.props.clickHandler;
  }
	
	static getDerivedStateFromProps(nextProps:any, prevState:any){
		if(nextProps.page !== prevState.Model.page || nextProps.totalPage !== prevState.Model.totalPage){
			let model = prevState.Model;
			model.page = nextProps.page;
			model.totalPage = nextProps.totalPage;
			return {
				model: model
			}
		}
		return null;
	}
	
	paginationClick = (page:number) => {
		if(page !== this.state.Model.page && page <= this.state.Model.totalPage){
			this.state.Model.clickHandler(page);
		}
	}

  render() {
		let model = this.state.Model;
		if(model.totalPage <= 1){
			return (
				<React.Fragment></React.Fragment>
			)
		}
		return (
			<BootstrapPagination>
				<BootstrapPagination.First onClick={() => this.paginationClick(1)} />
				<BootstrapPagination.Prev onClick={() => this.paginationClick(model.page > 1 ? (model.page - 1) : 1)} />
				{
					[...Array(model.totalPage)].map((x:number, i:number) => {
						return <BootstrapPagination.Item key={i} 
							active={model.page === (i+1)} 
							onClick={() => this.paginationClick((i+1))}
						>
						{(i+1)}
						</BootstrapPagination.Item>
					})
				}
				<BootstrapPagination.Next onClick={() => this.paginationClick(model.page + 1)} />
				<BootstrapPagination.Last onClick={() => this.paginationClick(model.totalPage)} />
			</BootstrapPagination>
		)
  }
}

class PaginationViewModel extends BaseViewModel{
	clickHandler: any;
	page: number = 1;
	totalPage: number = 0;
}