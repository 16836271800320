export default class SectionModel{
	/* background_color:string="";
	border_color:string="";
	button_background_color:string="";
	button_text_color:string="";
	endtime:string=""; */
	section_name:string="";
	section_title:string="";
	section_type:string="";
	//text_color:string="";
}