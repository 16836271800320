import React  from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import ReturnPolicyViewModel from '../../ViewModels/Home/ReturnPolicyViewModel';
import HeaderPage from '../Core/HeaderPage';
import FooterPage from '../Core/FooterPage';
import HomeDataAccess from '../../DataAccess/HomeDataAccess';
import { BaseLoader } from '../Core/BaseView';
import { withRouter } from 'react-router-dom';

class ReturnPolicyViewPage extends BaseComponent<any,ReturnPolicyViewModel> {
  constructor(props:any) {
    super(props);
    this.state = new BaseState<ReturnPolicyViewModel>(new ReturnPolicyViewModel());
    this.state.Model.loading = true;
  }
	
  componentDidMount(){
	this.getRetutnPolicyInfo();
  }

  getRetutnPolicyInfo(){
		let model = this.state.Model;
		let params ={page: 'returns-and-refunds'}
		
		HomeDataAccess.GetPageContent(params, async (res: BaseResponse) => {
			model.loading = false;		
			if(!res.success){
			return this.ShowToast(res.message,"warning");
			}
			let data = res.data;
			model.title = data.title;
			model.content = data.content;
			this.UpdateViewModel();
		});
	}
	
	render(){
		let model = this.state.Model;
		
		return(
			<React.Fragment>
			  <HeaderPage />
			    <div>
			   {
				 model.loading ?
				  <Col xs={12} sm={12} md={12} lg={12}>
					<div className="container_loader">
						<BaseLoader/>
					</div>
				   </Col>
				  :
				  <div className="privacy-policy-area">
					  <Container>
						  <Row>
							<Col xs={12} sm={12} md={12} lg={12} dangerouslySetInnerHTML={{__html: model.content}}>
								
							</Col>
						  </Row>
					  </Container>
				  </div>
			   }
			  </div>		
		      <FooterPage />
		  </React.Fragment>
    );
  }
}

export default withRouter(ReturnPolicyViewPage)
