import { RouteComponentProps } from 'react-router-dom';
import BaseComponentCore from './BaseComponentCore';
import BaseViewModel from './BaseViewModel';
import { toast } from 'react-toastify';
import SessionHelper from './SessionHelper';

export default abstract class BaseComponent<T extends RouteComponentProps<any>, U> extends BaseComponentCore<T, U> {
		
    ShowPageLoader(IsLoading: boolean, LabelText: string = "") {
			var model = this.state.Model as unknown as BaseViewModel;
			model.IsPageLoading = IsLoading;
			model.PageLoadingLabel = LabelText ? model.TableLoadingLabel : LabelText;
			this.UpdateViewModelUnknown(model);
		}
	
		ShowTableLoader(IsLoading: boolean, LabelText: string = "") {
			var model = this.state.Model as unknown as BaseViewModel;
			model.IsTableLoading = IsLoading;
			model.TableLoadingLabel = LabelText ? model.TableLoadingLabel : LabelText;
			this.UpdateViewModelUnknown(model);
		}

		ShowModal(){
			var model = this.state.Model as unknown as BaseViewModel;
			model.ModalStatus = true;
			this.UpdateViewModelUnknown(model);
		}

		CloseModal(){
			var model = this.state.Model as unknown as BaseViewModel;
			model.ModalStatus = false;
			this.UpdateViewModelUnknown(model);
		}

		ShowToast(msg:string,type?: "danger" | "success" | "warning"){
			switch(type){
				case "danger":
					toast.error(msg);
					break
					case "success":
					toast.success(msg);
					break
					case "warning":
					toast.warning(msg);
					break
					default :
					toast.warning(msg);
					break
			}
		}
		
		UpdateCartCount(count: number){
			let newObj:any = {};
			newObj.cart_count = count;
			this.UpdateAuthData(newObj);
		}
		
		UpdateWishlistCount(count: number){
			let newObj:any = {};
			newObj.wishlist_count = count;
			this.UpdateAuthData(newObj);
		}

		UpdateNoficationCount(count: number){
			let newObj:any = {};
			newObj.notification_count = count;
			this.UpdateAuthData(newObj);
		}
		
		UpdateAuthData(data: any){
			let authData = {...this.Auth, ...data};
			SessionHelper.SetSession(authData);
			this.UpdateViewModel();
		}
		
		priceDisplay(currency: string, price: any){
		   price = Number.parseFloat(price).toFixed(2)
		   return (currency + ' ' + price);
    }
		
		showCartErrorMessage(res:any){
			try {
				let errArr = JSON.parse(res.message);
				if('products' in errArr){
					this.ShowToast(errArr.products[0],"success");
				}
			} catch (e) {
				this.ShowToast(res.message,"success");
			}
		}
		
		isMobile(){
			return (Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1);
		}

		ucFirst(str:any){
			if(!str) return str;
			try{
				return str.charAt(0).toUpperCase() + str.slice(1);
			}catch(err){
				return str;
			}
		}
		
      
}

