import React  from 'react';
import { Col, Container, Row, Breadcrumb, Alert } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseResponse from '../../Core/BaseResponse';
import BaseState from '../../Core/BaseState';
import SessionHelper from '../../Core/SessionHelper';
import CustomerOrderDataAccess from '../../DataAccess/CustomerOrderDataAccess';
import TicketsViewModel from '../../ViewModels/Ticket/TicketsViewModel';
import HeaderPage from '../Core/HeaderPage';
import { BaseLoader } from '../Core/BaseView';
import { Link } from 'react-router-dom';
import FooterPage from '../Core/FooterPage';
import { withRouter } from 'react-router-dom';
import PageNotFoundContent from '../Core/PageNotFoundContent';
import Pagination from "../../Core/Pagination";

class TicketsPage extends BaseComponent<any, TicketsViewModel> {

  constructor(props:any) {
		super(props);
		SessionHelper.HandleAuthentication();
		this.state = new BaseState<TicketsViewModel>(new TicketsViewModel());
	  this.state.Model.loading = true;
  }

  componentDidMount(){
		if(!this.Auth){
			return null;
		}
    this.GetTickets();
  }

  GetTickets = async  () => {
		let orderId = this.props.match.params.orderid;
		this.state.Model.orderid = orderId;
		this.UpdateViewModel();
		
		let params = {
			order_id: orderId,
			page: this.state.Model.page
		}
		
		CustomerOrderDataAccess.getTickets(params, async (res: BaseResponse) => {
			if(!res.success){
				this.state.Model.loading = false;
				this.UpdateViewModel();
				return this.ShowToast(res.message,"warning");
			}
			this.state.Model.tickets = res.data.items;
			this.state.Model.totalPage = res.data.total_pages;
			this.state.Model.loading = false;
			this.UpdateViewModel();
		});
  }
	
	ticketView = (item:any) => {
		let model = this.state.Model;
		this.props.history.push('/order/'+model.orderid+'/tickets/' + item.id);
	}
	
	paginationClick = (page:number) => {
		let model = this.state.Model;
		model.page = page;
		this.setState({
			Model: model
		}, () => {
			this.GetTickets();
		})
	}

	render(){
		let model = this.state.Model;

		if(!this.Auth){
			return null;
		}
		
		return(
			<div>
				<HeaderPage />
				<div className="all_ticket_container">
				<div className="container">
				
						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="breadcrumb-container">
									<Breadcrumb>
										<Breadcrumb.Item href="/">Homepage</Breadcrumb.Item>
										<Breadcrumb.Item href="/orders">My Order</Breadcrumb.Item>
										<Breadcrumb.Item href={"/order-details/" + model.orderid}>Order Details</Breadcrumb.Item>
										<Breadcrumb.Item active>All Tickets</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</Col>
							{
								model.loading ?
								<Col xs={12} sm={12} md={12} lg={12}>
									<div className="container_loader">
										<BaseLoader/>
									</div>
								</Col>
								:
								<React.Fragment>
								{
									!model.tickets.length ?
									<Col xs={12} sm={12} md={12} lg={12}>
										<Alert variant="danger" className="not_found_product_container">
											<span>Sorry! No ticket found.</span>
										</Alert>
									</Col>
									:
									<React.Fragment>
										
										{
											model.tickets.map((item:any, key:number) => {
											return <Col xs={12} sm={9} md={9} lg={9} key={key} onClick={() => this.ticketView(item)}>
												<Container>
												<Row>
												<div className="myorderlist-area">
													<Col xs={12} sm={12} md={12} lg={12}>
														<div className="ticket_area">
															<p>{item.ticket_no}</p>
															<span>{item.ticket_date}</span>
														</div>
													</Col>
													<Col xs={12} sm={12} md={12} lg={12}>
														<div className="ticket_subject_area">
															<p>{item.subject}</p>
														</div>
													</Col>
													<Col xs={12} sm={12} md={12} lg={12}>
													<div className="ticket_msg_area">
														<p>{item.message}</p>
													</div>
													</Col>
													</div>
												</Row>
												</Container>
											</Col>

											})
										}
										
										<Col xs={12} sm={12} md={12} lg={12}>
											<Pagination
												page={model.page}
												totalPage={model.totalPage}
												clickHandler={this.paginationClick}
											/>
										</Col>
										
									</React.Fragment>
								}
								</React.Fragment>
							}
						</Row>
						
				
				</div>
				<a href="#" className="float2">
					Please Create Your Ticket! 
				</a>
				<Link to={"/order/"+model.orderid+"/ticket/create"} className="float">
					<i className="fa fa-plus my-float"></i>
				</Link>
				</div>
				<FooterPage />
			</div>
		);
	}
}

export default withRouter(TicketsPage)