import React  from 'react';
import {Row } from 'react-bootstrap';
import BaseComponent from "../../Core/BaseComponent";
import BaseState from '../../Core/BaseState';
import BaseResponse from '../../Core/BaseResponse';
import EventsViewModel from '../../ViewModels/Home/EventsViewModel';
import HomeDataAccess from '../../DataAccess/HomeDataAccess';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

type Props = {
	section?: any
} 

export default class Events extends BaseComponent<any,EventsViewModel> {
  constructor(props:any) {
    super(props);
    this.state = new BaseState<EventsViewModel>(new EventsViewModel());
		this.state.Model.section = 'section' in this.props ? this.props.section : null;
		this.state.Model.loading = true;
  }
	
	componentDidMount(){
		this.eventList();
	}
	
	eventList(){
		HomeDataAccess.GetEventList((res: BaseResponse) => {
      this.state.Model.loading = false;
      if(!res.success){
				this.UpdateViewModel();
				return;
      }
			this.state.Model.events = res.data.events
			this.UpdateViewModel();
			
		});
		
	}
  
	render(){
		let model = this.state.Model;
		
		/* if(model.loading){
			return (
				<BaseLoader/>
			)
		} */
		
		if(!model.events.length){
			return false;
		}
		
		return(
			
			<div className="home">
				<section className="new_products events_container">
					<div className="new_products_container">
						<div className="puja_feature_heading">
							<h1> {model.section.section_title} </h1>
						</div>
						<div className="new_products_row">
							<div className="new_product_outer">
								<Row>
									<OwlCarousel
										autoplay={false}
										className="owl-theme"
										loop
										margin={18}
										items={6}
										dots={false}
										nav={true}
										responsive={{
											0: {
													items: 1,
											},
											600: {
													items: 3,
											},
											1000: {
													items: 5,
											},
										}}
									>
										{
											model.events.map((item: any, key:number) => {
											return <div className="event-slider-container" key={key}>
													{
														item.media ?
														<Link to={"/events/" + item.slug} className="image link-to-product">
														  <div className="event-slider-image">
															   <img src={item.media} alt="puja" />
														   </div>
														</Link>
														: null
													}
												<div className="event_image_header"><h1>{item.name}</h1></div>
												</div>
											})
										}
									</OwlCarousel>
								</Row>
							</div>
						</div>
					</div>
				</section>
			</div>
    );
  }
}