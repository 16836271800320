import React  from 'react';
import BaseComponent from "../../Core/BaseComponent";
import BaseState from '../../Core/BaseState';
import HeaderViewModel from '../../ViewModels/Home/HeaderViewModel';
//import footer_bg from '../../Assets/Images/footer-bg.png'
import { Link } from "react-router-dom";
class FooterPage extends BaseComponent<any,HeaderViewModel> {
  constructor(props:any) {
		super(props)
		this.state = new BaseState<HeaderViewModel>(new HeaderViewModel());
  }
	
  render(){
		
		//const d: Date = new Date();
		
		return(
			<footer id="footer" className="footer layout-01">
                <div className="footer_main_container">
                    <div className="footer_container">
                        <div className="footer_row"> 
                            <div className="footer_single_column">
                                <h3 className="section-title">My Account</h3>
                                <ul className="footer-menu">
                                {
                                   this.Auth ?
                                   <React.Fragment>
                                    {/* <li><Link to="">Sign In</Link></li>    */}
                                    <li><Link to="/carts">View Cart</Link></li>   
                                    <li><Link to="/wishlists">My Wishlist</Link></li> 
                                    <li><Link to="/tracking">Track My Order</Link></li> 
                                    </React.Fragment>
							     : 
                                    <React.Fragment>
                                    <li><Link to="/login">Sign In</Link></li>   
                                    <li><Link to="/login">View Cart</Link></li>    
                                    <li><Link to="/login">My Wishlist</Link></li>  
                                    <li><Link to="/login">Track My Order</Link></li> 
                                    </React.Fragment>
						       }
                                    
                                </ul>
                            </div>
                            <div className="footer_single_column">
                                <h3 className="section-title">Why Buy From Us</h3>
                                <ul className="footer-menu">
                                    <li><Link to="/customer-service">Customer Service</Link></li> 
                                    <li><Link to="/shipping">Shipping &amp; Returns</Link></li> 
                                    <li><Link to="/shipping">Shipping</Link></li> 
                                </ul>
                            </div>
                            <div className="footer_single_column">
                                <h3 className="section-title">Information</h3>
                                <ul className="footer-menu">
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/return-policy">Return Policy</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="footer_row">
                            <div className="social-title">
                                <h3>Get Social</h3>
                                <a className="social facebook" href="" target="_blank"><i className="fas fa-envelope" /></a>
                                <a className="social facebook" href="" target="_blank"><i className="fas fa-envelope" /></a>
                                <a className="social facebook" href="" target="_blank"><i className="fas fa-envelope" /></a>
                                <a className="social facebook" href="" target="_blank"><i className="fas fa-envelope" /></a>
                            </div>
                        </div> */}
                    </div>
                </div>
			</footer>
		);
  }
}
 
export default FooterPage
